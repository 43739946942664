import Candidacy, { ErrorCandidacy } from 'requests/objects/candidacy'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import FileCv from 'requests/objects/fileCv'
import BoardComment from 'requests/objects/boardComment'
import File from 'requests/objects/file'
import Interview from 'requests/objects/interview'
import { setMessageBar } from 'redux/slices/common'
import store from 'redux/store'
import { MessageBarType } from '@fluentui/react'

/**
 * CandidaciesHandler
 * @augments {ApiHandler<Candidacy, ErrorCandidacy>}
 */
export default class CandidaciesHandler extends ApiHandler {
    constructor() {
        super({ type: Candidacy, errorType: ErrorCandidacy, key: 'candidacies' })
    }

    /**
     * Get file
     * @param {number} candidacyId Id
     * @param {number} fileId Id
     * @param {BlobPropertyBag['type']=} type type
     * @returns {RequestApi<Blob>} Request
     */
    getFileCv(candidacyId, fileId, type) {
        const request = this.initFetchRequest({
            url: [candidacyId, 'filecvs', fileId], method: 'GET', responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data], { type }))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upload file
     * @param {number} candidacyId Id
     * @param {globalThis.File} file file
     * @returns {RequestApi<FileCv>} Returns
     */
    uploadFileCv(candidacyId = undefined, file = undefined) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: [candidacyId, 'filecvs'],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new FileCv(/** @type {any} */(res.data)?.filecvs))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Remove file
     * @param {number} candidacyId Id
     * @param {number} fieldId Ids
     * @returns {RequestApi<FileCv>} Request
     */
    removeFileCv(candidacyId, fieldId) {
        const request = this.initFetchRequest({
            url: [candidacyId, 'filecvs', fieldId], method: 'DELETE',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get file
     * @param {number} candidacyId Id
     * @param {number} fileId Id
     * @returns {RequestApi<Blob>} Request
     */
    getFile(candidacyId, fileId) {
        const request = this.initFetchRequest({
            url: [candidacyId, 'files', fileId], method: 'GET', responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upload file
     * @param {number} candidacyId Id
     * @param {globalThis.File} file file
     * @returns {RequestApi<File>} Returns
     */
    uploadFile(candidacyId = undefined, file = undefined) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: [candidacyId, 'files'],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new File(/** @type {any} */(res.data)?.files))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Remove file
     * @param {number} candidacyId Id
     * @param {number} fieldId Ids
     * @returns {RequestApi<File>} Request
     */
    removeFile(candidacyId, fieldId) {
        const request = this.initFetchRequest({
            url: [candidacyId, 'files', fieldId], method: 'DELETE',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update candidacy status
     * @param {number} candidacyId Id
     * @param {number} statusId Id
     * @returns {RequestApi<Candidacy>} Request
     */
    patchStatus(candidacyId, statusId) {
        const request = this.initFetchRequest({ url: [candidacyId, 'status', statusId], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update candidacy favorite
     * @param {number} candidacyId Id
     * @param {{isFavorite: boolean}} data data
     * @returns {RequestApi<Candidacy>} Request
     */
    patchFavorite(candidacyId, data) {
        const request = this.initFetchRequest({ url: [candidacyId, 'favorite'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert comment
     * @param {BoardComment} obj Request
     * @param {number} candidacyId id
     * @param {number} commentId id
     * @returns {RequestApi<BoardComment>} Request
     */
    // eslint-disable-next-line new-cap
    upsertComment(obj, candidacyId, commentId) {
        if (commentId) {
            const request = this.initFetchRequest({ url: [candidacyId, 'comments', commentId], method: 'PUT', data: obj })

            return this.getRequestApi(
                () => request.fetchRequest
                    // eslint-disable-next-line new-cap
                    .then(res => new BoardComment(res.data.comments))
                    .catch(err => {
                        throw this.handleError(err)
                    }),
                request.cancelToken,
            )
        }

        const request = this.initFetchRequest({ url: [candidacyId, 'comments'], method: 'POST', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new BoardComment(res.data.comments))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Delete comment
     * @param {number} candidacyId id
     * @param {number} commentId id
     * @returns {RequestApi<BoardComment>} Request
     */
    removeCommentById(candidacyId, commentId) {
        const request = this.initFetchRequest({ url: [candidacyId, 'comments', commentId], method: 'DELETE' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new BoardComment(res.data.comments))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update hunt pin comment
     * @param {number} candidacyId Id
     * @param {number} commentId id
     * @param {{isPinned: boolean}} data data
     * @returns {RequestApi<BoardComment>} Request
     */
    patchPin(candidacyId, commentId, data) {
        const request = this.initFetchRequest({ url: [candidacyId, 'comments', commentId, 'pin'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new BoardComment(res.data.comments))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update candidacy interview date
     * @param {number} candidacyId Id
     * @param {Partial<Candidacy>} data data
     * @returns {RequestApi<Candidacy>} Request
     */
    patchInterviewInfo(candidacyId, data) {
        const request = this.initFetchRequest({ url: [candidacyId, 'interview-informations'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update Candidacy interview date
     * @param {number} candidatId Id
     * @param {Partial<Candidacy>} data data
     * @returns {RequestApi<Candidacy>} Request
     */
    patchInterviewClientInfo(candidatId, data) {
        const request = this.initFetchRequest({
            url: [candidatId, 'interview-client-informations'], method: 'PATCH', data,
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Import parameters from a mission to candidate
     * @param {number} candidacyId Id
     * @param {{comment: string, isCode: boolean}} data data
     * @param {boolean=} isForce isForce
     * @returns {RequestApi<Candidacy>} Request
     */
    patchMissionCoding(candidacyId, data, isForce) {
        const request = this.initFetchRequest({
            url: [candidacyId, 'mission-coding'], method: 'PATCH', params: { isForce }, data,
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Set coding
     * @param {number} candidacyId Id
     * @param {Candidacy} data data
     * @returns {RequestApi<Candidacy>} Request
     */
    patchReCoding(candidacyId, data) {
        const request = this.initFetchRequest({ url: [candidacyId, 'recoding'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Patch reject
     * @param {number} candidacyId Id
     * @param {{isRejected: boolean, comment: string}} data data
     * @returns {RequestApi<Candidacy>} Request
     */
    patchReject(candidacyId, data) {
        const request = this.initFetchRequest({ url: [candidacyId, 'reject'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Import via mission coding
     * @param {number} missionId Id
     * @returns {RequestApi<Candidacy[]>} Request
     */
    patchViaImportCoding(missionId) {
        const request = this.initFetchRequest({ url: ['import', missionId], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => /** @type {any} */(res.data[this.objectName])?.map(x => new (this.type)(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert interview
     * @param {Interview} obj Request
     * @param {number} candidacyId id
     * @param {number} interviewId id
     * @param {boolean=} isValidating isValidating
     * @returns {RequestApi<Interview>} Request
     */
    upsertInterview(obj, candidacyId, interviewId, isValidating = false) {
        if (interviewId) {
            const request = this.initFetchRequest({
                url: [candidacyId, 'interviews'], method: 'PUT', data: obj, params: { isValidating },
            })

            return this.getRequestApi(
                () => request.fetchRequest
                    .then(res => {
                        if (isValidating)
                            store.dispatch(setMessageBar({
                                isDisplayed: true,
                                type: MessageBarType.success,
                                message: "L'entretien à bien été validé",
                            }))
                        return new Interview(res.data.interviews)
                    })
                    .catch(err => {
                        throw this.handleError(err)
                    }),
                request.cancelToken,
            )
        }

        const request = this.initFetchRequest({ url: [candidacyId, 'interviews'], method: 'POST', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Interview(res.data.interviews))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Create candidacies from candidates
     * @param {number[]} candidateIds Id
     * @param {number} missionId Id
     * @returns {RequestApi<Candidacy[]>} Request
     */
    createCandidacies(candidateIds, missionId) {
        const request = this.initFetchRequest({ url: ['export', missionId], method: 'PATCH', data: { candidateIds } })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({
                        isDisplayed: true,
                        type: MessageBarType.success,
                        message: 'Les candidatures ont bien été créées.',
                    }))
                    // eslint-disable-next-line new-cap
                    return /** @type {any} */(res.data[this.objectName])?.map(x => new (this.type)(x)) ?? []
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update candidacy interview cancel
     * @param {number} candidacyId Id
     * @returns {RequestApi<Candidacy>} Request
     */
    patchInterviewCancel(candidacyId) {
        const request = this.initFetchRequest({ url: [candidacyId, 'interview-informations', 'cancel'], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update candidacy interview cancel
     * @param {number} candidacyId Id
     * @returns {RequestApi<Candidacy>} Request
     */
    patchInterviewClientCancel(candidacyId) {
        const request = this.initFetchRequest({ url: [candidacyId, 'interview-client-informations', 'cancel'], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update candidacy isUnderProposal
     * @param {number} candidacyId Id
     * @param {{isUnderProposal: boolean}} data data
     * @returns {RequestApi<Candidacy>} Request
     */
    patchUnderProposal(candidacyId, data) {
        const request = this.initFetchRequest({ url: [candidacyId, 'under-proposal'], method: 'PATCH', data })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get interview data in pdf
     * @param {number} candidacyId candidacyId param
     * @returns {RequestApi<Blob>} Request
     */
    getPdfInterview(candidacyId) {
        const request = this.initFetchRequest({
            url: [candidacyId, 'pdf-interview'],
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Patch transfer
     * @param {number} candidacyId Id
     * @param {number} missionId Id
     * @returns {RequestApi<Candidacy>} Request
     */
    patchTransfer(candidacyId, missionId) {
        const request = this.initFetchRequest({ url: [candidacyId, 'transfer', missionId], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
