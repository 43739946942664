import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, PrimaryButton,
} from '@fluentui/react'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import Status from 'types/status'
import Candidacy from 'requests/objects/candidacy'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'

/**
 * BoardImportCodingModal
 * @param {object} props Props
 * @param {CandidaciesHandler} props.handler handler
 * @param {boolean} props.isVisible isVisible
 * @param {number} props.missionId missionId
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {(item: Candidacy[]) => void} props.onCreateMany onCreateMany
 * @returns {JSX.Element} Returns
 */
export default function BoardImportCodingModal({
    handler, isVisible, missionId, setIsVisible, onCreateMany,
}) {
    const [status, setStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<RequestApi<Candidacy[]>>} */
    const handlerUpdateBoardItem = useRef(null)

    const update = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerUpdateBoardItem.current = handler.patchViaImportCoding(missionId)
            const newItems = await handlerUpdateBoardItem.current.fetch()
            onCreateMany(newItems)
            setStatus(Status.RESOLVED)
            setIsVisible(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [handler, onCreateMany, setIsVisible, missionId])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerUpdateBoardItem?.current?.cancel()
    }, [])

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Importer de la base de données',
                subText: 'Importer tous les candidats correspondant au codage de la mission?',
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="450px"
            minWidth="450px"
        >
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    update()
                }}
            >
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text="Annuler"
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        type="submit"
                        text="Oui"
                        disabled={status === Status.PENDING}
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
