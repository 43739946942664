import React, { PureComponent } from 'react'
import {
    MessageBarType, TextField, ICommandBarItemProps,
} from '@fluentui/react'
import history from 'helpers/history'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import EmailTemplate, { ErrorEmailTemplate } from 'requests/objects/emailTemplate'
import setPageTitle from 'helpers/methods/pageTitle'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdEmailTemplates extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.emailTemplateId,
            /** @type {EmailTemplate} Element find from API */
            item: new EmailTemplate(),
            /** @type {EmailTemplate} Initial element fond from API. Used to refresh */
            iniItem: new EmailTemplate(),
            /** @type {ErrorEmailTemplate} Errors */
            errorField: new ErrorEmailTemplate(),
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { match, setCommand } = this.props

        if (match?.params?.emailTemplateId !== prevProps?.match?.params?.emailTemplateId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.emailTemplatesHandlerRemoveById?.cancel()
        this.emailTemplatesHandlerGetById?.cancel()
        this.emailTemplatesHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state
        const mainTitle = `Modèle email ${match?.params?.emailTemplateId ? item.name || '' : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie modèle email', key: ' modele-email' },
            { text: 'Tous les modèles emails', key: 'all-modele-email', href: '/modele-emails' },
            // eslint-disable-next-line max-len
            { text: mainTitle, key: 'one-modele-email', isCurrentItem: true },
        ])
        setTitle('Modèles emails')
        setPageTitle(mainTitle)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, emailTemplatesHandler, setMessageBar,
        } = this.props

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: false,
            },
        ]

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorEmailTemplate(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.emailTemplateId,
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: 'Supprimer le modèle email',
                        subTitle: 'Êtes-vous certain de vouloir supprimer le modèle email ? Cette action est définitive.',
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.emailTemplatesHandlerRemoveById = emailTemplatesHandler.removeById(match?.params?.emailTemplateId)
                                    await this.emailTemplatesHandlerRemoveById.fetch()
                                    history.push('/modele-emails')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimé",
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.emailTemplateId,
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, emailTemplatesHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.emailTemplateId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.emailTemplate) // If object came from history push with a create
                    this.setState({
                        item: location.state?.emailTemplate,
                        iniItem: location.state?.emailTemplate,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                        this.setupBreadcrumb()
                    })
                else
                    try {
                        this.emailTemplatesHandlerGetById = emailTemplatesHandler.getById(match?.params?.emailTemplateId)
                        const emailTemplate = await this.emailTemplatesHandlerGetById.fetch()
                        this.setState({
                            item: emailTemplate,
                            iniItem: emailTemplate,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                            this.setupBreadcrumb()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new EmailTemplate(),
                    iniItem: new EmailTemplate(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, emailTemplatesHandler, match, setMessageBar,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.emailTemplatesHandlerUpsert = emailTemplatesHandler.upsert(item, match?.params?.emailTemplateId)
                const emailTemplate = await this.emailTemplatesHandlerUpsert.fetch()

                this.setState({
                    item: emailTemplate,
                    iniItem: emailTemplate,
                    status: Status.RESOLVED,
                    errorField: new ErrorEmailTemplate(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.emailTemplateId)
                    history.replace(`/modele-emails/${emailTemplate.emailTemplateId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorEmailTemplate>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField,
        } = this.state

        if (status === Status.PENDING)
            return <Loader />

        return (
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    this.upsert()
                }}
            >
                <Card
                    title="Champs"
                    iconName="PageData"
                >
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Nom"
                                value={item.name}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, name: newVal } })}
                                errorMessage={errorField.name}
                                required
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Sujet"
                                value={item.subject}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, subject: newVal } })}
                                errorMessage={errorField.subject}
                                required
                            />
                        </Columns.Column>
                        <Columns.Column size="half">
                            <TextField
                                label="Contenu"
                                value={item.content}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => this.setState({ item: { ...item, content: newVal } })}
                                errorMessage={errorField.content}
                                required
                                multiline
                                autoAdjustHeight
                            />
                        </Columns.Column>
                    </Columns>
                </Card>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={this.submitInput}
                    tabIndex={-1}
                />
            </form>
        )
    }
}

IdEmailTemplates.prototype.getUpdatedList = getUpdatedList
IdEmailTemplates.prototype.isValidDate = isValidDate
