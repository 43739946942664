import Types from 'types/types'
import { Column } from 'types/column' // eslint-disable-line
import CvInput from 'components/pages/candidats/[id]/cvInput'
import React from 'react'
import CandidatesHandler from 'requests/handlers/candidatesHandler'

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const candidatColumns = [
    {
        fieldName: 'firstname',
        name: 'Prénom',
        type: Types.STRING,
    },
    {
        fieldName: 'lastname',
        name: 'Nom',
        type: Types.STRING,
    },
    {
        fieldName: 'age',
        name: 'Âge',
        type: Types.STRING,
    },
    {
        fieldName: 'mobilePhone',
        name: 'Téléphone',
        type: Types.STRING,
    },
    {
        fieldName: 'email',
        name: 'Email',
        type: Types.STRING,
    },
    {
        fieldName: 'fileCv',
        name: 'CV',
        type: Types.STRING,
        onRender: item => (
            <CvInput
                label={null}
                isReadOnly
                id={item.candidateId}
                handler={new CandidatesHandler()}
                updateItem={() => null}
                item={/** @type {any} */({
                    fileCv: {
                        fileCvId: item['fileCv.fileCvId'],
                        name: item['fileCv.name'],
                    },
                })}
            />
        ),
    },
]

export default candidatColumns
