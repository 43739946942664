/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Contact from 'requests/objects/contact'

/**
 * EventClient Object
 */
export default class EventClient extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.eventClientId eventClientId of the eventClient
     * @param {number=} data.missionId missionId
     * @param {object=} data.mission mission
     * @param {number=} data.contactId contactId
     * @param {object=} data.contact contact
     * @param {number=} data.userCollaboratorId userCollaboratorId
     * @param {number=} data.eventTypeId eventTypeId
     * @param {object=} data.eventType eventType
     * @param {boolean=} data.hasTodo hasLeft
     * @param {boolean=} data.hasMemory hasMemory
     * @param {boolean=} data.hasBusinessMemory hasBusinessMemory
     * @param {string=} data.comment comment
     * @param {string=} data.date date
     * @param {string=} data.todoDate todoDate
     * @param {object=} data.file file
     * @param {number=} data.fileId fileId
     */
    constructor({
        eventClientId = 0,
        missionId,
        mission = {},
        contactId,
        contact,
        userCollaboratorId,
        eventTypeId,
        eventType,
        hasTodo = false,
        hasMemory = false,
        hasBusinessMemory = false,
        date = '',
        todoDate = '',
        comment = '',
        file,
        fileId,
        ...data
    } = {}) {
        super(data)
        this.eventClientId = eventClientId
        this.missionId = missionId
        this.mission = mission
        this.contactId = contactId
        this.contact = contact ? new Contact(contact) : undefined
        this.userCollaboratorId = userCollaboratorId
        this.eventTypeId = eventTypeId
        this.eventType = eventType
        this.hasTodo = hasTodo
        this.hasMemory = hasMemory
        this.hasBusinessMemory = hasBusinessMemory
        this.date = date
        this.todoDate = todoDate ? new Date(todoDate) : undefined
        this.comment = comment
        this.file = file
        this.fileId = fileId
    }
}

/**
 * EventClient Object used to bind error message
 */
export class ErrorEventClient extends ErrorBase {
    constructor() {
        super()
        this.eventClientId = ''
        this.missionId = ''
        this.contactId = ''
        this.userCollaboratorId = ''
        this.eventTypeId = ''
        this.hasTodo = ''
        this.hasMemory = ''
        this.hasBusinessMemory = ''
        this.date = ''
        this.todoDate = ''
        this.comment = ''
        this.file = ''
        this.fileId = ''
    }
}
