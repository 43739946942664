import React, { PureComponent } from 'react'
import { Menu as BulmaMenu } from 'react-bulma-components'
import { Icon, Text } from '@fluentui/react'
import classNames from 'classnames'
// eslint-disable-next-line import/named
import { MenuElement } from 'pages/_layout'
import { Link } from 'react-router-dom'

/**
 * @typedef {object} MenuProps
 * @property {MenuElement[]} menu Menu to display
 * @property {string} selectedKeyURL Selected Key URL
 * @property {boolean} isLeftMenuOpen Is menu open
 * @property {(isLeftMenuOpen: boolean) => void} setIsLeftMenuOpen Change state of is menu open to parent componenet
 * @augments {PureComponent<MenuProps>}}
 */
export default class Menu extends PureComponent {
    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            menu, selectedKeyURL, setIsLeftMenuOpen, isLeftMenuOpen,
        } = this.props

        return (
            <BulmaMenu>
                <div
                    className={classNames('navbar-burger', { 'is-active': isLeftMenuOpen })}
                    role="button"
                    tabIndex={0}
                    onClick={() => setIsLeftMenuOpen(!isLeftMenuOpen)}
                    onKeyPress={() => setIsLeftMenuOpen(!isLeftMenuOpen)}
                >
                    <span />
                    <span />
                    <span />
                </div>
                <hr />
                {
                    menu.map((x, i) => {
                        /** @type {string} Variable to match subroutes when needed */
                        const selectedKey = (() => {
                            /**
                             * Exemple: "/project/[id]/item"
                             */
                            if (selectedKeyURL.match(/\/utilisateurs\/.*/g))
                                return '/utilisateurs'
                            if (selectedKeyURL.match(/\/candidats\/.*/g))
                                return '/candidats'
                            if (selectedKeyURL.match(/\/informations-commerciales\/.*/g))
                                return '/informations-commerciales'
                            if (selectedKeyURL.match(/\/modele-emails\/.*/g))
                                return '/modele-emails'
                            if (selectedKeyURL.match(/\/clients\/.*/g))
                                return '/clients'
                            if (selectedKeyURL.match(/\/missions\/.*/g))
                                return '/missions'
                            if (selectedKeyURL.match(/\/recherche-telephone\/.*/g))
                                return '/recherche-telephone'

                            return selectedKeyURL
                        })()
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <React.Fragment key={i}>
                                {
                                    <BulmaMenu.List title={x.name ? x.name : ''}>
                                        {
                                            x?.links.map((y, j) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <li key={`${i}_${j}`}>
                                                    <Link // BulmaMenu.List.Item
                                                        to={y.key}
                                                        className={classNames({ 'is-active': y.key === selectedKey })}
                                                        title={y.title}
                                                    >
                                                        <Text variant="medium">
                                                            <Icon
                                                                iconName={y.icon}
                                                            />
                                                            <span>
                                                                {' '}
                                                                {y.title}
                                                            </span>
                                                        </Text>
                                                    </Link>
                                                    {y?.links?.length
                                                        && (
                                                            <BulmaMenu.List>
                                                                {
                                                                    y?.links.map((z, k) => (
                                                                        <Link // BulmaMenu.List.Item
                                                                            // eslint-disable-next-line react/no-array-index-key
                                                                            key={`${i}_${j}_${k}`}
                                                                            to={z.key}
                                                                            className={classNames({ 'is-active': z.key === selectedKey })}
                                                                            title={y.title}
                                                                        >
                                                                            <Text variant="medium">
                                                                                <Icon
                                                                                    iconName={z.icon}

                                                                                />
                                                                                <span>
                                                                                    {' '}
                                                                                    {z.title}
                                                                                </span>
                                                                            </Text>
                                                                        </Link>
                                                                    ))
                                                                }
                                                            </BulmaMenu.List>
                                                        )}
                                                </li>
                                            ))
                                        }
                                    </BulmaMenu.List>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </BulmaMenu>
        )
    }
}
