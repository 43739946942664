import Hunt, { ErrorHunt } from 'requests/objects/hunt'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import BoardComment from 'requests/objects/boardComment'
import FileCv from 'requests/objects/fileCv'
import File from 'requests/objects/file'
import Interview from 'requests/objects/interview'
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'
import { MessageBarType } from '@fluentui/react'

/**
 * HuntHandler
 * @augments {ApiHandler<Hunt, ErrorHunt>}
 */
export default class HuntsHandler extends ApiHandler {
    constructor() {
        super({ type: Hunt, errorType: ErrorHunt, key: 'hunts' })
    }

    /**
     * Get file
     * @param {number} huntId Id
     * @param {number} fileId Id
     * @param {BlobPropertyBag['type']=} type type
     * @returns {RequestApi<Blob>} Request
     */
    getFileCv(huntId, fileId, type) {
        const request = this.initFetchRequest({
            url: [huntId, 'filecvs', fileId], method: 'GET', responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data], { type }))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upload file
     * @param {number} huntId Id
     * @param {globalThis.File} file file
     * @returns {RequestApi<FileCv>} Returns
     */
    uploadFileCv(huntId = undefined, file = undefined) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: [huntId, 'filecvs'],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new FileCv(/** @type {any} */(res.data)?.filecvs))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Remove file
     * @param {number} huntId Id
     * @param {number} fieldId Ids
     * @returns {RequestApi<FileCv>} Request
     */
    removeFileCv(huntId, fieldId) {
        const request = this.initFetchRequest({
            url: [huntId, 'filecvs', fieldId], method: 'DELETE',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get file template
     * @returns {RequestApi<Blob>} Request
     */
    getFileTemplate() {
        const request = this.initFetchRequest({
            url: ['files', 'template'], method: 'GET', responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get file
     * @param {number} huntId Id
     * @param {number} fileId Id
     * @returns {RequestApi<Blob>} Request
     */
    getFile(huntId, fileId) {
        const request = this.initFetchRequest({
            url: [huntId, 'files', fileId], method: 'GET', responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upload file
     * @param {number} huntId Id
     * @param {globalThis.File} file file
     * @returns {RequestApi<File>} Returns
     */
    uploadFile(huntId = undefined, file = undefined) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: [huntId, 'files'],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new File(/** @type {any} */(res.data)?.files))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Remove file
     * @param {number} huntId Id
     * @param {number} fieldId Ids
     * @returns {RequestApi<File>} Request
     */
    removeFile(huntId, fieldId) {
        const request = this.initFetchRequest({
            url: [huntId, 'files', fieldId], method: 'DELETE',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update hunt status
     * @param {number} huntId Id
     * @param {number} huntStatusId Id
     * @returns {RequestApi<Hunt>} Request
     */
    patchStatus(huntId, huntStatusId) {
        const request = this.initFetchRequest({ url: [huntId, 'status', huntStatusId], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update hunt favorite
     * @param {number} huntId Id
     * @param {{isFavorite: boolean}} data data
     * @returns {RequestApi<Hunt>} Request
     */
    patchFavorite(huntId, data) {
        const request = this.initFetchRequest({ url: [huntId, 'favorite'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert comment
     * @param {BoardComment} obj Request
     * @param {number} huntId id
     * @param {number} commentId id
     * @returns {RequestApi<BoardComment>} Request
     */
    // eslint-disable-next-line new-cap
    upsertComment(obj, huntId, commentId) {
        if (commentId) {
            const request = this.initFetchRequest({ url: [huntId, 'comments', commentId], method: 'PUT', data: obj })

            return this.getRequestApi(
                () => request.fetchRequest
                    .then(res => new BoardComment(res.data.comments))
                    .catch(err => {
                        throw this.handleError(err)
                    }),
                request.cancelToken,
            )
        }

        const request = this.initFetchRequest({ url: [huntId, 'comments'], method: 'POST', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new BoardComment(res.data.comments))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Delete comment
     * @param {number} huntId id
     * @param {number} commentId id
     * @returns {RequestApi<BoardComment>} Request
     */
    removeCommentById(huntId, commentId) {
        const request = this.initFetchRequest({ url: [huntId, 'comments', commentId], method: 'DELETE' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new BoardComment(res.data.comments))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update hunt pin comment
     * @param {number} huntId Id
     * @param {number} commentId id
     * @param {{isPinned: boolean}} data data
     * @returns {RequestApi<BoardComment>} Request
     */
    patchPin(huntId, commentId, data) {
        const request = this.initFetchRequest({ url: [huntId, 'comments', commentId, 'pin'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new BoardComment(res.data.comments))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update hunt interview date
     * @param {number} huntId Id
     * @param {Partial<Hunt>} data data
     * @param {object=} params params
     * @returns {RequestApi<Hunt>} Request
     */
    patchInterviewInfo(huntId, data, params = {}) {
        const request = this.initFetchRequest({
            url: [huntId, 'interview-informations'], method: 'PATCH', data, params,
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update hunt interview date
     * @param {number} huntId Id
     * @param {Partial<Hunt>} data data
     * @returns {RequestApi<Hunt>} Request
     */
    patchInterviewClientInfo(huntId, data) {
        const request = this.initFetchRequest({ url: [huntId, 'interview-client-informations'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Import parameters from a mission to candidate
     * @param {number} huntId Id
     * @param {{comment: string, isCode: boolean}} data data
     * @param {boolean=} isForce isForce
     * @returns {RequestApi<Hunt>} Request
     */
    patchMissionCoding(huntId, data, isForce) {
        const request = this.initFetchRequest({
            url: [huntId, 'mission-coding'], method: 'PATCH', params: { isForce }, data,
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Set coding
     * @param {number} huntId Id
     * @param {Hunt} data data
     * @returns {RequestApi<Hunt>} Request
     */
    patchReCoding(huntId, data) {
        const request = this.initFetchRequest({ url: [huntId, 'recoding'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Patch reject
     * @param {number} huntId Id
     * @param {{isRejected: boolean, comment: string}} data data
     * @returns {RequestApi<Hunt>} Request
     */
    patchReject(huntId, data) {
        const request = this.initFetchRequest({ url: [huntId, 'reject'], method: 'PATCH', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert interview
     * @param {Interview} obj Request
     * @param {number} huntId id
     * @param {number} interviewId id
     * @param {boolean=} isValidating isValidating
     * @returns {RequestApi<Interview>} Request
     */
    upsertInterview(obj, huntId, interviewId, isValidating = false) {
        if (interviewId) {
            const request = this.initFetchRequest({
                url: [huntId, 'interviews'], method: 'PUT', data: obj, params: { isValidating },
            })

            return this.getRequestApi(
                () => request.fetchRequest
                    .then(res => {
                        if (isValidating)
                            store.dispatch(setMessageBar({
                                isDisplayed: true,
                                type: MessageBarType.success,
                                message: "L'entretien à bien été validé",
                            }))
                        return new Interview(res.data.interviews)
                    })
                    .catch(err => {
                        throw this.handleError(err)
                    }),
                request.cancelToken,
            )
        }

        const request = this.initFetchRequest({ url: [huntId, 'interviews'], method: 'POST', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Interview(res.data.interviews))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update hunt interview cancel
     * @param {number} huntId Id
     * @returns {RequestApi<Hunt>} Request
     */
    patchInterviewCancel(huntId) {
        const request = this.initFetchRequest({ url: [huntId, 'interview-informations', 'cancel'], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update hunt interview cancel
     * @param {number} huntId Id
     * @returns {RequestApi<Hunt>} Request
     */
    patchInterviewClientCancel(huntId) {
        const request = this.initFetchRequest({ url: [huntId, 'interview-client-informations', 'cancel'], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update hunt isUnderProposal
     * @param {number} huntId Id
     * @param {{isUnderProposal: boolean}} data data
     * @returns {RequestApi<Hunt>} Request
     */
    patchUnderProposal(huntId, data) {
        const request = this.initFetchRequest({ url: [huntId, 'under-proposal'], method: 'PATCH', data })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get interview data in pdf
     * @param {number} huntId huntId param
     * @returns {RequestApi<Blob>} Request
     */
    getPdfInterview(huntId) {
        const request = this.initFetchRequest({
            url: [huntId, 'pdf-interview'],
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Patch transfer
     * @param {number} huntId Id
     * @param {number} missionId Id
     * @returns {RequestApi<Hunt>} Request
     */
    patchTransfer(huntId, missionId) {
        const request = this.initFetchRequest({ url: [huntId, 'transfer', missionId], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
