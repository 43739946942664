import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    Icon,
} from '@fluentui/react'
import Candidacy from 'requests/objects/candidacy'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Status from 'types/status'
import styles from 'styles/components/pages/missions/pivotCandidacy/boardFavoriteButton.module.scss'
import classNames from 'classnames'
import HuntsHandler from 'requests/handlers/huntsHandler'
import Hunt from 'requests/objects/hunt'

/**
 * Candidacy fav button
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {(item: Candidacy | Hunt) => void} props.onUpdate onUpdate
 * @returns {JSX.Element} Returns
 */
export default function BoardFavoriteButton({
    item, handler, onUpdate,
}) {
    const [status, setStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<RequestApi<Candidacy | Hunt>>} */
    const handlerRemove = useRef(null)

    const toggleFavorite = useCallback(async () => {
        if (status === Status.PENDING)
            return
        try {
            setStatus(Status.PENDING)
            handlerRemove.current = handler.patchFavorite(
                (/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId),
                { isFavorite: !item.isFavorite },
            )
            const boardItem = await handlerRemove.current.fetch()
            setStatus(Status.RESOLVED)
            onUpdate(boardItem)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [item, handler, onUpdate, status])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerRemove?.current?.cancel()
    }, [])

    return (
        <Icon
            className={classNames(styles['board-favorite-button'], { [styles['is-active']]: item.isFavorite })}
            iconName={item.isFavorite ? 'FavoriteStarFill' : 'FavoriteStar'}
            title={item.isFavorite ? 'Retirer des favoris' : 'Marquer comme favoris'}
            onClick={toggleFavorite}
        />
    )
}
