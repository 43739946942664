import React, { PureComponent } from 'react'
import {
    Breadcrumb, MessageBar, CommandBar, IIconProps, Text,
} from '@fluentui/react'
import Header from 'components/_layout/header'
import history from 'helpers/history'
import Menu from 'components/_layout/menu'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styles from 'styles/pages/_layout.module.scss'
import EProfile from 'types/profile'

/** @debug {IIconProps|AppProps} */

/**
 * @typedef {object} MenuElement
 * @property {string} name Element name
 * @property {boolean} isDisplay Is element display
 * @property {LinkElement[]} links Refresh app
 */
/**
 * @typedef {object} LinkElement
 * @property {string} key Element key and URL
 * @property {string} title Element title
 * @property {IIconProps['iconName']} icon Element icon
 * @property {boolean} isDisplay Is element display
 * @property {LinkElement[]=} links Refresh app
 */

/**
 * @typedef {object} LayoutProps
 * @property {boolean} isDisplay Is layout is displayed
 * @property {Function} refresh Refresh app
 * @augments {PureComponent<AppProps & LayoutProps>}}
 */
export default class Layout extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {MenuElement[]} */
            menu: [],
            /** @type {boolean} Is Left Menu Open ? */
            isLeftMenuOpen: true,
        }
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setUrl } = this.props

        setUrl(history.location.pathname)
    }

    /**
     * @inheritdoc
     * @param {object} prevProps prevProps
     */
    componentDidUpdate(prevProps) {
        const { isDisplay, me } = this.props

        if (prevProps.isDisplay !== isDisplay && !isDisplay)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                menu: [],
                isLeftMenuOpen: false,
            })

        // Re render menu if user when info user change
        if (prevProps.me !== me)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                menu: /** @type {MenuElement[]} */([
                    {
                        name: '',
                        isDisplay: true,
                        links: [
                            {
                                key: '/',
                                title: 'Accueil',
                                icon: 'Home',
                                isDisplay: true,
                            },
                            {
                                key: '/informations-commerciales',
                                title: 'Inf. commerciales',
                                icon: 'Lightbulb',
                                isDisplay: true,
                            },
                            {
                                key: '/clients',
                                title: 'Clients',
                                icon: 'FavoriteStarFill',
                                isDisplay: true,
                            },
                            {
                                key: '/carte-missions',
                                title: 'Carte missions',
                                icon: 'Nav2DMapView',
                                isDisplay: true,
                            },
                            {
                                key: '/candidats',
                                title: 'Candidats',
                                icon: 'ProfileSearch',
                                isDisplay: true,
                            },
                            {
                                key: '/missions',
                                title: 'Missions',
                                icon: 'Money',
                                isDisplay: true,
                            },
                            {
                                key: '/utilisateurs',
                                title: 'Utilisateurs',
                                icon: 'Group',
                                isDisplay: me?.profileId === EProfile.Administrator,
                            }, {
                                key: '/modele-emails',
                                title: 'Modèles emails',
                                icon: 'Mail',
                                isDisplay: true,
                            }, {
                                key: '/recherche-telephone',
                                title: 'Recherche télephone',
                                icon: 'Phone',
                                isDisplay: true,
                            },
                        ],
                    },
                ]),
            })
    }

    /**
     * Filter menu with element that must be displayed
     * @param {MenuElement[]} menu menu
     * @returns {MenuElement[]} Filtered menu
     */
    // eslint-disable-next-line class-methods-use-this
    filterMenu(menu) {
        return menu
            .filter(group => group.isDisplay)
            .map(group => ({
                ...group,
                links: group.links.filter(link => link.isDisplay),
            }))
    }

    render() {
        const {
            selectedKeyURL, breadcrumb, command, messageBar, me, param, signOut, isDisplay, children, refresh, setMessageBar, title,
        } = this.props
        const { menu, isLeftMenuOpen } = this.state

        if (!isDisplay)
            return children

        const filteredMenu = this.filterMenu(menu)

        return (
            <section
                className={classNames(styles.layout, { 'is-menu-close': !isLeftMenuOpen })}
            >
                <Header
                    menu={filteredMenu}
                    me={me}
                    param={param}
                    refresh={() => this.setState({ menu: [] }, () => refresh())}
                    signOut={signOut}
                />
                <div className={styles['content-layout']}>
                    <div className={classNames(styles['menu-layout'], 'is-hidden-touch', { [styles['is-close']]: !isLeftMenuOpen })}>
                        <Menu
                            setIsLeftMenuOpen={newVal => this.setState({ isLeftMenuOpen: newVal })}
                            isLeftMenuOpen={isLeftMenuOpen}
                            menu={filteredMenu}
                            selectedKeyURL={selectedKeyURL}
                        />
                    </div>
                    <div className={styles['main-layout']}>
                        <CommandBar
                            items={command}
                        />
                        <div className={styles['body-layout']}>
                            <div className={styles['body-content-layout']}>
                                <Text
                                    variant="large"
                                    className={styles['body-content-layout-title']}
                                    as="p"
                                >
                                    {title}
                                </Text>
                                <Breadcrumb
                                    items={breadcrumb}
                                    maxDisplayedItems={5}
                                    tooltipHostProps={{ content: undefined }}
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    onRenderItem={(props, defaultRender) => (props.href
                                        ? (
                                            <Link to={props.href}>
                                                {defaultRender({ ...props, href: undefined })}
                                            </Link>
                                        )
                                        : defaultRender(props))}
                                />
                                {
                                    messageBar?.isDisplayed
                                    && (
                                        <>
                                            <MessageBar
                                                messageBarType={messageBar.type}
                                                isMultiline={false}
                                                truncated
                                                onDismiss={() => setMessageBar({ isDisplayed: false })}
                                            >
                                                {messageBar.message}
                                            </MessageBar>
                                            <br />
                                        </>
                                    )
                                }
                                {children}
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
