/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Candidate from 'requests/objects/candidate'
import User from 'requests/objects/user'

/**
 * ResearchCandidate Object
 */
export default class ResearchCandidate extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.researchCandidateId researchCandidateId of the ResearchCandidate
     * @param {string=} data.name name
     * @param {object=} data.request request
     * @param {number=} data.createdByUserId createdByUserId
     * @param {object=} data.createdByUser createdByUser
     * @param {object[]=} data.excludedCandidates request
     */
    constructor({
        researchCandidateId = 0,
        name = '',
        request = {},
        createdByUserId,
        createdByUser,
        excludedCandidates = [],
        ...data
    } = {}) {
        super(data)
        this.researchCandidateId = researchCandidateId
        this.name = name
        this.request = request
        this.createdByUserId = createdByUserId
        this.createdByUser = createdByUser ? new User(createdByUser) : undefined
        this.excludedCandidates = excludedCandidates?.length ? excludedCandidates.map(x => new Candidate(x)) : []
    }
}

/**
 * ResearchCandidate Object used to bind error message
 */
export class ErrorResearchCandidate extends ErrorBase {
    constructor() {
        super()
        this.researchCandidateId = ''
        this.name = ''
        this.request = ''
        this.createdByUserId = ''
        this.createdByUser = ''
        this.excludedCandidates = ''
    }
}
