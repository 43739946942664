import User, { ErrorUser } from 'requests/objects/user'
// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'

/**
 * UsersHandler
 * @augments {ApiHandler<User, ErrorUser>}
 */
export default class UsersHandler extends ApiHandler {
    constructor() {
        super({ type: User, errorType: ErrorUser, key: 'users' })
    }

    /**
     * Authentificate User
     * @param {object} data data
     * @param {string} data.email email
     * @param {string} data.password password
     * @returns {RequestApi<User>} Request
     */
    login({ email, password }) {
        const request = this.initFetchRequest({ url: ['login'], method: 'POST', data: { email, password } })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Authentificate User
     * @param {object} data data
     * @param {string} data.refreshToken refreshToken
     * @param {string} data.accessToken accessToken
     * @returns {RequestApi<User>} Request
     */
    logout({ refreshToken, accessToken }) {
        const request = this.initFetchRequest({
            url: ['logout'], method: 'POST', data: { refreshToken }, headers: { Authorization: `Bearer ${accessToken}` },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    // eslint-disable-next-line no-console
                    console.error(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Authentificate User
     * @param {object} data data
     * @param {string} data.refreshToken refreshToken
     * @returns {RequestApi<User>} Request
     */
    refresh({ refreshToken }) {
        const request = this.initFetchRequest({ url: ['refresh'], method: 'PATCH', data: { refreshToken } })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get informations about current user
     * @returns {RequestApi<User>} Request
     */
    getMe() {
        const request = this.initFetchRequest({ url: ['me'] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Patch forgot password
     * @param {object} data data
     * @param {string} data.email email
     * @returns {RequestApi<User>} Request
     */
    forgotPassword({ email }) {
        const request = this.initFetchRequest({ url: ['forgot-password'], method: 'PATCH', data: { email } })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(() => { })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Patch new password
     * @param {string} key key
     * @param {object} data data
     * @param {string} data.password password
     * @param {string} data.confirmPassword confirmPassword
     * @returns {RequestApi<User>} Request
     */
    newPassword(key, { password, confirmPassword }) {
        const request = this.initFetchRequest({ url: ['new-password', key], method: 'PATCH', data: { password, confirmPassword } })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(() => { })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
