/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * BoardColumn Object
 */
export default class BoardColumn extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.boardHistoricId boardHistoricId of the BoardColumn
     * @param {number=} data.candidacyId candidacyId
     * @param {object=} data.candidacy candidacy
     * @param {number=} data.huntId huntId
     * @param {object=} data.hunt hunt
     * @param {number=} data.createdByUserId createdByUserId
     * @param {object=} data.createdByUser createdByUser
     * @param {number=} data.previousBoardColumnId previousBoardColumnId
     * @param {object=} data.previousBoardColumn previousBoardColumn
     * @param {number=} data.currentBoardColumnId currentBoardColumnId
     * @param {object=} data.currentBoardColumn currentBoardColumn
     * @param {string=} data.createdAt createdAt
     */
    constructor({
        boardHistoricId = 0,
        candidacyId,
        candidacy = {},
        huntId,
        hunt = {},
        createdByUserId,
        createdByUser = {},
        previousBoardColumnId,
        previousBoardColumn = {},
        currentBoardColumnId,
        currentBoardColumn = {},
        createdAt,
        ...data
    } = {}) {
        super(data)
        this.boardHistoricId = boardHistoricId
        this.candidacyId = candidacyId
        this.candidacy = candidacy
        this.huntId = huntId
        this.hunt = hunt
        this.createdByUserId = createdByUserId
        this.createdByUser = createdByUser
        this.previousBoardColumnId = previousBoardColumnId
        this.previousBoardColumn = previousBoardColumn
        this.currentBoardColumnId = currentBoardColumnId
        this.currentBoardColumn = currentBoardColumn
        this.createdAt = createdAt ? new Date(createdAt) : undefined
    }
}

/**
 * BoardColumn Object used to bind error message
 */
export class ErrorBoardColumn extends ErrorBase {
}
