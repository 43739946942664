import ParentCompany, { ErrorParentCompany } from 'requests/objects/parentCompany'
// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler from 'requests/apiHandler'
import NotImplementedError from 'requests/errors/notImplementedError'

/**
 * ParentCompaniesHandler
 * @augments {ApiHandler<ParentCompany, ErrorParentCompany>}
 */
export default class ParentCompaniesHandler extends ApiHandler {
    constructor() {
        super({ type: ParentCompany, errorType: ErrorParentCompany, key: 'parent-companies' })
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    getAll() {
        throw new NotImplementedError()
    }
}
