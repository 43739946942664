import { IComboBoxOption, IDropdownOption, IColumn } from '@fluentui/react' // eslint-disable-line
import Types from 'types/types'
import { Column } from 'types/column' // eslint-disable-line
import flattenObj from 'helpers/methods/flattenObj'
import Time from 'helpers/methods/time'

/**
 * Handle render of column
 * @param {Column[]} columnsList columnsList
 * @param {string} colName colName
 * @param {IColumn | Column} column column
 * @param {boolean=} isOnlyText Force to render text, not react
 * @returns {(item?: any, index?: number, column?: IColumn) => any} onRender function
 */
function handleRenderColumn(columnsList, colName, column, isOnlyText = false) {
    // eslint-disable-next-line
    /** Handle special case in list */
    switch (columnsList.find(x => x.name === colName)?.type) {
        case Types.DATE:
            if (isOnlyText)
                return item => item[column.fieldName]?.toISOString()

            return item => (item[column.fieldName] ? Time(item[column.fieldName]).getCleanDate({
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }) : '')

        case Types.DATETIME:
            if (isOnlyText)
                return item => item[column.fieldName]?.toISOString()

            return item => (item[column.fieldName] ? Time(item[column.fieldName]).getCleanDate({
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }) : '')

        case Types.MONTHYEAR:
            // eslint-disable-next-line no-unsafe-optional-chaining
            return item => `${item[column.fieldName]?.getFullYear()}-${String(`00${item[column.fieldName]?.getMonth() + 1}`).slice(-2)}`

        case Types.BOOLEAN:
            return item => {
                if (item[column.fieldName] === true)
                    return 'Oui'
                if (item[column.fieldName] === false)
                    return 'Non'
                return ''
            }

        default:
            return null
    }
}

/**
 * Method called by "onColumnClick"
 * Handle: String, Boolean, Date
 * @param {object} items Items to sort
 * @param {string} sortBy Column name
 * @param {boolean} descending Descending or ascending
 * @returns {object[]} Items sorted
 */
function sortItems(items, sortBy, descending = false) {
    return items.slice(0).sort((a, b) => {
        // Force null item to go at the bottom
        if (
            a[sortBy] === undefined
            || a[sortBy] === null
            || (typeof a[sortBy] === 'string' && a[sortBy]?.trim() === '')
        )
            return 1

        // Handle sort on number
        // if (sortBy === 'NB_HEURES_PAYEES') {
        //     a[sortBy] = parseFloat(a[sortBy])
        //     b[sortBy] = parseFloat(b[sortBy])
        // }

        if (descending) {
            if (typeof a[sortBy] === 'string')
                return a[sortBy]?.trim()?.toLowerCase() < b[sortBy]?.trim()?.toLowerCase() ? 1 : -1
            return a[sortBy] < b[sortBy] ? 1 : -1
        }
        if (typeof a[sortBy] === 'string')
            return a[sortBy]?.trim()?.toLowerCase() > b[sortBy]?.trim()?.toLowerCase() ? 1 : -1
        return a[sortBy] > b[sortBy] ? 1 : -1
    })
}

/**
 * Method to handle column click in <DetailList>
 * @param {object} params Params to pass
 * @param {string} params.colName Columns of the <DetailList> need to be in state to be sortable, ex: this.state.columns => "columns"
 * @param {string[]} params.dataName Dataname of data populating the <DetailList> can be nested object or not,
 *  ex: this.state.item => ["item"]; this.state.element.item => ["element", "item"]
 * @param {string} params.source Can be "state" or "props"
 * @param {Function=} params.action Function to update props
 * @param {boolean=} params.isFlatten Is items need to be flatten
 * @param {object} ev ev
 * @param {object} column column
 */
// eslint-disable-next-line default-param-last
function onColumnClick(params = {
    colName: 'columns', dataName: ['items'], source: 'state', action: () => null, isFlatten: false,
}, ev, column) {
    if (!Array.isArray(params.dataName) || params.dataName.length > 2 || params.dataName.length < 1) {
        // eslint-disable-next-line no-console
        console.error("'params.dataName' should be array with one or two arguments only")
        return
    }
    const columns = this.state[params.colName]
    let items = params.dataName.length > 1 ? this[params.source][params.dataName[0]][params.dataName[1]] : this[params.source][params.dataName[0]]
    // var a = function(x, s) { if (s.length > 1) { return a(x[s[0]], s.slice(1, s.length)) } else { return x[s[0]] } }

    if (params.isFlatten)
        items = items.map(x => flattenObj(x))

    let newItems = items.slice()
    const newColumns = columns.slice()
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0]
    newColumns.forEach(newCol => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending
            currColumn.isSorted = true
        } else {
            // eslint-disable-next-line no-param-reassign
            newCol.isSorted = false
            // eslint-disable-next-line no-param-reassign
            newCol.isSortedDescending = true
        }
    })
    newItems = sortItems(newItems, currColumn.fieldName || '', currColumn.isSortedDescending)

    if (params.source === 'state')
        if (params.dataName.length > 1)
            this.setState({
                [params.dataName[0]]: {
                    ...this.state[params.dataName[0]],
                    [params.dataName[1]]: newItems,
                },
            })
        else
            this.setState({
                [params.colName]: newColumns,
                [params.dataName[0]]: newItems,
            })

    else if (typeof params.action === 'function')
        params.action(newItems)
    else
        this[params.source][params.action](newItems)
}

/**
 * Handle multiple choice dropdown
 * @param {string[] | number[] | object[]} selectedItems All selected items
 * @param {IComboBoxOption | IDropdownOption} item Item clicked
 * @returns {object[]} Selected items
 */
function getUpdatedList(selectedItems, item) {
    const newSelectedItems = [...selectedItems]
    if (item.selected) {
        newSelectedItems.push(item.key)
    } else {
        const currIndex = newSelectedItems.indexOf(item.key)
        if (currIndex > -1)
            newSelectedItems.splice(currIndex, 1)
    }
    return newSelectedItems
}

/**
 * Is Valid date
 * @param {any} dt dt
 * @returns {boolean} boolean
 */
function isValidDate(dt) {
    return dt instanceof Date && !Number.isNaN(new Date(dt).getTime())
}

export {
    onColumnClick, getUpdatedList, isValidDate, handleRenderColumn, sortItems,
}
