import { TooltipHost } from '@fluentui/react'
import classNames from 'classnames'
import React from 'react'
import styles from 'styles/components/pages/index/homeCardTitle.module.scss'

/**
 * @param {object} props Props
 * @param {string} props.leftText leftText
 * @param {JSX.Element | string=} props.rightText rightText
 * @param {number=} props.rightNumber rightNumber
 * @param {string=} props.emoji emoji
 * @param {('grey-blue' | 'purple' | 'light-blue')=} props.variant variant
 * @param {string=} props.tooltipText tooltipText
 * @returns {JSX.Element} Returns
 */
export default function HomeCardTitle({
    leftText, rightText, rightNumber, variant, emoji, tooltipText,
}) {
    return (

        <span
            className={classNames(styles['home-card-title'], { [styles[`is-${variant}`]]: variant })}
        >
            <TooltipHost
                content={tooltipText}
            >
                <span className={styles['home-card-title-left']}>
                    {leftText}
                </span>

            </TooltipHost>
            <span className={styles['home-card-title-right']}>
                {emoji && (
                    <span className={styles['home-card-title-right-emoji']}>
                        {emoji}
                        {' '}
                    </span>
                )}
                {rightNumber !== undefined && (
                    <span className={styles['home-card-title-right-number']}>
                        {rightNumber}
                        {' '}
                    </span>
                )}
                {rightText && (
                    <span className={styles['home-card-title-right-text']}>
                        {rightText}
                    </span>
                )}
            </span>

        </span>
    )
}
