import Contact, { ErrorContact } from 'requests/objects/contact'
import NotImplementedError from 'requests/errors/notImplementedError'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import EventClient from 'requests/objects/eventClient'
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'
import { MessageBarType } from '@fluentui/react'
import File from 'requests/objects/file'

/**
 * ContactsHandler
 * @augments {ApiHandler<Contact, ErrorContact>}
 */
export default class ContactsHandler extends ApiHandler {
    constructor() {
        super({ type: Contact, errorType: ErrorContact, key: 'contacts' })
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    getAll() {
        throw new NotImplementedError()
    }

    /**
     * Get one by ID
     * @param {number} contactId Id
     * @param {number} eventClientId Id
     * @returns {RequestApi<EventClient>} Request
     */
    getEventClientById(contactId, eventClientId) {
        const request = this.initFetchRequest({ url: [contactId, 'event-clients', eventClientId] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new EventClient(res.data['event-clients']))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Create
     * @param {number} contactId Id
     * @param {EventClient} obj Obj
     * @returns {RequestApi<EventClient>} Request
     */
    // eslint-disable-next-line new-cap
    createEventClient(contactId, obj = new EventClient()) {
        const request = this.initFetchRequest({ method: 'POST', data: obj, url: [contactId, 'event-clients'] })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été ajouté" }))
                    // eslint-disable-next-line new-cap
                    return new EventClient(res.data['event-clients'])
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update
     * @param {number} contactId Id
     * @param {number} eventClientId Id
     * @param {EventClient} obj Obj
     * @returns {RequestApi<EventClient>} Request
     */
    // eslint-disable-next-line new-cap
    updateEventClientById(contactId, eventClientId, obj = new EventClient()) {
        const request = this.initFetchRequest({ url: [contactId, 'event-clients', eventClientId], method: 'PUT', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été mis à jour" }))
                    // eslint-disable-next-line new-cap
                    return new EventClient(res.data['event-clients'])
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert
     * @param {number} contactId Id
     * @param {number} eventClientId Id
     * @param {EventClient} obj Request
     * @returns {RequestApi<EventClient>} Request
     */
    // eslint-disable-next-line new-cap
    upsertEventClient(contactId, eventClientId, obj = new EventClient()) {
        if (eventClientId)
            return this.updateEventClientById(contactId, eventClientId, obj)

        return this.createEventClient(contactId, obj)
    }

    /**
     * Delete
     * @param {number} contactId Id
     * @param {number} eventClientId Id
     * @returns {RequestApi<EventClient>} Request
     */
    removeEventClientById(contactId, eventClientId) {
        const request = this.initFetchRequest({ url: [contactId, 'event-clients', eventClientId], method: 'DELETE' })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: "L'élément a bien été supprimé" }))
                    // eslint-disable-next-line new-cap
                    return new EventClient(res.data['event-clients'])
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get file
     * @param {number} contactId Id
     * @param {number} eventClientId Id
     * @param {number} fileId Id
     * @returns {RequestApi<Blob>} Request
     */
    getEventClientFile(contactId, eventClientId, fileId) {
        const request = this.initFetchRequest({
            url: [contactId, 'event-clients', eventClientId, 'files', fileId], method: 'GET', responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upload file
     * @param {number} contactId Id
     * @param {number} eventClientId Id
     * @param {globalThis.File} file file
     * @returns {RequestApi<File>} Returns
     */
    uploadEventClientFile(contactId = undefined, eventClientId = undefined, file = undefined) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: [contactId, 'event-clients', eventClientId, 'files'],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new File(/** @type {any} */(res.data)?.files))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Remove file
     * @param {number} contactId Id
     * @param {number} eventClientId Id
     * @param {number} fileId Id
     * @returns {RequestApi<File>} Request
     */
    removeEventClientFile(contactId, eventClientId, fileId) {
        const request = this.initFetchRequest({
            url: [contactId, 'event-clients', eventClientId, 'files', fileId], method: 'DELETE',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
