import React, { PureComponent } from 'react'
import { Navbar } from 'react-bulma-components'
import {
    Icon, Panel, TextField,
} from '@fluentui/react'
import history from 'helpers/history'
import { MenuElement } from 'pages/_layout' //eslint-disable-line
import enhanceWithClickOutside from 'react-click-outside'
import { Link } from 'react-router-dom'
import User from 'requests/objects/user'

const Header = enhanceWithClickOutside(
    /**
     * @typedef {object} HeaderProps
     * @property {MenuElement[]} menu Menu to display
     * @property {User} me User informations
     * @property {object} param Constants
     * @property {function():void} signOut Sign out
     * @property {function():void} refresh Refresh app
     * @augments {PureComponent<HeaderProps>}}
     */
    // eslint-disable-next-line react/display-name
    class extends PureComponent {
        constructor(props) {
            super(props)
            this.state = {
                /** @type {boolean} Is burger menu open on mobile */
                isActive: false,
                /** @type {boolean} Is Panel user open */
                isOpenUser: false,
            }
        }

        /**
         * Handle click outside component
         */
        handleClickOutside() {
            this.setState({ isActive: false })
        }

        /**
         * Render component
         * @returns {JSX.Element} Element
         */
        render() {
            const {
                menu, me, signOut, refresh,
            } = this.props
            const { isActive, isOpenUser } = this.state

            return (
                <>
                    <Navbar
                        active={isActive}
                        transparent={false}
                    >
                        <Navbar.Brand>
                            <Navbar.Item
                                renderAs="a"
                                onClick={() => history.push('/')}
                            >
                                <img
                                    // eslint-disable-next-line global-require
                                    src={require('assets/img/logo.png').toString()}
                                    alt={process.env.REACT_APP_SHORT_NAME}
                                />
                                <span>
                                    {' '}
                                    {process.env.REACT_APP_SHORT_NAME}
                                </span>
                            </Navbar.Item>
                            <Navbar.Burger onClick={() => this.setState({ isActive: !isActive })} />
                        </Navbar.Brand>
                        <Navbar.Menu>
                            <Navbar.Container className="is-hidden-desktop">
                                {
                                    menu.map((x, i) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <Navbar.Item key={i}>
                                            {
                                                x.name
                                                && (
                                                    <Navbar.Link arrowless>
                                                        {x.name}
                                                    </Navbar.Link>
                                                )
                                            }
                                            <Navbar.Dropdown>
                                                {
                                                    x?.links.map((y, j) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <React.Fragment key={`${i}_${j}`}>
                                                            <Link
                                                                className="navbar-item"
                                                                to={y.key}
                                                                onClick={() => this.setState({ isActive: false })}
                                                            >
                                                                <Icon iconName={y.icon} />
                                                                <span>
                                                                    {' '}
                                                                    {y.title}
                                                                </span>
                                                            </Link>
                                                            {
                                                                y?.links?.length
                                                                && y?.links.map((z, k) => (
                                                                    <Link
                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                        key={`${i}_${j}_${k}`}
                                                                        className="navbar-item"
                                                                        to={z.key}
                                                                        onClick={() => this.setState({ isActive: false })}
                                                                    >
                                                                        <Icon iconName={z.icon} />
                                                                        <span>
                                                                            {' '}
                                                                            {z.title}
                                                                        </span>
                                                                    </Link>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </Navbar.Dropdown>
                                        </Navbar.Item>
                                    ))
                                }
                                <Navbar.Item>
                                    <Navbar.Link arrowless>
                                        Autre
                                    </Navbar.Link>
                                    <Navbar.Dropdown>
                                        <Navbar.Item
                                            onClick={() => this.setState({ isActive: false }, () => refresh())}
                                        >
                                            <Icon iconName="Refresh" />
                                            {' '}
                                            Rafraichir
                                        </Navbar.Item>
                                        <Navbar.Item
                                            onClick={() => this.setState({ isOpenUser: true })}
                                        >
                                            <Icon iconName="Contact" />
                                            {' '}
                                            Utilisateur
                                        </Navbar.Item>
                                        <Navbar.Item
                                            onClick={() => signOut()}
                                        >
                                            <Icon iconName="SignOut" />
                                            {' '}
                                            Se déconnecter
                                        </Navbar.Item>
                                    </Navbar.Dropdown>
                                </Navbar.Item>
                            </Navbar.Container>
                            <Navbar.Container
                                align="right"
                                className="is-hidden-touch"
                            >
                                <Navbar.Item
                                    className="is-tab"
                                    onClick={() => refresh()}
                                >
                                    <Icon iconName="Refresh" />
                                </Navbar.Item>
                                <Navbar.Item
                                    className="is-tab"
                                    onClick={() => this.setState({ isOpenUser: true })}
                                >
                                    <Icon iconName="Contact" />
                                </Navbar.Item>
                                <Navbar.Item
                                    className="is-tab"
                                    onClick={() => signOut()}
                                >
                                    <Icon iconName="SignOut" />
                                </Navbar.Item>
                            </Navbar.Container>
                        </Navbar.Menu>
                    </Navbar>
                    <Panel
                        className="panel-user"
                        headerText="Votre compte"
                        isLightDismiss
                        isOpen={isOpenUser}
                        onDismiss={() => this.setState({ isOpenUser: false })}
                        closeButtonAriaLabel="Close"
                    >
                        <br />
                        <TextField
                            label="Nom"
                            value={`${me?.lastname ?? ''} ${me?.firstname ?? ''}`}
                            borderless
                            readOnly
                        />
                        <br />
                        <TextField
                            label="Email"
                            value={me?.email}
                            borderless
                            readOnly
                        />
                        <br />
                        <TextField
                            label="Profil"
                            value={me?.profile?.name}
                            borderless
                            readOnly
                        />
                    </Panel>
                </>
            )
        }
    },
)

export default Header
