import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, PrimaryButton, TextField, Toggle, Label,
} from '@fluentui/react'
import Candidacy, { ErrorCandidacy } from 'requests/objects/candidacy'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Status from 'types/status'
import Hunt, { ErrorHunt } from 'requests/objects/hunt'
import HuntsHandler from 'requests/handlers/huntsHandler'

/**
 * Coding card
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {boolean=} props.isForce isForce
 * @param {(item: Candidacy | Hunt) => void} props.onUpdate onUpdate
 * @returns {JSX.Element} Returns
 */
export default function BoardMissionCodingModal({
    item: itemProps, handler, isVisible, setIsVisible, isForce = false, onUpdate,
}) {
    const [item, setItem] = useState({ comment: itemProps?.comment, isCode: false })
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(/** @type {ErrorHunt | ErrorCandidacy} */({}))

    /** @type {React.MutableRefObject<RequestApi<Candidacy | Hunt>>} */
    const handlerUpdateBoardItem = useRef(null)

    const update = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerUpdateBoardItem.current = handler.patchMissionCoding(
                /** @type {Candidacy} */(itemProps).candidacyId || /** @type {Hunt} */(itemProps).huntId,
                item,
                isForce,
            )
            const newItem = await handlerUpdateBoardItem.current.fetch()
            onUpdate(newItem)
            setStatus(Status.RESOLVED)
            setIsVisible(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorHunt | ErrorCandidacy>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [handler, itemProps, item, isForce, onUpdate, setIsVisible])

    // Update local item on props change
    useEffect(() => {
        if (isVisible)
            setItem({ comment: itemProps?.comment, isCode: false })
    }, [isVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    // Cancel request on dismount
    useEffect(() => () => {
        handlerUpdateBoardItem?.current?.cancel()
    }, [])

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Coder le candidat',
                subText: 'Voulez-vous coder les informations du candidat par rapport aux informations de la mission?',
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
        >
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    update()
                }}
            >
                <Label
                    required
                    htmlFor="isCode"
                >
                    Coder ?
                </Label>
                <Toggle
                    id="isCode"
                    onText="Oui"
                    offText="Non"
                    checked={item.isCode}
                    onChange={(_ev, checked) => setItem(prevValue => ({ ...prevValue, isCode: checked }))}
                    disabled={status === Status.PENDING}
                    required
                />
                <TextField
                    label="Commentaire"
                    value={item.comment || ''}
                    readOnly={status === Status.PENDING}
                    borderless={status === Status.PENDING}
                    onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, comment: newVal?.toUpperCase() }))}
                    errorMessage={errorField.comment}
                    multiline
                    autoAdjustHeight
                />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text="Annuler"
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        type="submit"
                        text="Enregistrer"
                        disabled={status === Status.PENDING}
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
