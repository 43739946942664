import React, { useCallback, useState } from 'react'
import {
    ConstrainMode, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList, Text,
} from '@fluentui/react'
import Status from 'types/status'
import { sortItems } from 'helpers/methods/common'
import flattenObj from 'helpers/methods/flattenObj'

/**
 * @param {object} props Props
 * @param {import("@fluentui/react").IColumn[]} props.columns columns
 * @param {any[]} props.items items
 * @param {import('@fluentui/react').IRenderFunction<import('@fluentui/react').IDetailsRowProps>=} props.onRenderRow onRenderRow
 * @param {Status} props.status status
 * @param {string=} props.noDataText noDataText
 * @returns {JSX.Element} Element
 */
export default function SortableList({
    columns: columnsProps, items: itemsProps, onRenderRow, status, noDataText,
}) {
    const [columns, setColumns] = useState(columnsProps)
    const [items, setItems] = useState(itemsProps.map(x => flattenObj(x)))

    const onColumnClick = useCallback((_ev, column) => {
        const newColumns = columns.slice()

        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0]

        newColumns.forEach(newCol => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending
                currColumn.isSorted = true
            } else {
                const colCopy = { ...newCol } // Create a copy of newCol
                colCopy.isSorted = false
                colCopy.isSortedDescending = true
                // Update the array element with the modified copy
                Object.assign(newColumns, { [newColumns.indexOf(newCol)]: colCopy })
            }
        })

        const newItems = sortItems(items, currColumn.fieldName, currColumn.isSortedDescending)

        setColumns(newColumns)
        setItems(newItems)
    }, [columns, items])

    return (
        <>
            <ShimmeredDetailsList
                items={items}
                columns={columns.map(col => ({ ...col, onColumnClick }))}
                enableShimmer={status === Status.PENDING}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                onRenderRow={onRenderRow}
            />
            {!items?.length && status !== Status.IDLE
                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{noDataText || 'Aucun résultat'}</Text>}
        </>

    )
}
