/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import JobBoard from 'requests/objects/jobBoard'

/**
 * DiffusionJobBoard Object
 */
export default class DiffusionJobBoard extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.diffusionJobBoardId diffusionJobBoardId
     * @param {string=} data.status status
     * @param {string=} data.reason reason
     * @param {number=} data.jobBoardId jobBoardId
     * @param {JobBoard=} data.jobBoard jobBoard
     * @param {number=} data.missionId missionId
     * @param {object=} data.mission mission
     */
    constructor({
        diffusionJobBoardId,
        status,
        reason,
        jobBoardId,
        jobBoard,
        missionId,
        mission,
        ...data
    } = {}) {
        super(data)
        this.diffusionJobBoardId = diffusionJobBoardId
        this.status = status
        this.reason = reason
        this.jobBoardId = jobBoardId
        this.jobBoard = jobBoard ? new JobBoard(jobBoard) : undefined
        this.missionId = missionId
        this.mission = mission
    }
}

/**
 * diffusionJobBoard Object used to bind error message
 */
export class ErrordiffusionJobBoard extends ErrorBase {
    constructor() {
        super()
        this.diffusionJobBoardId = ''
        this.status = ''
        this.reason = ''
        this.jobBoardId = ''
        this.jobBoard = ''
        this.missionId = ''
        this.mission = ''
    }
}
