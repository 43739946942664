import React, {
    useCallback, useEffect, useRef,
} from 'react'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import HandleBlob from 'helpers/methods/blob'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FileInput from 'components/inputs/fileInput'
import File from 'requests/objects/file'
import EventClient from 'requests/objects/eventClient'
import ContactsHandler from 'requests/handlers/contactsHanlder'

/**
 * Files list
 * @param {object} props Props
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {number} props.eventClientId id
 * @param {number} props.contactId id
 * @param {ContactsHandler} props.handler CandidatesHandler
 * @param {(file: File) => void} props.updateItem updateItem
 * @param {EventClient} props.item Candidate
 * @param {string=} props.label Label
 * @returns {JSX.Element} Returns
 */
export default function EventFileInput({
    isReadOnly = false,
    eventClientId = 0,
    contactId = 0,
    handler,
    updateItem = () => null,
    item = new EventClient(),
    label = 'Fichier',
}) {
    /** @type {React.MutableRefObject<RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<File>>} */
    const handlerUploadFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<File>>} */
    const handlerRemoveFile = useRef(null)

    const download = useCallback(async () => {
        try {
            handlerGetFile.current = handler.getEventClientFile(contactId, eventClientId, item.file?.fileId)
            const blob = await handlerGetFile.current.fetch()
            HandleBlob.download(blob, item?.file?.name)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [handler, eventClientId, contactId, item.file?.fileId, item.file?.name])

    const upload = useCallback(
        /**
         * @param {globalThis.File} file file
         */
        async file => {
            try {
                handlerUploadFile.current = handler.uploadEventClientFile(contactId, eventClientId, file)
                const itemFile = await handlerUploadFile.current.fetch()
                updateItem(itemFile)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [handler, contactId, eventClientId, updateItem],
    )

    const remove = useCallback(async () => {
        try {
            handlerRemoveFile.current = handler.removeEventClientFile(contactId, eventClientId, item.file?.fileId)
            await handlerRemoveFile.current.fetch()
            updateItem(new File())
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [handler, eventClientId, contactId, item.file?.fileId, updateItem])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerGetFile?.current?.cancel()
        handlerUploadFile?.current?.cancel()
        handlerRemoveFile?.current?.cancel()
    }, [])

    return (
        <FileInput
            label={label}
            labelUpload="Télécharger"
            isReadOnly={isReadOnly}
            isDisabled={!eventClientId}
            fileName={item.file?.name}
            tooltipContent=""
            onDownload={() => download()}
            // onOpen={() => download(true)}
            onUpload={file => upload(file)}
            onDelete={() => remove()}
        />
    )
}
