/* eslint-disable max-classes-per-file */

import Time from 'helpers/methods/time'
import Base, { ErrorBase } from 'requests/objects/_base'
import FileCv from 'requests/objects/fileCv'
import Candidacy from 'requests/objects/candidacy'
import Hunt from 'requests/objects/hunt'

/**
 * Candidate Object
 */
export default class Candidate extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.candidateId candidateId of the Candidate
     * @param {string=} data.lastname lastname
     * @param {string=} data.firstname firstname
     * @param {string=} data.zipCode zipCode
     * @param {number=} data.cityId city
     * @param {object=} data.city city
     * @param {string=} data.birthdate birthdate
     * @param {string=} data.landlinePhone landlinePhone
     * @param {string=} data.mobilePhone mobilePhone
     * @param {string=} data.email email
     * @param {string=} data.activity activity
     * @param {string=} data.information information
     * @param {number=} data.civilityId civilityId
     * @param {object=} data.civility civility
     * @param {number=} data.departmentId departmentId
     * @param {object=} data.department department
     * @param {number=} data.divisionId divisionId
     * @param {object=} data.division division
     * @param {object[]=} data.commercialInformations commercialInformations
     * @param {object[]=} data.eventCandidates eventCandidates
     * @param {number=} data.subDivisionId subDivisionId
     * @param {object=} data.subDivision subDivision
     * @param {number=} data.metier1Id metier1Id
     * @param {object=} data.metier1 metier1
     * @param {number=} data.metier2Id metier2Id
     * @param {object=} data.metier2 metier2
     * @param {number=} data.department1Id department1Id
     * @param {object=} data.department1 department1
     * @param {number=} data.department2Id department2Id
     * @param {object=} data.department2 department2
     * @param {number=} data.department3Id department3Id
     * @param {object=} data.department3 department3
     * @param {number=} data.department4Id department4Id
     * @param {object=} data.department4 department4
     * @param {number=} data.department5Id department5Id
     * @param {object=} data.department5 department5
     * @param {number=} data.candidateStatusId candidateStatusId
     * @param {object=} data.candidateStatus candidateStatus
     * @param {string=} data.latitude latitude
     * @param {string=} data.longitude longitude
     * @param {string=} data.source source
     * @param {object=} data.fileCv fileCv
     * @param {object[]=} data.candidacies candidacies
     * @param {object[]=} data.hunts hunts
     * @param {string=} data.remuneration remuneration
     * @param {string=} data.company company
     * @param {string=} data.currentPosition currentPosition
     * @param {string=} data.linkedinProfileUrl linkedinProfileUrl
     */
    constructor({
        candidateId = 0,
        lastname = '',
        firstname = '',
        zipCode = '',
        cityId,
        city = {},
        birthdate,
        landlinePhone = '',
        mobilePhone = '',
        email = '',
        activity = '',
        information = '',
        civilityId,
        civility,
        departmentId,
        department,
        divisionId,
        division,
        commercialInformations,
        eventCandidates,
        subDivisionId,
        subDivision,
        metier1Id,
        metier1,
        metier2Id,
        metier2,
        department1Id,
        department1,
        department2Id,
        department2,
        department3Id,
        department3,
        department4Id,
        department4,
        department5Id,
        department5,
        candidateStatusId,
        candidateStatus = { name: 'Disponible' },
        latitude,
        longitude,
        source,
        fileCv,
        candidacies = [],
        hunts = [],
        remuneration = '',
        company = '',
        currentPosition = '',
        linkedinProfileUrl = '',
        ...data
    } = {}) {
        super(data)
        this.candidateId = candidateId
        this.lastname = lastname
        this.firstname = firstname
        this.zipCode = zipCode
        this.cityId = cityId
        this.city = city
        this.birthdate = birthdate ? new Date(birthdate) : undefined
        /** Used only in front */
        this.age = this.birthdate ? `${Time(this.birthdate).getAge()} ans` : undefined
        this.landlinePhone = landlinePhone
        this.mobilePhone = mobilePhone
        this.email = email
        this.activity = activity
        this.information = information
        this.civilityId = civilityId
        this.civility = civility
        this.departmentId = departmentId
        this.department = department
        this.divisionId = divisionId
        this.division = division
        this.commercialInformations = commercialInformations
        this.eventCandidates = eventCandidates
        this.subDivisionId = subDivisionId
        this.subDivision = subDivision
        this.metier1Id = metier1Id
        this.metier1 = metier1
        this.metier2Id = metier2Id
        this.metier2 = metier2
        this.department1Id = department1Id
        this.department1 = department1
        this.department2Id = department2Id
        this.department2 = department2
        this.department3Id = department3Id
        this.department3 = department3
        this.department4Id = department4Id
        this.department4 = department4
        this.department5Id = department5Id
        this.department5 = department5
        this.candidateStatusId = candidateStatusId
        this.candidateStatus = candidateStatus
        this.latitude = !Number.isNaN(Number.parseFloat(latitude)) ? Number.parseFloat(latitude) : 0
        this.longitude = !Number.isNaN(Number.parseFloat(longitude)) ? Number.parseFloat(longitude) : 0
        this.source = source
        this.fileCv = fileCv ? new FileCv(fileCv) : undefined
        this.candidacies = candidacies?.map(x => new Candidacy(x)) ?? []
        this.hunts = hunts?.map(x => new Hunt(x)) ?? []
        this.remuneration = remuneration
        this.company = company
        this.currentPosition = currentPosition
        this.linkedinProfileUrl = linkedinProfileUrl
    }
}

/**
 * Candidate Object used to bind error message
 */
export class ErrorCandidate extends ErrorBase {
    constructor() {
        super()
        this.candidateId = ''
        this.lastname = ''
        this.firstname = ''
        this.zipCode = ''
        this.cityId = ''
        this.city = ''
        this.birthdate = ''
        this.landlinePhone = ''
        this.mobilePhone = ''
        this.email = ''
        this.activity = ''
        this.information = ''
        this.civilityId = ''
        this.civility = ''
        this.departmentId = ''
        this.department = ''
        this.divisionId = ''
        this.division = ''
        this.commercialInformations = ''
        this.eventCandidates = ''
        this.subDivisionId = ''
        this.subDivision = ''
        this.metier1Id = ''
        this.metier1 = ''
        this.metier2Id = ''
        this.metier2 = ''
        this.department1Id = ''
        this.department1 = ''
        this.department2Id = ''
        this.department2 = ''
        this.department3Id = ''
        this.department3 = ''
        this.department4Id = ''
        this.department4 = ''
        this.department5Id = ''
        this.department5 = ''
        this.candidateStatusId = ''
        this.candidateStatus = ''
        this.remuneration = ''
        this.company = ''
        this.currentPosition = ''
        this.linkedinProfileUrl = ''
    }
}
