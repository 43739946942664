import React from 'react'

/**
 * Reset carret position
 * @param {React.FormEvent<HTMLInputElement | HTMLTextAreaElement>} ev Ev
 */
export default function resetCarret(ev) {
    const element = /** @type {HTMLInputElement} */(ev.target)
    const start = element.selectionStart
    const end = element.selectionEnd
    element.setSelectionRange(start, end)
    setTimeout(() => {
        element.setSelectionRange(start, end)
    }, 0)
}
