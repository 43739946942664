/**
 * @enum {string}
 */
const EDiffusionAction = {
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
    REPUBLISH: 'republish',
}

export default EDiffusionAction
