import React, { PureComponent } from 'react'
import {
    MessageBarType, TextField, ICommandBarItemProps, ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, ConstrainMode, Text,
} from '@fluentui/react'
import history from 'helpers/history'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import ParentCompany, { ErrorParentCompany } from 'requests/objects/parentCompany'
import { Link } from 'react-router-dom'
import EProfile from 'types/profile'
import setPageTitle from 'helpers/methods/pageTitle'
import resetCarret from 'helpers/methods/resetCarret'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdParentCompanies extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.parentCompanyId,
            /** @type {ParentCompany} Element find from API */
            item: new ParentCompany(),
            /** @type {ParentCompany} Initial element fond from API. Used to refresh */
            iniItem: new ParentCompany(),
            /** @type {ErrorParentCompany} Errors */
            errorField: new ErrorParentCompany(),
            /** @type {import('@fluentui/react').IColumn[]} */
            clientColumns: [
                {
                    key: 'name',
                    name: 'Nom',
                    fieldName: 'name',
                    minWidth: 200,
                    maxWidth: 200,
                    isRowHeader: true,
                    isResizable: true,
                },
            ],
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { match, setCommand } = this.props

        if (match?.params?.parentCompanyId !== prevProps?.match?.params?.parentCompanyId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.parentCompaniesHandlerRemoveById?.cancel()
        this.parentCompaniesHandlerGetById?.cancel()
        this.parentCompaniesHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state
        const client = item.clients.find(cli => Number.parseInt(match?.params?.clientId, 10) === cli.clientId)

        const mainTitle = `Société mère ${match?.params?.parentCompanyId ? item.name || '' : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie client', key: 'client' },
            { text: 'Tous les clients', key: 'all-client', href: '/clients' },
            {
                text: `Client ${match?.params?.clientId ? client?.name || '' : 'Nouveau'}`,
                key: 'one-client',
                href: `/clients/${match?.params?.clientId}`,
            },
            {
                text: mainTitle,
                key: 'one-parent-company',
                isCurrentItem: true,
            },
        ])
        setTitle('Clients')
        setPageTitle(mainTitle)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, parentCompaniesHandler, setMessageBar, removeParam, me,
        } = this.props

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: ![EProfile.Administrator].includes(me.profileId),
            },
        ]

        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorParentCompany(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.parentCompanyId,
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
            {
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: 'Supprimer la société mère',
                        subTitle: 'Êtes-vous certain de vouloir supprimer la société mère ? Cette action est définitive.',
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.parentCompaniesHandlerRemoveById = parentCompaniesHandler.removeById(match?.params?.parentCompanyId)
                                    await this.parentCompaniesHandlerRemoveById.fetch()
                                    history.push(`/clients/${match?.params?.clientId}`)
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'élément a bien été supprimé",
                                    })
                                    removeParam({
                                        key: 'parentCompanies',
                                        value: { key: Number.parseInt(match?.params?.parentCompanyId, 10) },
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.parentCompanyId,
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, parentCompaniesHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.parentCompanyId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.parentCompany) // If object came from history push with a create
                    this.setState({
                        item: location.state?.parentCompany,
                        iniItem: location.state?.parentCompany,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                        this.setupBreadcrumb()
                    })
                else
                    try {
                        this.parentCompaniesHandlerGetById = parentCompaniesHandler.getById(match?.params?.parentCompanyId)
                        const parentCompany = await this.parentCompaniesHandlerGetById.fetch()
                        this.setState({
                            item: parentCompany,
                            iniItem: parentCompany,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                            this.setupBreadcrumb()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new ParentCompany(),
                    iniItem: new ParentCompany(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, parentCompaniesHandler, match, setMessageBar, addParam, editParam,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.parentCompaniesHandlerUpsert = parentCompaniesHandler.upsert(item, match?.params?.parentCompanyId)
                const parentCompany = await this.parentCompaniesHandlerUpsert.fetch()

                this.setState({
                    item: parentCompany,
                    iniItem: parentCompany,
                    status: Status.RESOLVED,
                    errorField: new ErrorParentCompany(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.parentCompanyId) {
                    addParam({
                        key: 'parentCompanies',
                        value: { key: parentCompany.parentCompanyId, text: parentCompany.name },
                    })
                    history.replace(`/clients/${match.params?.clientId}/societe-meres/${parentCompany.parentCompanyId}`)
                } else {
                    editParam({
                        key: 'parentCompanies',
                        value: { key: parentCompany.parentCompanyId, text: parentCompany.name },
                    })
                }
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorParentCompany>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, clientColumns,
        } = this.state

        if (status === Status.PENDING)
            return <Loader />

        return (
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    this.upsert()
                }}
            >
                <Card
                    title="Champs"
                    iconName="PageData"
                >
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Nom"
                                value={item.name}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                onChange={(ev, newVal) => {
                                    this.setState({ item: { ...item, name: newVal?.toUpperCase() } })
                                    resetCarret(ev)
                                }}
                                errorMessage={errorField.name}
                                required
                            />
                        </Columns.Column>
                    </Columns>
                </Card>

                <br />
                <Card
                    title="Clients"
                    iconName="People"
                >
                    <ShimmeredDetailsList
                        items={item.clients}
                        columns={clientColumns}
                        enableShimmer={status === Status.PENDING}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => (
                            <Link
                                to={{
                                    pathname: `/clients/${props.item?.clientId}`,
                                }}
                            >
                                {defaultRender(props)}
                            </Link>
                        )}
                    />
                    {!item.clients?.length && status !== Status.IDLE
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun client n'a été trouvée</Text>}
                </Card>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={this.submitInput}
                    tabIndex={-1}
                />
            </form>
        )
    }
}

IdParentCompanies.prototype.getUpdatedList = getUpdatedList
IdParentCompanies.prototype.isValidDate = isValidDate
