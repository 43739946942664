/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * Contact Object
 */
export default class Contact extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.contactId contactId of the Contact
     * @param {number=} data.clientId clientId
     * @param {object=} data.client client
     * @param {number=} data.divisionId divisionId
     * @param {object=} data.division division
     * @param {string=} data.lastname lastname
     * @param {string=} data.firstname firstname
     * @param {string=} data.address address
     * @param {string=} data.additionalAddress additionalAddress
     * @param {string=} data.zipCode zipCode
     * @param {number=} data.cityId city
     * @param {object=} data.city city
     * @param {string=} data.email email
     * @param {string=} data.information information
     * @param {string=} data.landlinePhone landlinePhone
     * @param {string=} data.mobilePhone mobilePhone
     * @param {number=} data.subDivisionId subDivisionId
     * @param {object=} data.subDivision subDivision
     * @param {number=} data.metierId metierId
     * @param {object=} data.metier metier
     * @param {boolean=} data.hasLeft hasLeft
     * @param {object[]=} data.eventClients eventClients
     */
    constructor({
        contactId = 0,
        clientId,
        client,
        divisionId,
        division,
        lastname = '',
        firstname = '',
        address = '',
        additionalAddress = '',
        zipCode = '',
        cityId,
        city = {},
        email = '',
        information = '',
        landlinePhone = '',
        mobilePhone = '',
        subDivisionId,
        subDivision,
        metierId,
        metier,
        hasLeft = false,
        eventClients,
        ...data
    } = {}) {
        super(data)
        this.contactId = contactId
        this.clientId = clientId
        this.client = client
        this.divisionId = divisionId
        this.division = division
        this.lastname = lastname
        this.firstname = firstname
        this.address = address
        this.additionalAddress = additionalAddress
        this.zipCode = zipCode
        this.cityId = cityId
        this.city = city
        this.email = email
        this.information = information
        this.landlinePhone = landlinePhone
        this.mobilePhone = mobilePhone
        this.subDivisionId = subDivisionId
        this.subDivision = subDivision
        this.metierId = metierId
        this.metier = metier
        this.hasLeft = hasLeft
        this.eventClients = eventClients
    }
}

/**
 * Contact Object used to bind error message
 */
export class ErrorContact extends ErrorBase {
    constructor() {
        super()
        this.contactId = ''
        this.clientId = ''
        this.client = ''
        this.divisionId = ''
        this.division = ''
        this.lastname = ''
        this.firstname = ''
        this.address = ''
        this.additionalAddress = ''
        this.zipCode = ''
        this.cityId = ''
        this.city = ''
        this.email = ''
        this.information = ''
        this.landlinePhone = ''
        this.mobilePhone = ''
        this.subDivisionId = ''
        this.subDivision = ''
        this.metierId = ''
        this.metier = ''
        this.hasLeft = ''
        this.eventClients = ''
    }
}
