import React, { PureComponent } from 'react'
import {
    TextField, PrimaryButton, SpinnerSize, Spinner, Text,
    MessageBarType,
} from '@fluentui/react'
import history from 'helpers/history'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import { ErrorUser } from 'requests/objects/user'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'

/** @debug {AppProps} */

/**
 * @augments {React.PureComponent<AppProps>}
 */
export default class PasswordForgot extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {string} email */
            email: '',
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {ErrorUser} */
            errorField: new ErrorUser(),
        }
    }

    componentDidMount() {
        const {
            setBreadcrumb, setCommand, setTitle, isAuthenticated, setMessageBar,
        } = this.props

        setBreadcrumb([])
        setCommand([])
        setTitle('')
        setMessageBar({ isDisplayed: false })

        if (isAuthenticated)
            history.push('/')
    }

    componentWillUnmount() {
        this.usersHandlerLogin?.cancel()
    }

    handleSubmit(ev) {
        ev.preventDefault()
        const { usersHandler, setMessageBar } = this.props
        const { email } = this.state

        this.setState({ status: Status.PENDING }, async () => {
            try {
                this.usersHandlerLogin = usersHandler.forgotPassword({ email })
                await this.usersHandlerLogin.fetch()
                history.push('/login')
                setMessageBar({ // We must set the messagebar after change page, to force re add it
                    isDisplayed: true,
                    type: MessageBarType.success,
                    message: 'Votre demande a bien été prise en compte.',
                })
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorUser>} */(error).errorField })
                            break
                        default:
                            this.setState({ errorField: new ErrorUser() })
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    render() {
        const {
            email, status, errorField,
        } = this.state

        return (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <TextField
                    label="Email"
                    value={email}
                    onChange={ev => this.setState({ email: /** @type {HTMLInputElement} */(ev.target).value })}
                    // iconProps={{ iconName: 'Mail' }}
                    readOnly={status === Status.PENDING}
                    autoComplete="username"
                    errorMessage={errorField.email}
                    required
                    type="email"
                />
                <Text
                    variant="small"
                    block
                >
                    Veuillez saisir votre email afin que nous puissions vous envoyer un lien de réinitialisation de votre mot de passe
                </Text>
                <br />
                <div className="flex-row">
                    <PrimaryButton
                        iconProps={{ iconName: 'FollowUser' }}
                        text="Valider"
                        type="submit"
                        disabled={status === Status.PENDING}
                    >
                        {status === Status.PENDING && (
                            <Spinner size={SpinnerSize.small} />
                        )}
                    </PrimaryButton>
                </div>
            </form>
        )
    }
}
