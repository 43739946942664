/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Profile from 'requests/objects/profile'

/**
 * User Object
 */
export default class User extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.userId userId
     * @param {string=} data.email email
     * @param {string=} data.firstname firstname
     * @param {string=} data.lastname lastname
     * @param {string=} data.password password
     * @param {string=} data.accessToken accessToken
     * @param {string=} data.refreshToken refreshToken
     * @param {number=} data.profileId profileId
     * @param {object=} data.profile profile
     * @param {string=} data.code code
     * @param {string=} data.desk desk
     * @param {boolean=} data.hasLeft hasLeft
     * @param {number=} data.userManagerId userManagerId
     * @param {object=} data.userManager userManager
     * @param {boolean=} data.hasOpManAccessBoardReception hasOpManAccessBoardReception
     */
    constructor({
        userId,
        email = '',
        firstname = '',
        lastname = '',
        password = '',
        accessToken,
        refreshToken,
        profileId,
        profile,
        code = '',
        desk = '',
        hasLeft = false,
        userManagerId,
        userManager,
        hasOpManAccessBoardReception = false,
        ...data
    } = {}) {
        super(data)
        this.userId = userId
        this.email = email
        this.firstname = firstname
        this.lastname = lastname
        this.password = password
        this.profileId = profileId
        this.profile = profile ? new Profile(profile) : undefined
        this.code = code
        this.desk = desk
        this.hasLeft = !!hasLeft
        this.userManagerId = userManagerId
        this.userManager = userManager ? new User(userManager) : undefined
        this.hasOpManAccessBoardReception = hasOpManAccessBoardReception

        this.accessToken = accessToken
        this.refreshToken = refreshToken
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorUser extends ErrorBase {
    constructor() {
        super()
        this.userId = ''
        this.email = ''
        this.firstname = ''
        this.lastname = ''
        this.password = ''
        this.confirmPassword = ''
        this.profileId = ''
        this.profile = ''
        this.code = ''
        this.desk = ''
        this.hasLeft = ''
        this.userManagerId = ''
        this.userManager = ''
        this.hasOpManAccessBoardReception = ''
    }
}
