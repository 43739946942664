import React, { PureComponent } from 'react'
import { Text } from '@fluentui/react'
import Card from 'components/containers/card'
// eslint-disable-next-line import/named
import { AppProps } from 'app'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}}
 */
export default class NotFound extends PureComponent {
    componentDidMount() {
        const { setBreadcrumb, setCommand, setTitle } = this.props
        setBreadcrumb([])
        setCommand([])
        setTitle('Erreur')
    }

    render() {
        return (
            <Card
                title="Erreur"
                iconName="Error"
            >
                <Text
                    as="p"
                    block
                >
                    La page n'a pas été trouvée
                </Text>
            </Card>
        )
    }
}
