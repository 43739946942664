/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import User from 'requests/objects/user'

/**
 * missionUserCollaboratorHistoric Object
 */
export default class MissionUserCollaboratorHistoric extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.missionUserCollaboratorHistoricId missionUserCollaboratorHistoricId
     * @param {number=} data.missionId missionId
     * @param {number=} data.userCollaboratorId userCollaboratorId
     * @param {object=} data.userCollaborator userCollaborator
     * @param {string=} data.startDate startDate
     * @param {string=} data.endDate endDate
     */
    constructor({
        missionUserCollaboratorHistoricId = 0,
        userCollaboratorId,
        userCollaborator,
        missionId,
        startDate = '',
        endDate = '',
        ...data
    } = {}) {
        super(data)
        this.missionUserCollaboratorHistoricId = missionUserCollaboratorHistoricId
        this.userCollaboratorId = userCollaboratorId
        this.userCollaborator = userCollaborator ? new User(userCollaborator) : undefined
        this.missionId = missionId
        this.startDate = startDate ? new Date(startDate) : undefined
        this.endDate = endDate ? new Date(endDate) : undefined
    }
}

/**
 * Mission Object used to bind error message
 */
export class ErrorMissionUserCollaboratorHistoric extends ErrorBase {
    constructor() {
        super()
        this.missionUserCollaboratorHistoricId = ''
        this.userCollaboratorId = ''
        this.userCollaborator = ''
        this.missionId = ''
        this.startDate = ''
        this.endDate = ''
    }
}
