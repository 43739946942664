import {
    Slice,
    PayloadAction,
    createSlice,
} from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Middleware } from 'redux'
import { IBreadcrumbItem, ICommandBarItemProps, MessageBarType } from '@fluentui/react'
import { ReactElement } from 'react'

/**
 * Common middlewares, must have "getDefaultMiddleware"
 * @type {Middleware[]}
 */
const commonMiddleware = []

/**
 * Payload Init
 * @typedef {object} PayloadMessageBar
 * @property {boolean} isDisplayed Is message bar display ?
 * @property {MessageBarType=} type MessageBarType
 * @property {(string|object)=} message Message to show
 *
 * Payload Url
 * @typedef {string} PayloadUrl Current Url
 *
 * Payload Breadcrumb
 * @typedef {IBreadcrumbItem[]=} PayloadBreadcrumb Breadcrumb items
 *
 * Payload Title
 * @typedef {string=} PayloadTitle Title
 *
 * Payload Command
 * @typedef {ICommandBarItemProps[]=} PayloadCommand Commanbar items
 *
 * Payload Modal
 * @typedef {object} PayloadModal
 * @property {boolean} show Is modal displayed
 * @property {string=} title Title
 * @property {string=} subTitle Subtitle
 * @property {Function=} callback Callback when clicking "Yes" button
 * @property {ReactElement=} content JSX content
 */

/**
 * Common State
 * @typedef {object} CommonState
 * @property {string} selectedKeyURL Current URL in application
 * @property {object} messageBar Messagebar
 * @property {boolean} messageBar.isDisplayed Is message bar displayed
 * @property {MessageBarType=} messageBar.type Messagebar type
 * @property {string=} messageBar.message Text
 * @property {IBreadcrumbItem[]} breadcrumb Breadcrumb items
 * @property {ICommandBarItemProps[] | object[]} command Command items
 * @property {object} modal Modal
 * @property {boolean} modal.show Is modal displayed
 * @property {string=} modal.title Title
 * @property {string=} modal.subTitle Subtitle
 * @property {Function=} modal.callback Callback when clicking "Yes" button
 * @property {ReactElement=} modal.content JSX content
 * @property {string=} title Title
 */
/**
 * Common Slice
 * @type {Slice<CommonState>}
 */
const commonSlice = createSlice({
    name: 'common',
    /** @type {CommonState} */
    initialState: {
        selectedKeyURL: '/',
        messageBar: {
            isDisplayed: false,
            type: null,
            message: null,
        },
        breadcrumb: [],
        command: [],
        modal: {
            show: false,
            title: '',
            subTitle: '',
            callback: () => null,
            content: null,
        },
        title: '',
    },
    reducers: {
        /**
         * Set MessageBar
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadMessageBar>} action action
         */
        setMessageBar: (state, action) => {
            switch (action.payload.type) {
                case MessageBarType.error:
                    // eslint-disable-next-line
                    action.payload.message = action.payload.message ? (typeof action.payload.message !== 'object' ? action.payload.message : JSON.stringify(action.payload.message)) : 'Une erreur est survenue'
                    break
                case MessageBarType.blocked:
                    // eslint-disable-next-line
                    action.payload.message = action.payload.message ? (typeof action.payload.message !== 'object' ? action.payload.message : JSON.stringify(action.payload.message)) : "Vous n'êtes pas autorisé à faire ceci"
                    break
                case MessageBarType.success:
                    // eslint-disable-next-line
                    action.payload.message = action.payload.message ? (typeof action.payload.message !== 'object' ? action.payload.message : JSON.stringify(action.payload.message)) : "L'opération s'est correctement déroulée."
                    break
                default:
                    break
            }
            // eslint-disable-next-line no-param-reassign
            state.messageBar = action.payload
        },
        /**
         * Set Current Url
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadUrl>} action action
         */
        setUrl: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.selectedKeyURL = action.payload
        },
        /**
         * Set Breadcrumb
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadTitle>} action action
         */
        setTitle: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.title = action.payload
        },
        /**
         * Set Breadcrumb
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadBreadcrumb>} action action
         */
        setBreadcrumb: (state, action) => {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            state.breadcrumb = action.payload
        },
        /**
         * Set CommandBar
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadCommand>} action action
         */
        setCommand: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.command = action.payload
        },
        /**
         * Set Modal
         * @param {CommonState} state state
         * @param {PayloadAction<PayloadModal>} action action
         */
        setModal: (state, action) => {
            // eslint-disable-next-line no-param-reassign
            state.modal = action.payload
        },
    },
})

const {
    setMessageBar, setUrl, setBreadcrumb, setCommand, setModal, setTitle,
} = commonSlice.actions
const commonReducer = commonSlice.reducer

export {
    setMessageBar, setUrl, setBreadcrumb, setCommand, setModal, setTitle, // Reducers, used to call actions
    commonReducer, // All reducers, used to create store
    commonMiddleware, // Middleware
}
