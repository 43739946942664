/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import User from 'requests/objects/user'
import Hunt from 'requests/objects/hunt'
import Candidacy from 'requests/objects/candidacy'

/**
 * BoardComment Object
 */
export default class BoardComment extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.boardCommentId boardCommentId of the Comment
     * @param {string=} data.content content
     * @param {string=} data.date date
     * @param {number=} data.createdByUserId createdByUserId
     * @param {object=} data.createdByUser createdByUser
     * @param {number=} data.candidacyId candidacyId
     * @param {object=} data.candidacy candidacy
     * @param {number=} data.huntId huntId
     * @param {object=} data.hunt hunt
     * @param {number=} data.isPinned isPinned
     * @param {number=} data.status isPinned
     */
    constructor({
        boardCommentId = 0,
        content = '',
        date,
        createdByUserId,
        createdByUser,
        candidacyId,
        candidacy = {},
        huntId,
        hunt = {},
        isPinned,
        status,
        ...data
    } = {}) {
        super(data)
        this.boardCommentId = boardCommentId
        this.content = content
        this.date = date ? new Date(date) : undefined
        this.createdByUserId = createdByUserId
        this.createdByUser = createdByUser ? new User(createdByUser) : createdByUser
        this.candidacyId = candidacyId
        this.candidacy = candidacy ? new Candidacy(candidacy) : undefined
        this.huntId = huntId
        this.hunt = hunt ? new Hunt(hunt) : undefined
        this.isPinned = isPinned
        this.status = status
    }
}

/**
 * BoardComment Object used to bind error message
 */
export class ErrorBoardComment extends ErrorBase {
    constructor() {
        super()
        this.boardCommentId = ''
        this.content = ''
        this.date = ''
        this.createdByUserId = ''
        this.createdByUser = ''
        this.candidacyId = ''
        this.isPinned = ''
        this.status = ''
    }
}
