import Types from 'types/types'
import { Column } from 'types/column' // eslint-disable-line

import React from 'react'
import { Link } from 'react-router-dom'

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const missionColumns = [
    {
        fieldName: 'createdAt',
        name: 'Date de création',
        type: Types.DATETIME,
    },
    {
        fieldName: 'ref',
        name: 'Référence',
        type: Types.STRING,
    },
    {
        fieldName: 'client.name',
        name: 'Entreprise',
        type: Types.STRING,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (item ? (
            <Link
                to={{
                    pathname: `/clients/${item['client.clientId']}`,
                }}
            >
                {item['client.name']}
            </Link>
        ) : ''),
    },
    {
        fieldName: 'name',
        name: 'Intitulé de poste',
        type: Types.STRING,
    },
    {
        fieldName: 'userCollaborator.lastname',
        name: 'Consultant',
        type: Types.STRING,
        onRender: item => `${item['userCollaborator.lastname'] ?? ''} ${item['userCollaborator.firstname'] ?? ''}`.toUpperCase(),
    },
    {
        fieldName: 'userOperationsManager.lastname',
        name: 'Chargé de recrutement',
        type: Types.STRING,
        onRender: item => `${item['userOperationsManager.lastname'] ?? ''} ${item['userOperationsManager.firstname'] ?? ''}`.toUpperCase(),
    },
    {
        fieldName: 'city.name',
        name: 'Localisation',
        type: Types.STRING,
    },
    {
        fieldName: 'contact.lastname',
        name: 'Contact client',
        type: Types.STRING,
        onRender: item => `${item['contact.lastname'] ?? ''} ${item['contact.firstname'] ?? ''}`,
    },
    {
        fieldName: 'missionStatus.name',
        name: 'Statut',
        type: Types.STRING,
    },
]

export default missionColumns
