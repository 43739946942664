import React, { useMemo } from 'react'
import Card from 'components/containers/card'
import Mission from 'requests/objects/mission'
import Status from 'types/status'
import { Link } from 'react-router-dom'
import flattenObj from 'helpers/methods/flattenObj'
import SortableList from 'components/pages/clients/[id]/index/sortableList'

/**
 * Pivot detail
 * @param {object} props Props
 * @param {Mission} props.item item
 * @param {Status} props.status status
 * @param {import('@fluentui/react').IColumn[]} props.eventClientsColumns eventClientsColumns
 * @param {import('@fluentui/react').IColumn[]} props.eventCandidatesColumn eventCandidatesColumn
 * @returns { JSX.Element } Returns
 */
export default function PivotEvent({
    item, status, eventClientsColumns, eventCandidatesColumn,
}) {
    const flattenEventCandidates = useMemo(() => item.eventCandidates.map(x => flattenObj(x)), [item.eventCandidates])

    return (
        <>
            <Card
                title="Évènements Client"
                iconName="PageData"
            >
                <SortableList
                    items={item.eventClients}
                    columns={eventClientsColumns}
                    status={status}
                    noDataText="Aucun évènements n'a été trouvé"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderRow={(props, defaultRender) => (
                        <Link
                            // eslint-disable-next-line max-len
                            to={`/clients/${item?.clientId}/contacts/${props.item?.contactId}/evenements/${props.item?.eventClientId}`}
                        >
                            {defaultRender(props)}
                        </Link>
                    )}
                />
            </Card>
            <br />
            <Card
                title="Évènements Candidats"
                iconName="PageData"
            >
                <SortableList
                    items={flattenEventCandidates}
                    columns={eventCandidatesColumn}
                    status={status}
                    noDataText="Aucun évènements n'a été trouvé"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderRow={(props, defaultRender) => {
                        const rootStyle = {}
                        if (props.item.eventTypeId === 48) { // PLACEMEENT
                            rootStyle.background = '#fde7e9'
                            rootStyle['&:hover'] = {
                                backgroundColor: '#fbcfd3',
                                color: 'rgb(50, 49, 48)',
                            }
                        }
                        return defaultRender({ ...props, styles: { root: rootStyle } })
                    }}
                />

            </Card>
        </>
    )
}
