import React, {
    useCallback, useEffect, useRef,
} from 'react'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import HandleBlob from 'helpers/methods/blob'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FileInput from 'components/inputs/fileInput'
import CandidatesHandler from 'requests/handlers/candidatesHandler'
import FileCv from 'requests/objects/fileCv'
import Candidate from 'requests/objects/candidate'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Candidacy from 'requests/objects/candidacy'
import Hunt from 'requests/objects/hunt'
import HuntsHandler from 'requests/handlers/huntsHandler'

/**
 * Files list
 * @param {object} props Props
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {number} props.id id
 * @param {CandidatesHandler | CandidaciesHandler | HuntsHandler} props.handler CandidatesHandler
 * @param {(file: FileCv) => void} props.updateItem updateItem
 * @param {Candidate | Candidacy | Hunt} props.item Candidate
 * @param {string=} props.label label
 * @returns {JSX.Element} Returns
 */
export default function CvInput({
    isReadOnly = false,
    id = 0,
    handler,
    updateItem = () => null,
    item = new Candidate(),
    label = 'CV',
}) {
    /** @type {React.MutableRefObject<RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<FileCv>>} */
    const handlerUploadFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<FileCv>>} */
    const handlerRemoveFile = useRef(null)

    const download = useCallback(async () => {
        try {
            handlerGetFile.current = handler.getFileCv(id, item.fileCv?.fileCvId)
            const blob = await handlerGetFile.current.fetch()
            HandleBlob.download(blob, item?.fileCv?.name)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [item?.fileCv, id, handler])

    const upload = useCallback(
        /**
         * @param {globalThis.File} file file
         */
        async file => {
            try {
                handlerUploadFile.current = handler.uploadFileCv(id, file)
                const itemFile = await handlerUploadFile.current.fetch()
                updateItem(itemFile)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [id, handler, updateItem],
    )

    const remove = useCallback(async () => {
        try {
            handlerRemoveFile.current = handler.removeFileCv(id, item.fileCv?.fileCvId)
            await handlerRemoveFile.current.fetch()
            updateItem(new FileCv())
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [item.fileCv?.fileCvId, id, handler, updateItem])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerGetFile?.current?.cancel()
        handlerUploadFile?.current?.cancel()
        handlerRemoveFile?.current?.cancel()
    }, [])

    return (
        <FileInput
            label={label}
            labelUpload="Télécharger le cv"
            isReadOnly={isReadOnly}
            isDisabled={!id}
            fileName={item.fileCv?.name}
            tooltipContent=""
            onDownload={() => download()}
            // onOpen={() => download(true)}
            onUpload={file => upload(file)}
            onDelete={() => remove()}
        />
    )
}
