/**
 * @enum {number}
 */
const ECommercialInformationProcessOption = {
    ClientRdvtDone: 1,
    ClientRdvCanceled: 2,
    ProcessedWithoutRdv: 3,
}

export default ECommercialInformationProcessOption
