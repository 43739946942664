import React, { ReactNode, useCallback, useRef } from 'react'
import styles from 'styles/components/pages/missions/pivotCandidacy/boardColumns.module.scss'
import Divider from 'components/visuals/divider'
import { Icon, Text } from '@fluentui/react'
import EBoardCardType from 'types/missions/enums/boardCardType'
import EBoardColumn from 'types/missions/enums/boardColumn'
import MissionsHandler from 'requests/handlers/missionsHandler'
import BoardExportButton from 'components/pages/missions/[id]/pivotCandidacy/boardExportButton'

/**
 * Pivot Candidacy
 * @param {object} props Props
 * @param {ReactNode=} props.children Children
 * @returns {JSX.Element} Returns
 */
export default function BoardColumns({
    children,
}) {
    return (
        <div className={styles['board-columns']}>
            {children}
        </div>
    )
}

/**
 * Pivot Candidacy
 * @param {object} props Props
 * @param {ReactNode=} props.children Children
 * @param {string} props.iconName iconName
 * @param {EBoardColumn} props.columnId columnId
 * @param {string} props.title title
 * @param {number} props.total total
 * @param {(id: number, newColumnId: EBoardColumn, type: EBoardCardType) => void} props.onBoardItemDrop onBoardItemDrop
 * @param {EBoardColumn} props.dragingColumnId dragingColumnId
 * @param {MissionsHandler} props.missionsHandler missionsHandler
 * @param {number} props.missionId missionId
 * @returns {JSX.Element} Returns
 */
export function BoardColumn({
    children, iconName = '', columnId = EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT, title = '', total = 0, onBoardItemDrop = () => null, dragingColumnId,
    missionsHandler,
    missionId,
}) {
    /** @type {React.MutableRefObject<HTMLElement>} */
    const columnRef = useRef()

    const onDragOver = useCallback(
        /**
         * @param {React.DragEvent<HTMLElement>} ev ev
         */
        ev => {
            ev.preventDefault()
        }, [],
    )

    const onDrop = useCallback(
        /**
         * @param {React.DragEvent<HTMLElement>} ev ev
         */
        ev => {
            ev.preventDefault()
            const id = +ev.dataTransfer.getData('id')
            const type = +ev.dataTransfer.getData('type')
            onBoardItemDrop(id, columnId, type)
        }, [onBoardItemDrop, columnId],
    )

    return (
        <section
            className={styles['board-columns-column']}
            ref={columnRef}
            onDragOver={!dragingColumnId || Math.abs(dragingColumnId - columnId) > 1 ? undefined : onDragOver}
            onDrop={onDrop}
        >
            <header>
                <div className={styles['board-columns-column-header-top']}>
                    <Text
                        className={styles['board-columns-column-header-top-number']}
                        variant="smallPlus"
                    >
                        Étape
                        {' '}
                        {columnId}
                    </Text>
                    <Text
                        className={styles['board-columns-column-header-top-title']}
                        variant="smallPlus"
                    >
                        Nombre candidatures
                        {' '}
                        <span>{total}</span>
                    </Text>
                </div>
                <div className={styles['board-columns-column-header-bottom']}>
                    <Icon
                        className={styles['board-columns-column-header-bottom-icon']}
                        iconName={iconName}
                    />
                    <Text
                        className={styles['board-columns-column-header-bottom-title']}
                        variant="mediumPlus"
                    >
                        {title}
                    </Text>
                    {[EBoardColumn.TO_CALL, EBoardColumn.CALLED].includes(columnId) && (
                        <BoardExportButton
                            missionId={missionId}
                            missionsHandler={missionsHandler}
                            columnId={columnId}
                            disabled={total === 0}
                        />
                    )}
                </div>
                <Divider />
            </header>
            <div className={styles['board-columns-column-content']}>
                {children}
            </div>
        </section>
    )
}
