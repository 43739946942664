import {
    DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import ResearchClientsHandler from 'requests/handlers/researchClientsHandler'
import ResearchClient, { ErrorResearchClient } from 'requests/objects/researchClient'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import resetCarret from 'helpers/methods/resetCarret'

/**
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {ResearchClientsHandler} props.handler handler
 * @param {object} props.data data
 * @returns {JSX.Element} Element
 */
export default function SaveSearchModal({
    isVisible, setIsVisible, handler, data,
}) {
    const [name, setName] = useState('')
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorResearchClient())

    /** @type {React.MutableRefObject<RequestApi<ResearchClient>>} */
    const handlerCreate = useRef()

    const save = useCallback(async () => {
        try {
            handlerCreate.current = handler.create(new ResearchClient({ name, request: JSON.stringify(data) }))
            await handlerCreate.current.fetch()
            setIsVisible(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorResearchClient>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [setIsVisible, handler, data, name])

    // On component did unmount
    useEffect(() => () => {
        handlerCreate.current?.cancel()
    }, [])

    useEffect(() => {
        if (!isVisible) {
            setName('')
            setStatus(Status.IDLE)
            setErrorField(new ErrorResearchClient())
            handlerCreate.current?.cancel()
        }
    }, [isVisible])

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Sauvegarder la recherche',
                subText: 'Voulez-vous sauvegarder la recherche?',
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
            minWidth="33%"
        >
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    save()
                }}
            >
                <TextField
                    label="Nom"
                    disabled={status === Status.PENDING}
                    value={name}
                    onChange={(ev, newVal) => {
                        setName(newVal?.toUpperCase())
                        resetCarret(ev)
                    }}
                    errorMessage={errorField.name}
                />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text="Annuler"
                    />
                    <PrimaryButton
                        text="Sauvegarder"
                        type="submit"
                        disabled={!name}
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
