import React, { useEffect, useState } from 'react'
import styles from 'styles/components/pages/missions/pivotCandidacy/boardNewCard.module.scss'
import { Icon, Text } from '@fluentui/react'
import classNames from 'classnames'
import Param from 'requests/objects/param'
import HuntsHandler from 'requests/handlers/huntsHandler'
import BoardUpsertIBoardtemPanel from 'components/pages/missions/[id]/pivotCandidacy/boardUpsertIBoardtemPanel'
import Hunt from 'requests/objects/hunt'
import BoardImportCodingModal from 'components/pages/missions/[id]/pivotCandidacy/boardImportCodingModal'
import Candidacy from 'requests/objects/candidacy'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'

/**
 * Candidacy card
 * @param {object} props Props
 * @param {CandidaciesHandler} props.candidaciesHandler candidaciesHandler
 * @param {HuntsHandler} props.huntsHandler huntHandler
 * @param {(item: (Hunt | Candidacy)) => void} props.onCreate onCreate
 * @param {(item: (Hunt | Candidacy)[]) => void} props.onCreateMany onCreateMany
 * @param {Param} props.param param
 * @param {number} props.missionId missionId
 * @returns {JSX.Element} Returns
 */
export default function BoardToCallCards({
    candidaciesHandler, huntsHandler, onCreate, onCreateMany, param, missionId,
}) {
    const [isBoardUpsertIBoardtemPanelVisible, setIsBoardUpsertIBoardtemPanelVisible] = useState(false)
    const [isImportCvPanelVisible, setIsImportCvPanelVisible] = useState(false)
    const [isImportCodingModalVisible, setIsImportCodingModalVisible] = useState(false)

    useEffect(() => {
        if (isBoardUpsertIBoardtemPanelVisible)
            document.querySelector('html').classList.add('is-scroll-disable')
        else
            document.querySelector('html').classList.remove('is-scroll-disable')
    }, [isBoardUpsertIBoardtemPanelVisible])

    return (
        <>
            <button
                className={classNames(styles['board-to-call-card'])}
                type="button"
                onClick={() => setIsBoardUpsertIBoardtemPanelVisible(true)}
            >
                <Icon iconName="Add" />
                <Text>Ajouter un AD</Text>
            </button>
            <button
                className={classNames(styles['board-to-call-card'])}
                type="button"
                onClick={() => setIsImportCvPanelVisible(true)}
            >
                <Icon iconName="PageAdd" />
                <Text>Importer CV</Text>
            </button>
            <button
                className={classNames(styles['board-to-call-card'])}
                type="button"
                onClick={() => setIsImportCodingModalVisible(true)}
            >
                <Icon iconName="AddFriend" />
                <Text>Importer de la base de données</Text>
            </button>

            <BoardUpsertIBoardtemPanel
                item={new Hunt({ missionId })}
                isVisible={isBoardUpsertIBoardtemPanelVisible}
                setIsVisible={setIsBoardUpsertIBoardtemPanelVisible}
                param={param}
                handler={huntsHandler}
                onCreate={onCreate}
                missionId={missionId}
                onUpdate={() => null}
            />

            <BoardUpsertIBoardtemPanel
                item={new Candidacy({ missionId })}
                isVisible={isImportCvPanelVisible}
                setIsVisible={setIsImportCvPanelVisible}
                param={param}
                handler={candidaciesHandler}
                onCreate={onCreate}
                missionId={missionId}
                onUpdate={() => null}
            />

            <BoardImportCodingModal
                isVisible={isImportCodingModalVisible}
                setIsVisible={setIsImportCodingModalVisible}
                handler={candidaciesHandler}
                missionId={missionId}
                onCreateMany={onCreateMany}
            />
        </>
    )
}
