/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Client from 'requests/objects/client'
import Contact from 'requests/objects/contact'
import User from 'requests/objects/user'
import Candidacy from 'requests/objects/candidacy'
import Hunt from 'requests/objects/hunt'
import DiffusionJobBoard from 'requests/objects/diffusionJobBoard'
import DiffusionAction from 'requests/objects/diffusionAction'
import MissionUserOperationsManagerHistoric from 'requests/objects/missionUserOperationsManagerHistoric'
import MissionUserCollaboratorHistoric from 'requests/objects/missionUserCollaboratorHistoric'

/**
 * Mission Object
 */
export default class Mission extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.missionId missionId of the Mission
     * @param {number=} data.userCollaboratorId userCollaboratorId
     * @param {object=} data.userCollaborator userCollaborator
     * @param {number=} data.userOperationsManagerId userOperationsManagerId
     * @param {object=} data.userOperationsManager userOperationsManager
     * @param {string=} data.ref ref
     * @param {string=} data.name name
     * @param {string=} data.remuneration remuneration
     * @param {number=} data.missionFamilyId missionFamilyId
     * @param {object=} data.missionFamily missionFamily
     * @param {number=} data.clientId clientId
     * @param {object=} data.client client
     * @param {number=} data.contactId contactId
     * @param {object=} data.contact contact
     * @param {number=} data.missionStatusId missionStatusId
     * @param {object=} data.missionStatus missionStatus
     * @param {number=} data.depositAmount depositAmount
     * @param {string=} data.depositDate depositDate
     * @param {number=} data.possibleAmount possibleAmount
     * @param {number=} data.forecastAmount forecastAmount
     * @param {number=} data.investmentAmount investmentAmount
     * @param {string=} data.investmentDate investmentDate
     * @param {number=} data.variousAmount1 variousAmount1
     * @param {string=} data.variousDate1 variousDate1
     * @param {number=} data.variousAmount2 variousAmount2
     * @param {string=} data.variousDate2 variousDate2
     * @param {number=} data.variousAmount3 variousAmount3
     * @param {string=} data.variousDate3 variousDate3
     * @param {number=} data.variousAmount4 variousAmount4
     * @param {string=} data.variousDate4 variousDate4
     * @param {number=} data.divisionId divisionId
     * @param {object=} data.division division
     * @param {number=} data.honorary honorary
     * @param {string=} data.latitude latitude
     * @param {string=} data.longitude longitude
     * @param {number=} data.department1Id department1Id
     * @param {object=} data.department1 department1
     * @param {number=} data.department2Id department2Id
     * @param {object=} data.department2 department2
     * @param {number=} data.department3Id department3Id
     * @param {object=} data.department3 department3
     * @param {number=} data.department4Id department4Id
     * @param {object=} data.department4 department4
     * @param {number=} data.subDivisionId subDivisionId
     * @param {object=} data.subDivision subDivision
     * @param {number=} data.metierId metierId
     * @param {object=} data.metier metier
     * @param {number=} data.communicationPlan communicationPlan
     * @param {string=} data.briefDate briefDate
     * @param {number=} data.originalUserCollaboratorId originalUserCollaboratorId
     * @param {object=} data.originalUserCollaborator originalUserCollaborator
     * @param {number=} data.originalUserOperationsManagerId originalUserOperationsManagerId
     * @param {object=} data.originalUserOperationsManager originalUserOperationsManager
     * @param {string=} data.reopeningDate reopeningDate
     * @param {number=} data.cityId cityId
     * @param {object=} data.city city
     * @param {object[]=} data.candidacies candidacies
     * @param {object[]=} data.hunts hunts
     * @param {DiffusionJobBoard[]=} data.diffusionJobBoards diffusionJobBoards
     * @param {number=} data.diffusionId number
     * @param {number=} data.platformId number
     * @param {number=} data.diffusionActionId diffusionActionId
     * @param {DiffusionAction=} data.diffusionAction diffusionAction
     * @param {string=} data.jobDescription string
     * @param {string=} data.companyDescription string
     * @param {string=} data.companyUrl string
     * @param {string=} data.applicantProfile string
     * @param {number=} data.applicantDegree number
     * @param {number=} data.applicantExperience number
     * @param {string=} data.applicationUrl string
     * @param {string=} data.status string
     * @param {string=} data.diffusionUrl string
     * @param {string=} data.diffusionStatus string
     * @param {boolean=} data.isBackout isBackout
     * @param {object[]=} data.missionUserCollaboratorHistorics missionUserCollaboratorHistorics
     * @param {object[]=} data.missionUserOperationsManagerHistorics missionUserOperationsManagerHistorics
     * @param {object[]=} data.eventCandidates eventCandidates
     * @param {object[]=} data.eventClients eventClients
     * @param {string=} data.websiteName websiteName
     * @param {string=} data.websiteDescription websiteDescription
     * @param {string=} data.websiteDescriptionPoste websiteDescriptionPoste
     * @param {string=} data.websiteProfile websiteProfile
     * @param {string=} data.websiteAdvantages websiteAdvantages
     * @param {string=} data.websiteAccompaniement websiteAccompaniement
     * @param {string=} data.websiteUrl websiteUrl
     * @param {number=} data.dualPlacementAmont dualPlacementAmont
     * @param {string=} data.dualPlacementDate dualPlacementDate
     */
    constructor({
        missionId = 0,
        userCollaboratorId,
        userCollaborator,
        userOperationsManagerId,
        userOperationsManager,
        ref = '',
        name = 'H/F ',
        remuneration,
        missionFamilyId,
        missionFamily,
        clientId,
        client,
        contactId,
        contact,
        missionStatusId,
        missionStatus,
        depositAmount,
        depositDate,
        possibleAmount,
        forecastAmount,
        investmentAmount,
        investmentDate,
        variousAmount1,
        variousDate1,
        variousAmount2,
        variousDate2,
        variousAmount3,
        variousDate3,
        variousAmount4,
        variousDate4,
        divisionId,
        division,
        honorary,
        latitude,
        longitude,
        department1Id,
        department1,
        department2Id,
        department2,
        department3Id,
        department3,
        department4Id,
        department4,
        subDivisionId,
        subDivision,
        metierId,
        metier,
        communicationPlan,
        briefDate,
        originalUserCollaboratorId,
        originalUserCollaborator,
        originalUserOperationsManagerId,
        originalUserOperationsManager,
        reopeningDate,
        cityId,
        city,
        candidacies = [],
        hunts = [],
        diffusionJobBoards = [],
        diffusionId,
        platformId,
        diffusionActionId,
        diffusionAction,
        jobDescription,
        companyDescription,
        companyUrl,
        applicantProfile,
        applicantDegree,
        applicantExperience,
        applicationUrl,
        status,
        diffusionUrl,
        diffusionStatus,
        isBackout = false,
        missionUserCollaboratorHistorics = [],
        missionUserOperationsManagerHistorics = [],
        eventCandidates = [],
        eventClients = [],
        websiteName = 'H/F ',
        websiteDescription = '',
        websiteDescriptionPoste = '',
        websiteProfile = '',
        websiteAdvantages = '',
        websiteAccompaniement = '',
        websiteUrl = '',
        dualPlacementAmont,
        dualPlacementDate,
        ...data
    } = {}) {
        super(data)
        this.missionId = missionId
        this.userCollaboratorId = userCollaboratorId
        this.userCollaborator = userCollaborator ? new User(userCollaborator) : undefined
        this.userOperationsManagerId = userOperationsManagerId
        this.userOperationsManager = userOperationsManager ? new User(userOperationsManager) : undefined
        this.ref = ref
        this.name = name
        this.remuneration = remuneration
        this.missionFamilyId = missionFamilyId
        this.missionFamily = missionFamily
        this.clientId = clientId
        this.client = client ? new Client(client) : undefined
        this.contactId = contactId
        this.contact = contact ? new Contact(contact) : undefined
        this.missionStatusId = missionStatusId
        this.missionStatus = missionStatus
        this.depositAmount = depositAmount
        this.depositDate = depositDate ? new Date(depositDate) : undefined
        this.possibleAmount = possibleAmount
        this.forecastAmount = forecastAmount
        this.investmentAmount = investmentAmount
        this.investmentDate = investmentDate ? new Date(investmentDate) : undefined
        this.variousAmount1 = variousAmount1
        this.variousDate1 = variousDate1 ? new Date(variousDate1) : undefined
        this.variousAmount2 = variousAmount2
        this.variousDate2 = variousDate2 ? new Date(variousDate2) : undefined
        this.variousAmount3 = variousAmount3
        this.variousDate3 = variousDate3 ? new Date(variousDate3) : undefined
        this.variousAmount4 = variousAmount4
        this.variousDate4 = variousDate4 ? new Date(variousDate4) : undefined
        this.divisionId = divisionId
        this.division = division
        this.honorary = honorary
        this.latitude = !Number.isNaN(Number.parseFloat(latitude)) ? Number.parseFloat(latitude) : 0
        this.longitude = !Number.isNaN(Number.parseFloat(longitude)) ? Number.parseFloat(longitude) : 0
        this.department1Id = department1Id
        this.department1 = department1
        this.department2Id = department2Id
        this.department2 = department2
        this.department3Id = department3Id
        this.department3 = department3
        this.department4Id = department4Id
        this.department4 = department4
        this.subDivisionId = subDivisionId
        this.subDivision = subDivision
        this.metierId = metierId
        this.metier = metier
        this.communicationPlan = communicationPlan
        this.briefDate = briefDate ? new Date(briefDate) : undefined
        this.originalUserCollaboratorId = originalUserCollaboratorId
        this.originalUserCollaborator = originalUserCollaborator ? new User(originalUserCollaborator) : undefined
        this.originalUserOperationsManagerId = originalUserOperationsManagerId
        this.originalUserOperationsManager = originalUserOperationsManager ? new User(originalUserOperationsManager) : undefined
        this.reopeningDate = reopeningDate ? new Date(reopeningDate) : undefined
        this.cityId = cityId
        this.city = city

        this.candidacies = candidacies?.map(x => new Candidacy(x)) ?? []
        this.hunts = hunts?.map(x => new Hunt(x)) ?? []
        this.diffusionJobBoards = diffusionJobBoards?.map(x => new DiffusionJobBoard(x)) ?? []

        this.isBackout = isBackout

        this.missionUserCollaboratorHistorics = missionUserCollaboratorHistorics?.map(x => new MissionUserCollaboratorHistoric(x)) ?? []
        this.missionUserOperationsManagerHistorics = missionUserOperationsManagerHistorics?.map(x => new MissionUserOperationsManagerHistoric(x)) ?? []

        /** @type {(Candidacy | Hunt)[]} */
        this.boardItems = [this.candidacies, this.hunts].flat().sort((a, b) => a.updatedAt.getTime() - b.updatedAt.getTime())
        this.diffusionId = diffusionId
        this.platformId = platformId
        this.diffusionActionId = diffusionActionId
        this.diffusionAction = diffusionAction
        this.jobDescription = jobDescription
        this.companyDescription = companyDescription
        this.companyUrl = companyUrl
        this.applicantProfile = applicantProfile
        this.applicantDegree = applicantDegree
        this.applicantExperience = applicantExperience
        this.applicationUrl = applicationUrl
        this.status = status
        this.diffusionUrl = diffusionUrl
        this.diffusionStatus = diffusionStatus

        this.eventCandidates = eventCandidates
        this.eventClients = eventClients

        this.websiteName = websiteName || 'H/F '
        // eslint-disable-next-line max-len
        this.websiteDescription = websiteDescription || 'DE GRAËT CONSULTING est un cabinet de recrutement et d’approche directe présent sur l’ensemble du territoire grâce à ses bureaux situés à Nantes, Paris, Marseille, Lyon, Lille, Toulouse et Caen.\nMulti-spécialiste, nous intervenons sur tous les secteurs d’activité, pour tous types de postes et auprès d’entreprises de toutes tailles, des PME / TPE locales aux grands groupes internationaux.'
        this.websiteDescriptionPoste = websiteDescriptionPoste || ''
        this.websiteProfile = websiteProfile || ''
        this.websiteAdvantages = websiteAdvantages || ''
        this.websiteAccompaniement = websiteAccompaniement || ''
        this.websiteUrl = websiteUrl || ''

        this.dualPlacementAmont = dualPlacementAmont
        this.dualPlacementDate = dualPlacementDate ? new Date(dualPlacementDate) : undefined
    }
}

/**
 * Mission Object used to bind error message
 */
export class ErrorMission extends ErrorBase {
    constructor() {
        super()
        this.missionId = ''
        this.userCollaboratorId = ''
        this.userCollaborator = ''
        this.userOperationsManagerId = ''
        this.userOperationsManager = ''
        this.ref = ''
        this.name = ''
        this.remuneration = ''
        this.missionFamilyId = ''
        this.missionFamily = ''
        this.clientId = ''
        this.client = ''
        this.contactId = ''
        this.contact = ''
        this.missionStatusId = ''
        this.missionStatus = ''
        this.depositAmount = ''
        this.depositDate = ''
        this.possibleAmount = ''
        this.forecastAmount = ''
        this.investmentAmount = ''
        this.investmentDate = ''
        this.variousAmount1 = ''
        this.variousDate1 = ''
        this.variousAmount2 = ''
        this.variousDate2 = ''
        this.variousAmount3 = ''
        this.variousDate3 = ''
        this.variousAmount4 = ''
        this.variousDate4 = ''
        this.divisionId = ''
        this.division = ''
        this.honorary = ''
        this.latitude = ''
        this.longitude = ''
        this.department1Id = ''
        this.department1 = ''
        this.department2Id = ''
        this.department2 = ''
        this.department3Id = ''
        this.department3 = ''
        this.department4Id = ''
        this.department4 = ''
        this.subDivisionId = ''
        this.subDivision = ''
        this.metierId = ''
        this.metier = ''
        this.communicationPlan = ''
        this.briefDate = ''
        this.originalUserCollaboratorId = ''
        this.originalUserCollaborator = ''
        this.originalUserOperationsManagerId = ''
        this.originalUserOperationsManager = ''
        this.reopeningDate = ''
        this.cityId = ''
        this.city = ''
        this.contract = ''
        this.contractId = ''
        this.sheet = ''
        this.sheetId = ''
        this.advert = ''
        this.advertId = ''
        this.candidacies = ''
        this.diffusionJobBoards = ''
        this.diffusionId = ''
        this.platformId = ''
        this.diffusionActionId = ''
        this.diffusionAction = ''
        this.jobDescription = ''
        this.companyDescription = ''
        this.companyUrl = ''
        this.applicantProfile = ''
        this.applicantDegree = ''
        this.applicantExperience = ''
        this.applicationUrl = ''
        this.status = ''
        this.diffusionUrl = ''
        this.diffusionStatus = ''
        this.isBackout = ''
        this.eventCandidates = ''
        this.eventClients = ''
        this.websiteName = ''
        this.websiteDescription = ''
        this.websiteProfile = ''
        this.websiteAdvantages = ''
        this.dualPlacementAmont = ''
        this.dualPlacementDate = ''
        this.websiteUrl = ''
        this.websiteAccompaniement = ''
    }
}
