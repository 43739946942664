/* eslint-disable max-classes-per-file */
import React, { PureComponent } from 'react'
import Login from 'pages/login'
import { initializeIcons } from '@uifabric/icons'
import { connect } from 'react-redux'
import Layout from 'pages/_layout'
import { loadTheme, MessageBarType } from '@fluentui/react'
import { PrivateRoute, PublicRoute } from 'components/routes'
import history from 'helpers/history'
import Modal from 'components/containers/modal'
import Index from 'pages/index'
import withHandlers from 'helpers/hoc/withHandlers'
import PublicLayout from 'pages/_publicLayout'
import {
    RouteChildrenProps, Switch, Router, Redirect,
} from 'react-router-dom'
import 'requests/pretender'
import { mapStateToProps, mapDispatchToProps } from 'redux/map'
import NotFound from 'pages/notFound'
import User from 'requests/objects/user'
import Param from 'requests/objects/param'
import { NEW_PATH } from 'types/others'
import IndexUsers from 'pages/utilisateurs'
import IdUsers from 'pages/utilisateurs/[id]'
import IndexCandidates from 'pages/candidats'
import IdCandidates from 'pages/candidats/[id]'
import IndexCommercialInformation from 'pages/informations-commerciales'
import IdCommercialInformation from 'pages/informations-commerciales/[id]'
import IndexEmailTemplates from 'pages/modele-emails'
import IdEmailTemplates from 'pages/modele-emails/[id]'
import IndexClients from 'pages/clients'
import IdClients from 'pages/clients/[id]/index'
import IdParentCompanies from 'pages/clients/[id]/societe-meres/[id]'
import IdContacts from 'pages/clients/[id]/contacts/[id]/index'
import IdEventClients from 'pages/clients/[id]/contacts/[id]/evenements/[id]'
import IndexMissions from 'pages/missions'
import IdMissions from 'pages/missions/[id]'
import IndexMapMissions from 'pages/carte-missions'
import IdCandidacies from 'pages/candidats/candidatures/[id]'
import IdHunts from 'pages/candidats/chasses/[id]'
import IndexPhoneTemplates from 'pages/phone'
import PasswordForgot from 'pages/password-forgot'
import PasswordNew from 'pages/password-new'

/**
 * Global components props
 * @typedef {import('redux/map').ReduxProps & import('helpers/hoc/withHandlers').HandlersProps & RouteChildrenProps} AppProps
 */

initializeIcons()
// https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
loadTheme({
    palette: {
        themePrimary: '#002930',
        themeLighterAlt: '#cbe2e6',
        themeLighter: '#a0c8cf',
        themeLight: '#7aafb9',
        themeTertiary: '#5997a2',
        themeSecondary: '#3d808b',
        themeDarkAlt: '#266975',
        themeDark: '#15535e',
        themeDarker: '#083e47',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
})

const AppLayout = connect(mapStateToProps, mapDispatchToProps)(withHandlers(Layout))
const AppPublicLayout = connect(mapStateToProps, mapDispatchToProps)(withHandlers(PublicLayout))

const AppModal = connect(mapStateToProps, mapDispatchToProps)(Modal)

const AppLogin = connect(mapStateToProps, mapDispatchToProps)(withHandlers(Login))
const AppPasswordForgot = connect(mapStateToProps, mapDispatchToProps)(withHandlers(PasswordForgot))
const AppPasswordNew = connect(mapStateToProps, mapDispatchToProps)(withHandlers(PasswordNew))

const AppIndex = connect(mapStateToProps, mapDispatchToProps)(withHandlers(Index))
const AppIndexUsers = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexUsers))
const AppIdUsers = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdUsers))

const AppIndexCandidates = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexCandidates))
const AppIdCandidates = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdCandidates))

const AppIndexCommercialInformation = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexCommercialInformation))
const AppIdCommercialInformation = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdCommercialInformation))
const AppIndexEmailTemplates = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexEmailTemplates))
const AppIdEmailTemplates = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdEmailTemplates))

const AppIdCandidacies = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdCandidacies))
const AppIdHunts = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdHunts))

const AppIndexMissions = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexMissions))
const AppIdMissions = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdMissions))

const AppIndexClients = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexClients))
const AppIdClients = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdClients))

const AppIndexMapMissions = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexMapMissions))

const AppIdParentCompanies = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdParentCompanies))
const AppIdContacts = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdContacts))
const AppIdEventClients = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdEventClients))

const AppIndexPhoneTemplates = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IndexPhoneTemplates))

// const AppIdCandidates = connect(mapStateToProps, mapDispatchToProps)(withHandlers(IdCandidacies))

const AppError = connect(mapStateToProps, mapDispatchToProps)(NotFound)

/**
 * @augments {React.PureComponent<AppProps>}
 */
class _App extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            isInit: false,
        }
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { isAuthenticated } = this.props

        history.replace({ ...history.location, state: null }) // Force to clean history state on each refresh

        if (isAuthenticated)
            this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous props
     */
    componentDidUpdate(prevProps) {
        const { isAuthenticated } = this.props

        if (isAuthenticated && isAuthenticated !== prevProps.isAuthenticated)
            this.init()
    }

    /**
     * Init app by getting constants and user infos
     */
    init() {
        const {
            setMessageBar, setCommand, setBreadcrumb, paramsHandler, usersHandler, init, signOut, setTitle,
        } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])
        setBreadcrumb([])
        setTitle('')
        this.setState({ isInit: false }, async () => {
            try {
                const lastUpdate = await paramsHandler.getLastUpdate().fetch()
                const lastUpdateLocal = (() => {
                    const lastUpdateStorage = new Date(localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_last_update`))
                    return lastUpdateStorage instanceof Date && !Number.isNaN(lastUpdateStorage.getTime()) ? lastUpdateStorage : null
                })()

                if (
                    !lastUpdateLocal
                    || lastUpdate > lastUpdateLocal
                    || !localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_me`)
                    || !localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_param`)
                ) {
                    const [me, param] = await Promise.all([
                        usersHandler.getMe().fetch(),
                        paramsHandler.getAll().fetch(),
                    ])
                    init({ me, param, lastUpdate })
                } else {
                    init({
                        me: new User(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_me`))),
                        param: new Param(JSON.parse(localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_param`))),
                        lastUpdate,
                    })
                    this.setState({ isInit: true })
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
                setMessageBar({
                    isDisplayed: true,
                    type: MessageBarType.error,
                    message: error,
                })
                signOut()
            } finally {
                this.setState({ isInit: true })
            }
        })
    }

    render() {
        const { isAuthenticated } = this.props
        const { isInit } = this.state

        return (
            <>
                <Router history={history}>
                    <AppLayout
                        isDisplay={isAuthenticated}
                        refresh={() => {
                            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_me`)
                            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_param`)
                            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_last_update`)
                            this.init()
                        }}
                    >
                        <Switch>
                            <PrivateRoute
                                path="/carte-missions"
                                component={AppIndexMapMissions}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Carte missions"
                            />

                            <PrivateRoute
                                path={`/missions/${NEW_PATH}`}
                                component={AppIdMissions}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Nouvelle mission"
                            />
                            <PrivateRoute
                                path="/missions/:missionId"
                                component={AppIdMissions}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Mission n°${props.missionId}`}
                            />
                            <PrivateRoute
                                path="/missions"
                                component={AppIndexMissions}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Missions"
                            />

                            <PrivateRoute
                                path="/clients/:clientId/societe-meres/:parentCompanyId"
                                component={AppIdParentCompanies}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Société mère n°${props.parentCompanyId}`}
                            />

                            <PrivateRoute
                                path={`/clients/:clientId/contacts/:contactId/evenements/${NEW_PATH}`}
                                component={AppIdEventClients}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Nouvel évènement"
                            />
                            <PrivateRoute
                                path="/clients/:clientId/contacts/:contactId/evenements/:eventClientId"
                                component={AppIdEventClients}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Évènements client n°${props.eventClientId}`}
                            />

                            <PrivateRoute
                                path={`/clients/:clientId/contacts/${NEW_PATH}`}
                                component={AppIdContacts}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Nouveau Contact"
                            />
                            <PrivateRoute
                                path="/clients/:clientId/contacts/:contactId"
                                component={AppIdContacts}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Contact n°${props.contactId}`}
                            />

                            <PrivateRoute
                                path={`/clients/${NEW_PATH}`}
                                component={AppIdClients}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Nouveau client"
                            />
                            <PrivateRoute
                                path="/clients/:clientId"
                                component={AppIdClients}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Client n°${props.clientId}`}
                            />
                            <PrivateRoute
                                path="/clients"
                                component={AppIndexClients}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Clients"
                            />

                            <PrivateRoute
                                path="/candidats/:candidateId/chasses/:huntId"
                                component={AppIdHunts}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Chasse n°${props.huntId}`}
                            />
                            <PrivateRoute
                                path="/candidats/:candidateId/candidatures/:candidacyId"
                                component={AppIdCandidacies}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Candidature n°${props.candidacyId}`}
                            />
                            <PrivateRoute
                                path={`/candidats/${NEW_PATH}`}
                                component={AppIdCandidates}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Nouveau candidat"
                            />
                            <PrivateRoute
                                path="/candidats/:candidateId"
                                component={AppIdCandidates}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Candidat n°${props.candidateId}`}
                            />
                            <PrivateRoute
                                path="/candidats"
                                component={AppIndexCandidates}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Candidats"
                            />

                            <PrivateRoute
                                path={`/informations-commerciales/${NEW_PATH}`}
                                component={AppIdCommercialInformation}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Nouvelle information commerciale"
                            />
                            <PrivateRoute
                                path="/informations-commerciales/:commercialInformationId"
                                component={AppIdCommercialInformation}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Information commerciale n°${props.commercialInformationId}`}
                            />
                            <PrivateRoute
                                path="/informations-commerciales"
                                component={AppIndexCommercialInformation}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Informations commerciales"
                            />

                            <PrivateRoute
                                path={`/utilisateurs/${NEW_PATH}`}
                                component={AppIdUsers}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Nouveau utilisateur"
                            />
                            <PrivateRoute
                                path="/utilisateurs/:userId"
                                component={AppIdUsers}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Utilisateur n°${props.userId}`}
                            />
                            <PrivateRoute
                                path="/utilisateurs"
                                component={AppIndexUsers}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Utilisateurs"
                            />

                            <PrivateRoute
                                path={`/modele-emails/${NEW_PATH}`}
                                component={AppIdEmailTemplates}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Nouveau modèle email"
                            />
                            <PrivateRoute
                                path="/modele-emails/:emailTemplateId"
                                component={AppIdEmailTemplates}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                            // title={props => `Modèle email n°${props.emailTemplateId}`}
                            />
                            <PrivateRoute
                                path="/modele-emails"
                                component={AppIndexEmailTemplates}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Modèles emails"
                            />
                            <PrivateRoute
                                path="/recherche-telephone"
                                component={AppIndexPhoneTemplates}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Recherche télephone"
                            />

                            <PrivateRoute
                                exact
                                path="/"
                                component={AppIndex}
                                isAuthenticated={isAuthenticated}
                                isInit={isInit}
                                title="Accueil"
                            />
                            {isAuthenticated && (
                                <PublicRoute
                                    component={AppError}
                                    title="Error"
                                />
                            )}
                        </Switch>
                    </AppLayout>

                    <AppPublicLayout
                        isDisplay={!isAuthenticated}
                    >
                        <Switch>
                            <PublicRoute
                                path="/login"
                                component={AppLogin}
                                title="Connexion"
                            />
                            <PublicRoute
                                path="/nouveau-motdepasse/:key"
                                component={AppPasswordNew}
                                title="Mot de passe oublié"
                            />
                            <PublicRoute
                                path="/motdepasse-oublie"
                                component={AppPasswordForgot}
                                title="Nouveau mot de passe"
                            />
                            {!isAuthenticated && (
                                <Redirect to="/login" />
                            )}
                        </Switch>
                    </AppPublicLayout>
                </Router>
                <AppModal />
            </>
        )
    }
}

const App = connect(mapStateToProps, mapDispatchToProps)(withHandlers(_App))

export default App
