/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * EmailTemplate Object
 */
export default class EmailTemplate extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.emailTemplateId emailTemplateId
     * @param {string=} data.name name
     * @param {string=} data.subject subject
     * @param {string=} data.content content
     * @param {object=} data.createdByUser createdByUser
     */
    constructor({
        emailTemplateId,
        name = '',
        subject = '',
        content = '',
        createdByUser,
        ...data
    } = {}) {
        super(data)
        this.emailTemplateId = emailTemplateId
        this.name = name
        this.subject = subject
        this.content = content
        this.createdByUser = createdByUser
    }
}

/**
 * EmailTemplate Object used to bind error message
 */
export class ErrorEmailTemplate extends ErrorBase {
    constructor() {
        super()
        this.emailTemplateId = ''
        this.name = ''
        this.subject = ''
        this.content = ''
        this.createdByUser = ''
    }
}
