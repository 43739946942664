import Home, { ErrorHome } from 'requests/objects/home'
// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler from 'requests/apiHandler'
import NotImplementedError from 'requests/errors/notImplementedError'

/**
 * HomesHandler
 * @augments {ApiHandler<Home, ErrorHome>}
 */
export default class HomesHandler extends ApiHandler {
    constructor() {
        super({ type: Home, errorType: ErrorHome, key: 'homes' })
    }

    /**
     * Get one by ID
     * @returns {import('requests/apiHandler').RequestApi<Home>} Request
     */
    get() {
        const request = this.initFetchRequest({})

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    getAll() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    getById() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    create() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    updateById() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    upsert() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    removeById() {
        throw new NotImplementedError()
    }
}
