/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import User from 'requests/objects/user'

/**
 * MissionUserOperationsManagerHistoric Object
 */
export default class MissionUserOperationsManagerHistoric extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.missionUserCollaboratorHistoricId missionUserCollaboratorHistoricId
     * @param {number=} data.missionId missionId
     * @param {number=} data.userOperationsManagerId userOperationsManagerId
     * @param {object=} data.userOperationsManager userOperationsManager
     * @param {string=} data.startDate startDate
     * @param {string=} data.endDate endDate
     */
    constructor({
        missionUserCollaboratorHistoricId = 0,
        userOperationsManagerId,
        userOperationsManager,
        missionId,
        startDate = '',
        endDate = '',
        ...data
    } = {}) {
        super(data)
        this.missionUserCollaboratorHistoricId = missionUserCollaboratorHistoricId
        this.userOperationsManagerId = userOperationsManagerId
        this.userOperationsManager = userOperationsManager ? new User(userOperationsManager) : undefined
        this.missionId = missionId
        this.startDate = startDate ? new Date(startDate) : undefined
        this.endDate = endDate ? new Date(endDate) : undefined
    }
}

/**
 * Mission Object used to bind error message
 */
export class ErrorMissionUserOperationsManagerHistoric extends ErrorBase {
    constructor() {
        super()
        this.missionUserCollaboratorHistoricId = ''
        this.userOperationsManagerId = ''
        this.userOperationsManager = ''
        this.missionId = ''
        this.startDate = ''
        this.endDate = ''
    }
}
