import {
    // eslint-disable-next-line import/named
    init, signIn, signOut, PayloadInit, PayloadSingIn, UserState, PayloadMe, PayloadParam, setMe, addParam, editParam, removeParam,
} from 'redux/slices/user'
import {
    // eslint-disable-next-line import/named, max-len
    setBreadcrumb, setCommand, setMessageBar, setModal, setUrl, PayloadMessageBar, PayloadUrl, PayloadBreadcrumb, PayloadCommand, PayloadModal, CommonState, setTitle, PayloadTitle,
} from 'redux/slices/common'

/** @debug {UserState|CommonState} */

/**
 * @typedef {object} ReduxProps
 * @property {function(PayloadInit):void} init Init
 * @property {function(PayloadMe):void} setMe setMe
 * @property {function(PayloadSingIn):void} signIn Sign in
 * @property {function():void} signOut Sign out
 * @property {function(PayloadParam):void} addParam Add param
 * @property {function(PayloadParam):void} editParam Edit param from constant
 * @property {function(PayloadParam):void} removeParam Remove param from constant
 * @property {function(PayloadMessageBar):void} setMessageBar Set MessageBar
 * @property {function(PayloadCommand):void} setCommand Set CommandBar
 * @property {function(PayloadBreadcrumb):void} setBreadcrumb Set Breadcrumb
 * @property {function(PayloadModal):void} setModal Set Modal
 * @property {function(PayloadUrl):void} setUrl Set Current Url
 * @property {function(PayloadTitle):void} setTitle Set Current title
 * @property {UserState["isAuthenticated"]} isAuthenticated Is user authenticated
 * @property {UserState["me"]} me User informations
 * @property {UserState["param"]} param Constants
 * @property {CommonState["selectedKeyURL"]} selectedKeyURL Current URL in application
 * @property {CommonState["messageBar"]} messageBar Messagebar
 * @property {CommonState["breadcrumb"]} breadcrumb Breadcrumb items
 * @property {CommonState["command"]} command Command items
 * @property {CommonState["modal"]} modal Modal
 * @property {CommonState["title"]} title title
 */

const mapDispatchToProps = dispatch => ({
    init: ({ me, param, lastUpdate }) => dispatch(init({ me, param, lastUpdate })),
    setMe: ({ me }) => dispatch(setMe({ me })),
    signIn: token => dispatch(signIn(token)),
    signOut: () => dispatch(signOut(undefined)),
    addParam: data => dispatch(addParam(data)),
    editParam: ({ key, value }) => dispatch(editParam({ key, value })),
    removeParam: ({ key, value }) => dispatch(removeParam({ key, value })),

    setMessageBar: ({ isDisplayed, type, message }) => dispatch(setMessageBar({ isDisplayed, type, message })),
    setCommand: commandBarItems => dispatch(setCommand(commandBarItems)),
    setBreadcrumb: data => dispatch(setBreadcrumb(data)),
    setTitle: data => dispatch(setTitle(data)),
    setModal: ({
        show, title, subTitle, callback, content,
    }) => dispatch(setModal({
        show, title, subTitle, callback, content,
    })),

    setUrl: selectedKeyURL => dispatch(setUrl(selectedKeyURL)),
})

const mapStateToProps = state => ({
    isAuthenticated: state.user.isAuthenticated,
    me: state.user.me,
    param: state.user.param,
    selectedKeyURL: state.common.selectedKeyURL,
    messageBar: state.common.messageBar,
    breadcrumb: state.common.breadcrumb,
    command: state.common.command,
    modal: state.common.modal,
    title: state.common.title,
})

export { mapDispatchToProps, mapStateToProps }
