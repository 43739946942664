import React, {
    useCallback, useMemo, useRef, useState, useImperativeHandle, forwardRef,
} from 'react'
import Mission from 'requests/objects/mission'
import {
    GoogleMap, Marker, InfoWindow, useJsApiLoader,
} from '@react-google-maps/api'
import { usePosition } from 'use-position'
import EDot from 'types/missions/enums/dot'
import { Text } from '@fluentui/react'
import { Link } from 'react-router-dom'
import Time from 'helpers/methods/time'
import Loader from 'components/visuals/loader'
/**
 * @typedef {object} MissionMapProps
 * @property {Mission[]} items Missions
 * @property {React.CSSProperties} mapContainerStyle mapContainerStyle
 * @property {({ minLatitude, maxLatitude, minLongitude, maxLongitude}
 *  : { minLatitude: number; maxLatitude: number; minLongitude: number; maxLongitude: number}) => void} setBounds setBounds
 * @property {import('react').Ref<any>=} ref Ref
 */

/**
 * @typedef {object} MissionMapRef
 * @property {(index: number) => void} open Open
 * @property {() => void} closeAll closeAll
 */

/**
 * Mission map element
 * @param {MissionMapProps} props props
 * @param {import('react').Ref<MissionMapRef>} missionMapRef missionMapRef
 * @returns {JSX.Element} Element
 */
function MissionMapElement({
    items = [], setBounds, mapContainerStyle = {},
}, missionMapRef) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    })

    const { latitude: navigatorLatitude, longitude: navigatorLongitude } = usePosition(false)

    /** @type {google.maps.LatLng | google.maps.LatLngLiteral} */
    const navigatorPosition = useMemo(() => ({
        lat: navigatorLatitude,
        lng: navigatorLongitude,
    }), [navigatorLatitude, navigatorLongitude])

    /** @type {google.maps.LatLng | google.maps.LatLngLiteral} */
    const center = useMemo(() => ({
        lat: navigatorLatitude || 47.22514438552267,
        lng: navigatorLongitude || -1.5639597023279326,
    }), [navigatorLatitude, navigatorLongitude])

    /** @type {google.maps.MapOptions} */
    const options = useMemo(() => ({
        mapTypeControl: false,
        clickableIcons: false,
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'transit',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [{ visibility: 'on' }],
            },
        ],
    }), [])

    const [isMyPosInfoOpen, setIsMyPosInfoOpen] = useState(false)

    const [isMissionInfoOpen, setIsMissionInfoOpen] = useState(new Array(items.length).fill(false))

    /** @type {[google.maps.Map, React.Dispatch<React.SetStateAction<google.maps.Map>>]} */
    const [map, setMap] = useState(null)

    /** @type {React.MutableRefObject<NodeJS.Timeout>} Used to debouche bounds change */
    const timeoutBoundsRef = useRef()

    /**
     * On bounds changed
     */
    const onBoundsChanged = useCallback(() => {
        const bounds = map.getBounds().toJSON()

        // Clear to debouce
        if (timeoutBoundsRef.current)
            clearTimeout(timeoutBoundsRef.current)

        timeoutBoundsRef.current = setTimeout(() => {
            setBounds({
                minLatitude: bounds.south, maxLatitude: bounds.north, minLongitude: bounds.west, maxLongitude: bounds.east,
            })
        }, 500)

        return () => {
            clearTimeout(timeoutBoundsRef.current)
        }
    }, [map, setBounds])

    /**
     * Set current is mission info open
     * @param {boolean} value Value
     * @param {number} inxex inxex
     * @param {number=} zoomValue zoomValue
     * @param {google.maps.MapMouseEvent=} ev ev
     */
    const setCurrentIsMissionInfoOpen = useCallback((value, index, zoomValue) => {
        const newArray = new Array(items.length).fill(false)
        newArray[index] = value
        setIsMissionInfoOpen(newArray)
        setIsMyPosInfoOpen(false)
        if (zoomValue) {
            map.setZoom(zoomValue)
            map.panTo({ lat: items[index].latitude, lng: items[index].longitude })
        }
    }, [map, items])

    // Forward some method to parent
    useImperativeHandle(missionMapRef, () => ({
        open: index => setCurrentIsMissionInfoOpen(true, index, 12),
        closeAll: () => setIsMissionInfoOpen(new Array(items.length).fill(false)),
    }))

    if (!isLoaded)
        return <Loader />

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={8}
            onBoundsChanged={onBoundsChanged}
            onLoad={mapLoaded => setMap(mapLoaded)}
            options={options}
        >
            {navigatorPosition.lat && navigatorPosition.lng
                && (
                    <Marker
                        position={navigatorPosition}
                        title="Ma position"
                        icon={EDot.BLUE}
                        onClick={() => {
                            setIsMyPosInfoOpen(true)
                            setIsMissionInfoOpen(new Array(items.length).fill(false))
                            // map.setCenter(ev.latLng)
                        }}
                    >
                        {isMyPosInfoOpen
                            && (
                                <InfoWindow
                                    zIndex={100}
                                    onCloseClick={() => setIsMyPosInfoOpen(false)}
                                >
                                    <Text>
                                        Ma position
                                    </Text>
                                </InfoWindow>
                            )}
                    </Marker>
                )}
            {items.map((item, index) => (
                <Marker
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.missionId}_${index}`}
                    position={{ lat: item.latitude, lng: item.longitude }}
                    title={item?.name}
                    icon={EDot.RED}
                    onClick={() => setCurrentIsMissionInfoOpen(true, index)}
                >
                    {isMissionInfoOpen[index]
                        && (
                            <InfoWindow
                                zIndex={100}
                                onCloseClick={() => setCurrentIsMissionInfoOpen(false, index)}
                            >
                                <div>
                                    <Text>
                                        Client :
                                        {' '}
                                        <Link to={`/clients/${item.clientId}`}>
                                            {item.client?.name}
                                        </Link>
                                    </Text>
                                    <br />
                                    <Text>
                                        Mission :
                                        {' '}
                                        <Link to={`/missions/${item.missionId}`}>
                                            {item.ref}
                                        </Link>
                                    </Text>
                                    <br />
                                    <Text>
                                        Dernière modification :
                                        {' '}
                                        {item.createdAt
                                            ? Time(item.createdAt).getCleanDate({
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                            })
                                            : ''}
                                    </Text>
                                </div>
                            </InfoWindow>
                        )}
                </Marker>
            ))}
        </GoogleMap>
    )
}

/**
 * MissionMap
 * @type {React.ForwardRefExoticComponent<MissionMapProps>}
 */
const MissionMap = forwardRef(MissionMapElement)

export default MissionMap
