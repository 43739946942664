import React, { PureComponent } from 'react'
import {
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    buildColumns,
    Text,
    IColumn,
    Label,
    DefaultButton,
    TextField,
    TooltipHost,
    DirectionalHint,
    TooltipDelay,
    Icon,
    Selection,
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn, onColumnClick, getUpdatedList } from 'helpers/methods/common'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Candidate from 'requests/objects/candidate'
import { Link } from 'react-router-dom'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/candidats/index.module.scss'
import { NEW_PATH } from 'types/others'
import candidateColumns from 'types/candidats/columns'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import SaveSearchModal from 'components/pages/candidats/index/saveSearchModal'
import SearchPanel from 'components/pages/candidats/index/searchPanel'
import { Column } from 'types/column' // eslint-disable-line
import Types from 'types/types'
import ExcludeCandidatButton from 'components/pages/candidats/index/excludeCandidatButton'
import FilteredVirtualComboboxAjax from 'components/inputs/filteredVirtualComboboxAjax'

/** @debug {AppProps} */

/**
 * @typedef {object} SearchParamsType
 * @property {string} firstname firstname
 * @property {string} lastname lastname
 * @property {number} divisionId divisionId
 * @property {number[]} subDivisionIds subDivisionIds
 * @property {number[]} metierIds metierIds
 * @property {number[]} departmentIds departmentIds
 * @property {number} cityId cityId
 * @property {number} perimeter perimeter
 * @property {string} cvContent cvContent
 * @property {number[]} excludedCandidateIds excludedCandidateIds
 * @property {number=} researchCandidateId researchCandidateId
 */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexCandidates extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {IColumn[]} Columns displayed */
            columns: [],
            /** @type {Candidate[]} Items found in API */
            items: [],
            /** @type {Candidate[]} Initial items found in API */
            iniItems: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                firstname: null,
                lastname: null,
                divisionId: null,
                subDivisionIds: [],
                metierIds: [],
                departmentIds: [],
                cityId: null,
                perimeter: null,
                cvContent: null,
                excludedCandidateIds: [],
            },
            /** @type {boolean} Is save search modal visible? */
            isSaveSearchModalVisible: false,
            /** @type {boolean} Is my search visible? */
            isSearchPanelVisible: false,
            /** @type {boolean} Is Selection Enable? */
            isSelectionEnable: false,
            /** @type {boolean} Is Selection Enable? */
            isModalCandidacyVisible: false,
            /** @type {number} Selected mission id */
            missionId: null,
        }

        this.submitInput = React.createRef()

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null

        /** @type {Selection} */
        this.selection = new Selection()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        // this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams))
            if (prevState.searchParams.researchCandidateId !== searchParams.researchCandidateId) {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            } else {
                // Apply changement with delay to prevent lag
                clearTimeout(this.timeOutParams)
                this.timeOutParams = setTimeout(() => {
                    history.replace({ search: '' })
                    this.syncSearchParamsInHistory()
                }, 250)
            }

        // this.init()

        // this.setupBreadcrumb()

        // this.setupCommandBar()

        // this.setState({
        //     columns: this.buildColumns(),
        // })

        if (prevState.searchParams?.divisionId !== searchParams?.divisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                searchParams: {
                    ...truePrevState.searchParams,
                    subDivisionIds: [],
                    metierIds: [],
                },
            }))

        if (prevState.searchParams?.subDivisionIds.length !== searchParams?.subDivisionIds.length
            || !prevState.searchParams?.subDivisionIds.every((v, i) => v === searchParams?.subDivisionIds[i])
        )
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                searchParams: {
                    ...truePrevState.searchParams,
                    metierIds: [],
                },
            }))
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.candidatesHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, setTitle } = this.props

        setBreadcrumb([
            { text: 'Saisie candidat', key: 'candidat' },
            { text: 'Tous les candidats', key: 'all-candidat', isCurrentItem: true },
        ])
        setTitle('Candidats')
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props
        const { isSelectionEnable } = this.state

        setCommand(
            [
                {
                    key: 'new',
                    text: 'Nouveau',
                    iconProps: { iconName: 'Add' },
                    onClick: () => history.push(`/candidats/${NEW_PATH}`),
                    disabled: false,
                    isVisible: true,
                },
                {
                    key: 'search',
                    text: 'Recherches',
                    iconProps: { iconName: 'Search' },
                    subMenuProps: {
                        items: [
                            {
                                key: 'save',
                                text: 'Sauvegarder',
                                iconProps: { iconName: 'SaveTemplate' },
                                onClick: () => this.setState({ isSaveSearchModalVisible: true }),
                                disabled: false,
                            },
                            {
                                key: 'search',
                                text: 'Mes recherches',
                                iconProps: { iconName: 'SearchBookmark' },
                                onClick: () => this.setState({ isSearchPanelVisible: true }),
                                disabled: false,
                            },
                        ],
                    },
                    isVisible: true,
                },
                {
                    key: 'new-candidacies',
                    text: 'Candidature(s)',
                    iconProps: { iconName: 'PeopleAdd' },
                    onClick: () => this.setState({ isSelectionEnable: true }, () => this.setupCommandBar()),
                    disabled: false,
                    isVisible: !isSelectionEnable,
                },
                {
                    key: 'new-candidacies-modal',
                    text: 'Candidature(s)',
                    iconProps: { iconName: 'AddNotes' },
                    onClick: () => this.setState({ isModalCandidacyVisible: true }, () => this.setupCommandBar()),
                    disabled: false,
                    isVisible: isSelectionEnable,
                },
            ].filter(x => x.isVisible),
        )
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    firstname: queryParams.get('firstname') || null,
                    lastname: queryParams.get('lastname') || null,
                    divisionId: queryParams.get('divisionId') ? +queryParams.get('divisionId') : null,
                    subDivisionIds: queryParams.getAll('subDivisionIds') ? queryParams.getAll('subDivisionIds').map(x => +x) : [],
                    metierIds: queryParams.getAll('metierIds') ? queryParams.getAll('metierIds').map(x => +x) : [],
                    departmentIds: queryParams.getAll('departmentIds') ? queryParams.getAll('departmentIds').map(x => +x) : [],
                    cityId: queryParams.get('cityId') ? +queryParams.get('cityId') : null,
                    perimeter: queryParams.get('perimeter') ? +queryParams.get('perimeter') : null,
                    cvContent: queryParams.get('cvContent') || null,
                    excludedCandidateIds: [], // queryParams.getAll('excludedCandidateIds') ? queryParams.getAll('excludedCandidateIds').map(x => +x) : [],
                },
            }, () => this.syncSearchParamsInHistory())
        }

        this.setState({ columns: this.buildColumns() })
    }

    /**
     * Init <DetailList>
     * @returns {IColumn[]} Columns
     */
    buildColumns() {
        const { researchCandidatesHandler } = this.props

        const initColumns = [...candidateColumns]

        if ((new URLSearchParams(window.location.search)).get('researchCandidateId'))
            initColumns.push(/** @type {Column} */({
                fieldName: 'action',
                name: '',
                type: Types.STRING,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: item => (
                    <ExcludeCandidatButton
                        handler={researchCandidatesHandler}
                        candidateId={item.candidateId}
                        onClick={() => {
                            const { items } = this.state
                            const newItems = items.filter(x => x.candidateId !== item.candidateId)
                            this.setState({
                                items: newItems,
                                iniItems: newItems,
                            })
                        }}
                    />
                ),
            }))

        const cols = initColumns?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            this.onColumnClick.bind(this, {
                colName: 'columns', dataName: ['items'], source: 'state', isFlatten: true,
            }),
        )

        // eslint-disable-next-line no-restricted-syntax
        for (const column of columns) {
            column.name = cols[column.name]
            column.maxWidth = (() => {
                switch (column.name) {
                    case 'Prénom':
                    case 'Nom':
                    case 'Age':
                    case 'Téléphone':
                    case 'Email':
                    case 'Source(s)':
                        return 150
                    default:
                        return (column.name?.length ?? 1) * 12
                }
            })()
            column.minWidth = (column.name?.length ?? 1) * 5
            column.onRender = initColumns.find(x => x.name === column.name)?.onRender ?? this.handleRenderColumn(initColumns, column.name, column)
        }

        return columns
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { candidatesHandler, setBreadcrumb } = this.props
            const { searchParams } = this.state
            setBreadcrumb([
                { text: 'Saisie candidat', key: 'candidat' },
                { text: 'Tous les candidats', key: 'all-candidat', isCurrentItem: true },
            ])
            try {
                this.candidatesHandlerGetAll = candidatesHandler.getAll(searchParams)
                const candidates = await this.candidatesHandlerGetAll.fetch()
                this.setState({
                    items: candidates.map(x => this.flattenObj(x)),
                    iniItems: candidates,
                    status: Status.RESOLVED,
                })
                setBreadcrumb([
                    { text: 'Saisie candidat', key: 'candidat' },
                    { text: `Tous les candidats (${candidates.length || 0})`, key: 'all-candidat', isCurrentItem: true },
                ])
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            iniItems: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Create multiple candidacies
     */
    async addCandidacies() {
        const selectedCandidacies = /** @type {(Candidate)[]} */(this.selection.getSelection())
        const candidateIds = selectedCandidacies.map(x => x.candidateId)

        const { candidaciesHandler } = this.props
        const { missionId } = this.state

        try {
            this.candidaciesHandlerCreateCandidacies = candidaciesHandler.createCandidacies(candidateIds, missionId)
            await this.candidaciesHandlerCreateCandidacies.fetch()
            this.setState({ isSelectionEnable: false, missionId: null, isModalCandidacyVisible: false })
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            columns, status, items, searchParams, isSaveSearchModalVisible, isSearchPanelVisible, isSelectionEnable, isModalCandidacyVisible, missionId,
            iniItems, // eslint-disable-line no-unused-vars
        } = this.state
        const { param, researchCandidatesHandler, searchFiltersHandler } = this.props
        const hasResearchCandidateId = !!(new URLSearchParams(window.location.search)).get('researchCandidateId')

        return (
            <>
                <main className={styles.index}>
                    <form
                        className={styles['index-search-filters']}
                        onSubmit={ev => {
                            ev.preventDefault()
                            this.search()
                        }}
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label="Nom"
                                    disabled={status === Status.PENDING || hasResearchCandidateId}
                                    value={searchParams.lastname || ''}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, lastname: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label="Prénom"
                                    disabled={status === Status.PENDING || hasResearchCandidateId}
                                    value={searchParams.firstname || ''}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, firstname: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Division"
                                    options={param.divisions}
                                    selectedKey={searchParams.divisionId}
                                    disabled={status === Status.PENDING || hasResearchCandidateId}
                                    onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, divisionId: option.key } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Sous-famille(s)"
                                    options={param.subDivisions.filter(x => !searchParams.divisionId || x.divisionId === searchParams.divisionId)}
                                    selectedKey={searchParams.subDivisionIds}
                                    disabled={status === Status.PENDING || hasResearchCandidateId}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: { ...searchParams, subDivisionIds: this.getUpdatedList(searchParams.subDivisionIds, option) },
                                    })}
                                    multiSelect
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Metier(s)"
                                    options={param.metiers.filter(x => !searchParams.subDivisionIds?.length
                                        || searchParams.subDivisionIds.includes(x.subDivisionId))}
                                    selectedKey={searchParams.metierIds}
                                    disabled={status === Status.PENDING || hasResearchCandidateId}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: { ...searchParams, metierIds: this.getUpdatedList(searchParams.metierIds, option) },
                                    })}
                                    multiSelect
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Département(s)"
                                    options={param.departments}
                                    selectedKey={searchParams.departmentIds}
                                    disabled={status === Status.PENDING || hasResearchCandidateId}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: { ...searchParams, departmentIds: this.getUpdatedList(searchParams.departmentIds, option) },
                                    })}
                                    multiSelect
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Ville"
                                    options={param.cities}
                                    selectedKey={searchParams.cityId}
                                    disabled={status === Status.PENDING || hasResearchCandidateId}
                                    onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, cityId: option.key } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Périmètre"
                                    options={[
                                        { key: 16, text: '16 km' },
                                        { key: 40, text: '40 km' },
                                        { key: 100, text: '100 km' },
                                        { key: 150, text: '150 km' },
                                    ]}
                                    selectedKey={searchParams.perimeter}
                                    disabled={status === Status.PENDING || hasResearchCandidateId}
                                    onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, perimeter: option.key } })}
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label="Dans le CV"
                                    disabled={status === Status.PENDING || hasResearchCandidateId}
                                    value={searchParams.cvContent || ''}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, cvContent: newVal } })}
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    onRenderLabel={(props, defaultRender) => (
                                        <div style={{ display: 'flex' }}>
                                            {defaultRender(props)}
                                            <TooltipHost
                                                content={(
                                                    <ul>
                                                        <li>
                                                            <code>+</code>
                                                            {' '}
                                                            signifie opération ET
                                                        </li>
                                                        <li>
                                                            <code>|</code>
                                                            {' '}
                                                            signifie opération OU
                                                        </li>
                                                        <li>
                                                            <code>-</code>
                                                            {' '}
                                                            nie un seul mot
                                                        </li>
                                                        <li>
                                                            <code>"</code>
                                                            {' '}
                                                            enveloppe un certain nombre de lettres pour signifier une phrase pour la recherche
                                                        </li>
                                                    </ul>
                                                )}
                                                directionalHint={DirectionalHint.topCenter}
                                                delay={TooltipDelay.zero}
                                                styles={{
                                                    root: {
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',
                                                    },
                                                }}
                                            >
                                                <Icon
                                                    iconName="Info"
                                                    styles={{ root: { padding: '3px', cursor: 'help' } }}
                                                />
                                            </TooltipHost>
                                        </div>
                                    )}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <Label className="is-hidden-mobile">&nbsp;</Label>
                                <DefaultButton
                                    text="Rechercher"
                                    primary
                                    split
                                    disabled={status === Status.PENDING}
                                    onClick={() => this.submitInput.current.click()}
                                    iconProps={{ iconName: 'Search' }}
                                    menuProps={{
                                        items: [
                                            {
                                                key: 'Search',
                                                text: 'Rechercher',
                                                iconProps: { iconName: 'Search' },
                                                onClick: () => this.submitInput.current.click(),
                                            },
                                            {
                                                key: 'Reset',
                                                text: 'Réinitialiser',
                                                iconProps: { iconName: 'Filter' },
                                                onClick: () => this.setState({
                                                    /** @type {SearchParamsType} */
                                                    searchParams: {
                                                        firstname: null,
                                                        lastname: null,
                                                        divisionId: null,
                                                        subDivisionIds: [],
                                                        metierIds: [],
                                                        departmentIds: [],
                                                        cityId: null,
                                                        perimeter: null,
                                                        cvContent: null,
                                                        excludedCandidateIds: [],
                                                    },
                                                    items: [],
                                                    iniItems: [],
                                                }, () => this.setState({ columns: this.buildColumns() })),
                                            },
                                        ],
                                    }}
                                />
                            </Columns.Column>
                        </Columns>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                            tabIndex={-1}
                        />
                    </form>
                    <Card>
                        <ShimmeredDetailsList
                            items={items}
                            columns={columns}
                            selectionMode={isSelectionEnable ? SelectionMode.multiple : SelectionMode.none}
                            selection={this.selection}
                            selectionPreservedOnEmptyClick
                            onShouldVirtualize={() => true}
                            enableShimmer={status === Status.PENDING}
                            layoutMode={DetailsListLayoutMode.justified}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderRow={(props, defaultRender) => (
                                <Link
                                    to={{
                                        pathname: `/candidats/${props.item?.candidateId}`,
                                        // state: {
                                        //     candidate: iniItems.find(x => x.candidateId === props.item?.candidateId),
                                        // },
                                    }}
                                    onClick={ev => {
                                        // eslint-disable-next-line prefer-destructuring
                                        const target = /** @type {HTMLElement} */(ev.target)
                                        if (isSelectionEnable || target.closest('button') || target.nodeName.toLowerCase() === 'button')
                                            ev.preventDefault()
                                    }}
                                >
                                    {defaultRender({
                                        ...props,
                                        styles: {
                                            cell: { display: 'flex', alignSelf: 'center' },
                                            checkCell: { display: 'flex', alignSelf: 'center', height: '100%' },
                                        },
                                    })}
                                </Link>
                            )}
                        />
                        {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                            && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>}
                        {!items?.length && [Status.IDLE].includes(status)
                            && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Veuillez lancer une recherche</Text>}
                    </Card>
                </main>
                <SaveSearchModal
                    isVisible={isSaveSearchModalVisible}
                    setIsVisible={newVal => this.setState({ isSaveSearchModalVisible: newVal })}
                    handler={researchCandidatesHandler}
                    data={searchParams}
                />
                <SearchPanel
                    isVisible={isSearchPanelVisible}
                    setIsVisible={newVal => this.setState({ isSearchPanelVisible: newVal })}
                    handler={researchCandidatesHandler}
                    applyFilters={newVal => this.setState({ searchParams: newVal }, () => {
                        this.setState({ columns: this.buildColumns() })
                        this.submitInput.current.click()
                    })}
                />
                <Dialog
                    hidden={!isModalCandidacyVisible}
                    onDismiss={() => this.setState({ isModalCandidacyVisible: false })}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: 'Nouvelle(s) candidature(s)',
                        subText: 'A quelle mission voulez-vous ajouter ces candidats ?',
                    }}
                    modalProps={{
                        isBlocking: true,
                    }}
                    maxWidth="555px"
                >
                    <FilteredVirtualComboboxAjax
                        label="Mission"
                        minChar={1}
                        defaultValue=""
                        readOnly={false}
                        selectedKey={missionId}
                        onChange={(_ev, option) => this.setState({ missionId: option.key })}
                        functionName="searchMissions"
                        handler={searchFiltersHandler}
                        required
                    />
                    <DialogFooter>
                        <DefaultButton
                            onClick={() => this.setState({ isModalCandidacyVisible: false })}
                            text="Annuler"
                        />
                        <PrimaryButton
                            onClick={() => this.addCandidacies()}
                            text="Oui"
                        />
                    </DialogFooter>
                </Dialog>
            </>
        )
    }
}

IndexCandidates.prototype.onColumnClick = onColumnClick
IndexCandidates.prototype.flattenObj = flattenObj
IndexCandidates.prototype.handleRenderColumn = handleRenderColumn
IndexCandidates.prototype.getUpdatedList = getUpdatedList
