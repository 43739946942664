import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, PrimaryButton, TextField,
} from '@fluentui/react'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import Status from 'types/status'
import Param from 'requests/objects/param'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import HuntsHandler from 'requests/handlers/huntsHandler'
import Candidacy, { ErrorCandidacy } from 'requests/objects/candidacy'
import Hunt from 'requests/objects/hunt'
import EBoardColumn from 'types/missions/enums/boardColumn'
import EBoardCardType from 'types/missions/enums/boardCardType'

/**
 * Coding card
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {Param} props.param param
 * @param {(item: Hunt | Candidacy) => void} props.onUpdate onUpdate
 * @param {boolean=} props.isWithRejection isWithRejection
 * @param {((id: number, newColumnId: EBoardColumn, type: EBoardCardType) => Promise<void>)=} props.onBoardItemDrop onBoardItemDrop
 * @returns {JSX.Element} Returns
 */
export default function BoardReCodingModal({
    item: itemProps, handler, isVisible, setIsVisible, param, onUpdate, isWithRejection = false, onBoardItemDrop = () => null,
}) {
    const [item, setItem] = useState(itemProps || new Candidacy())
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorCandidacy())

    /** @type {React.MutableRefObject<RequestApi<Candidacy | Hunt>>} */
    const handlerUpdateBoardItem = useRef(null)

    const update = useCallback(async () => {
        try {
            const id = /** @type {Candidacy} */(itemProps).candidacyId || /** @type {Hunt} */(itemProps).huntId
            setStatus(Status.PENDING)
            handlerUpdateBoardItem.current = handler.patchReCoding(
                id,
                /** @type {any} */({ ...item, isRejected: isWithRejection }),
            )
            const res = await handlerUpdateBoardItem.current.fetch()
            onUpdate(res)
            setStatus(Status.RESOLVED)
            setIsVisible(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorCandidacy>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [itemProps, handler, item, onUpdate, setIsVisible, isWithRejection])

    // Update local item on props change
    useEffect(() => {
        if (isVisible)
            setItem(itemProps || new Candidacy())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerUpdateBoardItem?.current?.cancel()
    }, [])

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Recoder le candidat',
                subText: isWithRejection
                    ? 'Recoder manuellement les informations du candidat et rejeter la candidature'
                    : 'Recoder manuellement les informations du candidat',
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="450px"
            minWidth="450px"
        >
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    update()
                }}
            >
                <FilteredVirtualCombobox
                    label="Famille de poste"
                    options={param.divisions}
                    selectedKey={item.candidate.divisionId}
                    onChange={(_ev, option) => setItem(prev => ({ ...prev, candidate: { ...prev.candidate, divisionId: option.key } }))}
                    errorMessage={errorField['candidate.divisionId']}
                    required
                />
                <FilteredVirtualCombobox
                    label="Sous-famille"
                    options={param.subDivisions.filter(x => !item.candidate.divisionId || x.divisionId === item.candidate.divisionId)}
                    selectedKey={item.candidate.subDivisionId}
                    onChange={(_ev, option) => setItem(prev => ({ ...prev, candidate: { ...prev.candidate, subDivisionId: option.key } }))}
                    errorMessage={errorField['candidate.subDivisionId']}
                    required
                />
                <FilteredVirtualCombobox
                    label="Metier"
                    options={param.metiers.filter(x => !item.candidate.subDivisionId || item.candidate.subDivisionId === x.subDivisionId)}
                    selectedKey={item.candidate.metier1Id}
                    onChange={(_ev, option) => setItem(prev => ({ ...prev, candidate: { ...prev.candidate, metier1Id: option.key } }))}
                    errorMessage={errorField['candidate.metier1Id']}
                    required
                />
                {isWithRejection && (
                    <TextField
                        label="Commentaire"
                        value={item.comment || ''}
                        readOnly={status === Status.PENDING}
                        borderless={status === Status.PENDING}
                        onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, comment: newVal?.toUpperCase() }))}
                        errorMessage={errorField.comment}
                        multiline
                        autoAdjustHeight
                    />
                )}
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            setIsVisible(false)
                            if (!isWithRejection)
                                onBoardItemDrop(
                                    /** @type {Candidacy} */(itemProps).candidacyId || /** @type {Hunt} */(itemProps).huntId,
                                    EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT,
                                    itemProps.type,
                                )
                        }}
                        text="Annuler"
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        type="submit"
                        text="Enregistrer"
                        disabled={status === Status.PENDING}
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
