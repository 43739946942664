/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import User from 'requests/objects/user'

/**
 * ResearchClient Object
 */
export default class ResearchClient extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.researchClientId researchClientId of the ResearchClient
     * @param {string=} data.name name
     * @param {object=} data.request request
     * @param {number=} data.createdByUserId createdByUserId
     * @param {object=} data.createdByUser createdByUser
     */
    constructor({
        researchClientId = 0,
        name = '',
        request = {},
        createdByUserId,
        createdByUser,
        ...data
    } = {}) {
        super(data)
        this.researchClientId = researchClientId
        this.name = name
        this.request = request
        this.createdByUserId = createdByUserId
        this.createdByUser = createdByUser ? new User(createdByUser) : undefined
    }
}

/**
 * ResearchClient Object used to bind error message
 */
export class ErrorResearchClient extends ErrorBase {
    constructor() {
        super()
        this.researchClientId = ''
        this.name = ''
        this.request = ''
        this.createdByUserId = ''
        this.createdByUser = ''
    }
}
