import Candidate, { ErrorCandidate } from 'requests/objects/candidate'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import FileCv from 'requests/objects/fileCv'

/**
 * CandidatesHandler
 * @augments {ApiHandler<Candidate, ErrorCandidate>}
 */
export default class CandidatesHandler extends ApiHandler {
    constructor() {
        super({ type: Candidate, errorType: ErrorCandidate, key: 'candidates' })
    }

    /**
     * Get file
     * @param {number} candidateId Id
     * @param {number} fileId Id
     * @returns {RequestApi<Blob>} Request
     */
    getFileCv(candidateId, fileId) {
        const request = this.initFetchRequest({
            url: [candidateId, 'filecvs', fileId], method: 'GET', responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upload file
     * @param {number} candidateId Id
     * @param {globalThis.File} file file
     * @returns {RequestApi<FileCv>} Returns
     */
    uploadFileCv(candidateId = undefined, file = undefined) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: [candidateId, 'filecvs'],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => new FileCv(/** @type {any} */(res.data)?.filecvs))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Remove file
     * @param {number} candidateId Id
     * @param {number} fieldId Ids
     * @returns {RequestApi<FileCv>} Request
     */
    removeFileCv(candidateId, fieldId) {
        const request = this.initFetchRequest({
            url: [candidateId, 'filecvs', fieldId], method: 'DELETE',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Import parameters from a candidacy
     * @param {number} candidateId Id
     * @param {number} candidacyId Id
     * @returns {RequestApi<Candidate>} Request
     */
    codingCandidacy(candidateId, candidacyId) {
        const request = this.initFetchRequest({ url: [candidateId, 'coding', candidacyId], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
