import React, {
    useCallback, useRef, useState,
} from 'react'
import { IconButton } from '@fluentui/react'
import HandleBlob from 'helpers/methods/blob'
import EBoardColumn from 'types/missions/enums/boardColumn'
import MissionsHandler from 'requests/handlers/missionsHandler'
import Status from 'types/status'
import Time from 'helpers/methods/time'

/**
 * Candidacy card
 * @param {object} props Props
 * @param {MissionsHandler} props.missionsHandler missionsHandler
 * @param {number} props.missionId missionId
 * @param {EBoardColumn} props.columnId columnId
 * @param {boolean=} props.disabled disabled
 * @returns {JSX.Element} Returns
 */
export default function BoardExportButton({
    missionId, missionsHandler, columnId, disabled,
}) {
    const [status, setStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const boardItemHandlerGetExport = useRef(null)

    const onExport = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            boardItemHandlerGetExport.current = missionsHandler.getExportedItems(missionId, columnId)
            const blob = await boardItemHandlerGetExport.current.fetch()
            HandleBlob.download(blob, `Export A Appeler - ${Time(new Date()).getCleanDate({
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            })}.xlsx`)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
        }
    }, [columnId, missionId, missionsHandler])

    return (
        <IconButton
            iconProps={{ iconName: 'ExcelDocument' }}
            onClick={onExport}
            disabled={status === Status.PENDING || disabled}
            style={{
                cursor: status === Status.PENDING ? 'progress' : undefined,
                marginLeft: 'auto',
                marginTop: '-5px',
                marginBottom: '-5px',
            }}
            title="Télécharger les candidatures/chasses au format Excel"
        />
    )
}
