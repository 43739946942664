import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    Dialog,
    DialogFooter,
    DialogType,
    IconButton, PrimaryButton, SelectionMode, ShimmeredDetailsList, Text,
} from '@fluentui/react'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import HuntsHandler from 'requests/handlers/huntsHandler'
import Candidacy from 'requests/objects/candidacy'
import Hunt from 'requests/objects/hunt'
import File from 'requests/objects/file'
import HandleBlob from 'helpers/methods/blob'
import styles from 'styles/components/pages/missions/pivotCandidacy/boardDetailPanelFiles.module.scss'

/**
 * Candidacy card
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {(item: Candidacy | Hunt) => void} props.onUpdate onUpdate
 * @returns {JSX.Element} Returns
 */
export default function BoardDetailPanelFiles({
    item,
    handler,
    onUpdate,
}) {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [focusedFileItem, setFocusedFileItem] = useState(new File())
    const [status, setStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<HTMLInputElement>} Input file ref */
    const inputFileRef = useRef(null)

    /** @type {React.MutableRefObject<RequestApi<Blob>>} */
    const boardItemHandlerGetFiles = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<File>>} */
    const boardItemHandlerUploadFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<File>>} */
    const boardItemHandlerRemoveFiles = useRef(null)

    const download = useCallback(
        /**
         * @param {File} file file
         */
        async file => {
            try {
                setStatus(Status.PENDING)
                const itemId = (/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId)
                boardItemHandlerGetFiles.current = handler.getFile(itemId, file.fileId)
                const blob = await boardItemHandlerGetFiles.current.fetch()
                HandleBlob.download(blob, file.name)
                setStatus(Status.RESOLVED)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        setStatus(Status.REJECTED)
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [handler, item],
    )

    const upload = useCallback(
        /**
         * @param {globalThis.File} file file
         */
        async file => {
            try {
                setStatus(Status.PENDING)
                const itemId = (/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId)
                boardItemHandlerUploadFile.current = handler.uploadFile(itemId, file)
                const newFile = await boardItemHandlerUploadFile.current.fetch()
                onUpdate({ ...item, files: [...item.files, newFile] })
                setStatus(Status.RESOLVED)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        setStatus(Status.REJECTED)
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [handler, item, onUpdate],
    )

    const remove = useCallback(async () => {
        if (!focusedFileItem)
            return
        try {
            setStatus(Status.PENDING)
            const itemId = (/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId)
            boardItemHandlerRemoveFiles.current = handler.removeFile(itemId, focusedFileItem.fileId)
            await boardItemHandlerRemoveFiles.current.fetch()
            onUpdate({ ...item, files: item.files.filter(x => x.fileId !== focusedFileItem.fileId) })
            setStatus(Status.RESOLVED)
            setFocusedFileItem(new File())
            setIsModalVisible(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [focusedFileItem, handler, item, onUpdate])

    const [columns] = useState([
        {
            key: 'name',
            name: 'Nom',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
        },
        {
            key: 'action',
            name: '',
            fieldName: 'action',
            minWidth: 25,
            maxWidth: 25,
            isRowHeader: true,
            isResizable: true,
            /**
             * @param {File} fileItem fileItem
             * @returns {JSX.Element} Returns
             */
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: fileItem => (
                <div style={{ paddingTop: '6px', margin: '-11px 0' }}>
                    <IconButton
                        iconProps={{ iconName: 'Download' }}
                        onClick={() => download(fileItem)}
                        title="Télécharger"
                    />
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        onClick={() => {
                            setIsModalVisible(true)
                            setFocusedFileItem(fileItem)
                        }}
                        title="Supprimer"
                    />
                </div>
            ),
        },
    ])

    // Cancel request on dismount
    useEffect(() => () => {
        boardItemHandlerGetFiles?.current?.cancel()
        boardItemHandlerUploadFile?.current?.cancel()
        boardItemHandlerRemoveFiles?.current?.cancel()
    }, [])

    return (
        <>
            <ShimmeredDetailsList
                items={item.files ?? []}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
            />
            {!item.files?.length
                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun fichier trouvé</Text>}
            <br />
            <input
                type="file"
                ref={inputFileRef}
                onChange={ev => {
                    if (ev.target?.files?.[0])
                        upload(ev.target?.files?.[0])
                }}
                style={{ display: 'none' }}
            />
            <PrimaryButton
                iconProps={{ iconName: 'Upload' }}
                text="Téléverser"
                onClick={() => {
                    inputFileRef.current.value = null
                    inputFileRef.current.click()
                }}
                disabled={status === Status.PENDING}
                className={styles['board-detail-panel-files-button']}
            />
            <Dialog
                hidden={!isModalVisible}
                onDismiss={() => setIsModalVisible(false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Supprimer le fichier',
                    subText: 'Êtes-vous certain de vouloir supprimer le fichier ? Cette action est définitive.',
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
            >
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsModalVisible(false)}
                        text="Annuler"
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={() => remove()}
                        text="Oui"
                        disabled={status === Status.PENDING}
                    />
                </DialogFooter>
            </Dialog>
        </>
    )
}
