import React, { PureComponent } from 'react'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { NEW_PATH } from 'types/others'
import Hunt, { ErrorHunt } from 'requests/objects/hunt'
import CvInput from 'components/pages/candidats/[id]/cvInput'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import { Text, TextField } from '@fluentui/react'
import setPageTitle from 'helpers/methods/pageTitle'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdHunts extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Hunt} Element find from API */
            item: new Hunt(),
            /** @type {ErrorHunt} Errors */
            errorField: new ErrorHunt(),
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { match, setCommand } = this.props

        if (match?.params?.huntId !== prevProps?.match?.params?.huntId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
            }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.huntsHandlerGetById?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state
        const mainTitle = `Chasse ${match?.params?.huntId ? item.mission?.ref || '' : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie candidat', key: 'candidat' },
            { text: 'Tous les candidats', key: 'all-candidat', href: '/candidats' },
            {
                // eslint-disable-next-line max-len
                text: `Candidat ${match?.params?.candidateId ? [item.candidate?.lastname, item.candidate?.firstname].filter(x => x).join(' ').trim() || '' : 'Nouveau'}`,
                key: 'one-candidat',
                href: `/candidats/${match?.params?.candidateId}`,
            },
            { text: mainTitle, key: 'one-candidature', isCurrentItem: true },
        ])
        setTitle('Candidats')
        setPageTitle(mainTitle)
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, huntsHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()

        // If there is an id in URL, get element by id
        if (match?.params?.huntId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.hunt) // If object came from history push with a create
                    this.setState({
                        item: location.state?.hunt,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        this.setupBreadcrumb()
                    })
                else
                    try {
                        this.huntsHandlerGetById = huntsHandler.getById(match?.params?.huntId)
                        const hunt = await this.huntsHandlerGetById.fetch()
                        this.setState({
                            item: hunt,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            this.setupBreadcrumb()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new Hunt(),
                    status: Status.IDLE,
                })
            })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, errorField,
        } = this.state
        const { huntsHandler } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                }}
            >
                <Card
                    title="Champs"
                    iconName="PageData"
                >
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Nom candidat"
                                value={`${item.candidate?.firstname || ''} ${item.candidate?.lastname || ''}`}
                                readOnly
                                borderless
                                errorMessage={errorField.candidate?.firstname}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Société à chasser"
                                value={item.company}
                                readOnly
                                borderless
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Reference mission"
                                readOnly
                                borderless
                                value={item.mission?.ref || ''}
                                // eslint-disable-next-line react/no-unstable-nested-components
                                onRenderInput={(props, defaultRender) => (item.missionId
                                    ? (
                                        <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                            <Link to={`/missions/${item?.missionId}`}>
                                                {item.mission?.ref}
                                            </Link>
                                        </Text>
                                    )
                                    : defaultRender(props))}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <CvInput
                                isReadOnly
                                id={item.huntId}
                                handler={huntsHandler}
                                updateItem={() => null}
                                item={item}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Provenance"
                                value={item.huntProvenance?.name}
                                readOnly
                                borderless
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Statut candidature"
                                value={(() => {
                                    if (item.isInterviewCancel)
                                        return 'Entretien annulé'
                                    if (item.isRejected)
                                        return 'Rejeté'
                                    return item.boardColumn?.name
                                })()}
                                readOnly
                                borderless
                            />
                        </Columns.Column>
                        <Columns.Column size="half">
                            <TextField
                                label="Commentaire"
                                value={item.comment}
                                readOnly
                                borderless
                                multiline
                                autoAdjustHeight
                            />
                        </Columns.Column>
                    </Columns>
                </Card>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="submit"
                    style={{ display: 'none' }}
                    ref={this.submitInput}
                    tabIndex={-1}
                />
            </form>
        )
    }
}

IdHunts.prototype.getUpdatedList = getUpdatedList
IdHunts.prototype.isValidDate = isValidDate
