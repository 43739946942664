import React from 'react'
import { DefaultButton, Text } from '@fluentui/react'
import Mission from 'requests/objects/mission'
import { Link } from 'react-router-dom'
import Time from 'helpers/methods/time'
import styles from 'styles/components/pages/carte-missions/missionsCard.module.scss'

/**
 * @param {object} props Props
 * @param {Mission} props.item Mission
 * @param {() => void} props.onOpen onOpen
 * @returns {JSX.Element} Element
 */
export default function MissionCard({ item, onOpen }) {
    return (
        <div className={styles['missions-card']}>
            <Text>
                Client :
                {' '}
                <Link to={`/clients/${item.clientId}`}>
                    {item.client?.name}
                </Link>
            </Text>
            <br />
            <Text>
                Poste :
                {' '}
                <Link to={`/missions/${item.missionId}`}>
                    {item.name}
                </Link>
            </Text>
            <br />
            <Text>
                Mission :
                {' '}
                <Link to={`/missions/${item.missionId}`}>
                    {item.ref}
                </Link>
            </Text>
            <br />
            <Text>
                Dernière modification :
                {' '}
                {item.createdAt
                    ? Time(item.createdAt).getCleanDate({
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })
                    : ''}
            </Text>
            <br />
            <DefaultButton
                text="Voir sur la carte"
                iconProps={{ iconName: 'RedEye' }}
                onClick={onOpen}
                styles={{ root: { margin: '10px 0 5px' } }}
            />
        </div>
    )
}
