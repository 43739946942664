/* eslint-disable max-classes-per-file */

import { ErrorBase } from 'requests/objects/_base'

/**
 * Phone Object
 */
export default class Phone {
    /**
     * @param {object} data data
     * @param {number[]=} data.ids id of the Phone
     * @param {string=} data.type type
     * @param {string=} data.name name
     * @param {string=} data.phone phone
     */
    constructor({
        ids = [],
        type,
        name,
        phone,
    } = {}) {
        this.ids = ids
        this.type = type
        this.name = name
        this.phone = phone
    }
}

/**
 * Phone Object used to bind error message
 */
export class ErrorPhone extends ErrorBase { }
