import React, {
    useCallback, useEffect, useRef,
} from 'react'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import HandleBlob from 'helpers/methods/blob'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FileInput from 'components/inputs/fileInput'
import HuntsHandler from 'requests/handlers/huntsHandler'

const FILE_NAME = 'CV TYPE.doc'

/**
 * Files list
 * @param {object} props Props
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {HuntsHandler} props.handler HuntsHandler
 * @returns {JSX.Element} Returns
 */
export default function BoardDetailPanelCvTemplateInput({
    isReadOnly = false,
    handler,
}) {
    /** @type {React.MutableRefObject<RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)

    const download = useCallback(async () => {
        try {
            handlerGetFile.current = handler.getFileTemplate()
            const blob = await handlerGetFile.current.fetch()
            HandleBlob.download(blob, FILE_NAME)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [handler])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerGetFile?.current?.cancel()
    }, [])

    return (
        <FileInput
            label="CV template"
            isReadOnly={isReadOnly}
            fileName={FILE_NAME}
            tooltipContent=""
            onDownload={() => download()}
        />
    )
}
