import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton,
} from '@fluentui/react'
import Candidacy from 'requests/objects/candidacy'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Status from 'types/status'
import EBoardCardType from 'types/missions/enums/boardCardType'
import HuntsHandler from 'requests/handlers/huntsHandler'
import Hunt from 'requests/objects/hunt'
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'

/**
 * Candidacy card
 * @param {object} props Props
 * @param {Candidacy | Hunt} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {(id: number, type: EBoardCardType) => void} props.onDelete onDelete
 * @returns {JSX.Element} Returns
 */
export default function BoardDeleteModal({
    item, handler, isVisible, setIsVisible, onDelete,
}) {
    const [status, setStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<RequestApi<Candidacy | Hunt>>} */
    const handlerRemoveBoardItem = useRef(null)

    const remove = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            const id = (/** @type {Candidacy} */(item).candidacyId || /** @type {Hunt} */(item).huntId)
            handlerRemoveBoardItem.current = handler.removeById(id)
            await handlerRemoveBoardItem.current.fetch()
            store.dispatch(setMessageBar({ isDisplayed: false }))
            setStatus(Status.RESOLVED)
            setIsVisible(false)
            onDelete(id, item.type)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [item, handler, setIsVisible, onDelete])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerRemoveBoardItem?.current?.cancel()
    }, [])

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Supprimer la candidature',
                subText: 'Êtes-vous certain de vouloir supprimer la candidature ? Cette action est définitive.',
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
        >
            <DialogFooter>
                <DefaultButton
                    onClick={() => setIsVisible(false)}
                    text="Annuler"
                    disabled={status === Status.PENDING}
                />
                <PrimaryButton
                    onClick={() => remove()}
                    text="Oui"
                    disabled={status === Status.PENDING}
                />
            </DialogFooter>
        </Dialog>
    )
}
