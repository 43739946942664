import ResearchClient, { ErrorResearchClient } from 'requests/objects/researchClient'
// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler from 'requests/apiHandler'

/**
 * ResearchClientsHandler
 * @augments {ApiHandler<ResearchClient, ErrorResearchClient>}
 */
export default class ResearchClientsHandler extends ApiHandler {
    constructor() {
        super({ type: ResearchClient, errorType: ErrorResearchClient, key: 'research-clients' })
    }
}
