import ResearchCandidate, { ErrorResearchCandidate } from 'requests/objects/researchCandidate'
// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'

/**
 * ResearchCandidatesHandler
 * @augments {ApiHandler<ResearchCandidate, ErrorResearchCandidate>}
 */
export default class ResearchCandidatesHandler extends ApiHandler {
    constructor() {
        super({ type: ResearchCandidate, errorType: ErrorResearchCandidate, key: 'research-candidates' })
    }

    /**
     * Exclude candidate
     * @param {number} researchCandidateId researchCandidateId
     * @param {number} candidateId candidateId
     * @returns {RequestApi<ResearchCandidate>} Request
     */
    exclude(researchCandidateId, candidateId) {
        const request = this.initFetchRequest({ url: [researchCandidateId, 'exclude-candidates', candidateId], method: 'PATCH' })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
