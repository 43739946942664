import Types from 'types/types'
import { Column } from 'types/column' // eslint-disable-line

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const userColumns = [
    {
        fieldName: 'firstname',
        name: 'Prénom',
        type: Types.STRING,
    },
    {
        fieldName: 'lastname',
        name: 'Nom',
        type: Types.STRING,
    },
    {
        fieldName: 'code',
        name: 'Code',
        type: Types.STRING,
    },
    {
        fieldName: 'desk',
        name: 'Bureau',
        type: Types.STRING,
    },
    {
        fieldName: 'hasLeft',
        name: 'Out',
        type: Types.BOOLEAN,
    },
    {
        fieldName: 'profile.name',
        name: 'Profil',
        type: Types.STRING,
    },
]

export default userColumns
