/**
 * @enum {number}
 */
const EBoardColumn = {
    RECEPTION_RETURN_ANNOUCEMENT: 1,
    RETURN_ANNOUCEMENT: 2,
    TO_CALL: 3,
    CALLED: 4,
    CANDIDIDATE_INTERVIEW: 5,
    SENDING_CV: 6,
    CLIENT_CANDIDATE_INTERVIEW: 7,
    PLACED: 8,
}

export default EBoardColumn
