/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * Interview Object
 */
export default class Interview extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.interviewId interviewId of the Interview
     * @param {number=} data.candidacyId candidacyId
     * @param {number=} data.huntId huntId
     * @param {string=} data.location location
     * @param {string=} data.formation formation
     * @param {string=} data.sleeping sleeping
     * @param {string=} data.availability availability
     * @param {string=} data.knowledge knowledge
     * @param {string=} data.ability ability
     * @param {string=} data.motivation motivation
     * @param {string=} data.track track
     * @param {string=} data.currentSalary currentSalary
     * @param {string=} data.desiredSalary desiredSalary
     * @param {string=} data.aptitudePros aptitudePros
     * @param {string=} data.aptitudeCons aptitudeCons
     * @param {string=} data.professionnalSkillPros professionnalSkillPros
     * @param {string=} data.professionnalSkillCons professionnalSkillCons
     * @param {boolean=} data.isValidated isValidated
     */
    constructor({
        interviewId = 0,
        candidacyId,
        huntId,
        location = '',
        formation = '',
        sleeping = '',
        availability = '',
        knowledge = '',
        ability = '',
        motivation = '',
        track = '',
        currentSalary = '',
        desiredSalary = '',
        aptitudePros = '',
        aptitudeCons = '',
        professionnalSkillPros = '',
        professionnalSkillCons = '',
        isValidated = false,
        ...data
    } = {}) {
        super(data)
        this.interviewId = interviewId
        this.candidacyId = candidacyId
        this.huntId = huntId
        this.location = location
        this.formation = formation
        this.sleeping = sleeping
        this.availability = availability
        this.knowledge = knowledge
        this.ability = ability
        this.motivation = motivation
        this.track = track
        this.currentSalary = currentSalary
        this.desiredSalary = desiredSalary
        this.aptitudePros = aptitudePros
        this.aptitudeCons = aptitudeCons
        this.professionnalSkillPros = professionnalSkillPros
        this.professionnalSkillCons = professionnalSkillCons
        this.isValidated = isValidated
    }
}

/**
 * Interview Object used to bind error message
 */
export class ErrorInterview extends ErrorBase {
    constructor() {
        super()
        this.interviewId = ''
        this.location = ''
        this.formation = ''
        this.sleeping = ''
        this.availability = ''
        this.knowledge = ''
        this.ability = ''
        this.motivation = ''
        this.track = ''
        this.currentSalary = ''
        this.desiredSalary = ''
        this.aptitudePros = ''
        this.aptitudeCons = ''
        this.professionnalSkillPros = ''
        this.professionnalSkillCons = ''
    }
}
