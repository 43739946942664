import React, { PureComponent } from 'react'
import {
    Label, DefaultButton, TextField, Text, List,
} from '@fluentui/react'
import history from 'helpers/history'
import {
    getUpdatedList, isValidDate,
} from 'helpers/methods/common'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Mission from 'requests/objects/mission'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import Card from 'components/containers/card'
import styles from 'styles/pages/carte-missions/index.module.scss'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import ESearchMapType from 'types/missions/enums/searchMapType'
import Time from 'helpers/methods/time'
import MissionMap from 'components/pages/carte-missions/missionsMap'
import MissionMapLegend from 'components/pages/carte-missions/missionsMapLegend'
import MissionCard from 'components/pages/carte-missions/missionsCard'
import Loader from 'components/visuals/loader'
import EDot from 'types/missions/enums/dot'

/** @debug {AppProps} */

/**
 * @typedef {object} SearchParamsType
 * @property {ESearchMapType} type type
 * @property {string} placedDate placedDate
 * @property {number} divisionId divisionId
 * @property {number[]} subDivisionIds subDivisionIds
 * @property {number[]} metierIds metierIds
 * @property {number[]} departmentIds departmentIds
 * @property {number} minLatitude minLatitude
 * @property {number} maxLatitude maxLatitude
 * @property {number} minLongitude minLongitude
 * @property {number} maxLongitude maxLongitude
 */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexMapMissions extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Mission[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                type: ESearchMapType.ALL,
                placedDate: null,
                divisionId: null,
                subDivisionIds: [],
                metierIds: [],
                departmentIds: [],
                minLatitude: null,
                maxLatitude: null,
                minLongitude: null,
                maxLongitude: null,
            },
        }

        this.submitInput = React.createRef()
        /** @type {React.MutableRefObject<import('components/pages/carte-missions/missionsMap').MissionMapRef>} */
        this.missionMapRef = React.createRef()

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        // this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }

        // this.init()

        // this.setupBreadcrumb()

        // this.setupCommandBar()

        // this.setState({
        //     columns: this.buildColumns(),
        // })

        if (prevState.searchParams?.type !== searchParams?.type && searchParams?.type !== ESearchMapType.PLACED)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                searchParams: {
                    ...truePrevState.searchParams,
                    placedDate: null,
                },
            }))

        if (prevState.searchParams?.divisionId && prevState.searchParams?.divisionId !== searchParams?.divisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                searchParams: {
                    ...truePrevState.searchParams,
                    dateplaced: [],
                    metierIds: [],
                },
            }))

        if ((prevState.searchParams?.subDivisionIds.length && prevState.searchParams?.subDivisionIds.length !== searchParams?.subDivisionIds.length)
            || !prevState.searchParams?.subDivisionIds.every((v, i) => v === searchParams?.subDivisionIds[i])
        )
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                searchParams: {
                    ...truePrevState.searchParams,
                    metierIds: [],
                },
            }))
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.missionsHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, setTitle } = this.props

        setBreadcrumb([
            { text: 'Saisie mission', key: 'mission' },
            { text: 'Carte des missions', key: 'all-missions', isCurrentItem: true },
        ])
        setTitle('Missions')
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props

        setCommand([])
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    type: queryParams.get('type') || ESearchMapType.ALL,
                    placedDate: queryParams.get('placedDate') || null,
                    divisionId: queryParams.get('divisionId') ? +queryParams.get('divisionId') : null,
                    subDivisionIds: queryParams.getAll('subDivisionIds') ? queryParams.getAll('subDivisionIds').map(x => +x) : [],
                    metierIds: queryParams.getAll('metierIds') ? queryParams.getAll('metierIds').map(x => +x) : [],
                    departmentIds: queryParams.getAll('departmentIds') ? queryParams.getAll('departmentIds').map(x => +x) : [],
                    minLatitude: queryParams.get('minLatitude') ? +queryParams.get('minLatitude') : null,
                    maxLatitude: queryParams.get('maxLatitude') ? +queryParams.get('maxLatitude') : null,
                    minLongitude: queryParams.get('minLongitude') ? +queryParams.get('minLongitude') : null,
                    maxLongitude: queryParams.get('maxLongitude') ? +queryParams.get('maxLongitude') : null,
                },
            }, () => {
                this.syncSearchParamsInHistory()
                this.search()
            })
        }
    }

    /**
     * Search elements
     */
    search() {
        this.missionMapRef.current?.closeAll?.()
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { missionsHandler, setBreadcrumb } = this.props
            const { searchParams } = this.state
            setBreadcrumb([
                { text: 'Saisie mission', key: 'mission' },
                { text: 'Carte des missions', key: 'all-missions', isCurrentItem: true },
            ])
            try {
                this.missionsHandlerGetAll = missionsHandler.getAllMap(searchParams)
                const missions = await this.missionsHandlerGetAll.fetch()
                this.setState({
                    // Remove stuff without coordinates
                    items: missions.filter(item => item.latitude && item.longitude),
                    status: Status.RESOLVED,
                })
                setBreadcrumb([
                    { text: 'Saisie mission', key: 'mission' },
                    { text: `Carte des missions (${missions.length || 0})`, key: 'all-missions', isCurrentItem: true },
                ])
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, items, searchParams,
        } = this.state
        const { param } = this.props

        return (
            <main className={styles.index}>
                <form
                    className={styles['index-search-filters']}
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.search()
                    }}
                >
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Type"
                                options={[
                                    { key: ESearchMapType.ALL, text: 'Toutes' },
                                    { key: ESearchMapType.OPEN, text: 'En cours' },
                                    { key: ESearchMapType.PLACED, text: 'Interrompues ou placées' },
                                ]}
                                selectedKey={searchParams.type}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, type: option.key } })}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Division"
                                options={param.divisions}
                                selectedKey={searchParams.divisionId}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, divisionId: option.key } })}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Sous-famille(s)"
                                options={param.subDivisions.filter(x => !searchParams.divisionId || x.divisionId === searchParams.divisionId)}
                                selectedKey={searchParams.subDivisionIds}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({
                                    searchParams: { ...searchParams, subDivisionIds: this.getUpdatedList(searchParams.subDivisionIds, option) },
                                })}
                                multiSelect
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Metier(s)"
                                options={param.metiers.filter(x => !searchParams.subDivisionIds?.length
                                    || searchParams.subDivisionIds.includes(x.subDivisionId))}
                                selectedKey={searchParams.metierIds}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({
                                    searchParams: { ...searchParams, metierIds: this.getUpdatedList(searchParams.metierIds, option) },
                                })}
                                multiSelect
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <FilteredVirtualCombobox
                                label="Département(s)"
                                options={param.departments}
                                selectedKey={searchParams.departmentIds}
                                disabled={status === Status.PENDING}
                                onChange={(_ev, option) => this.setState({
                                    searchParams: { ...searchParams, departmentIds: this.getUpdatedList(searchParams.departmentIds, option) },
                                })}
                                multiSelect
                            />
                        </Columns.Column>
                        {searchParams.type === ESearchMapType.PLACED
                            && (
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Créé après le"
                                        value={searchParams.placedDate && this.isValidDate(new Date(searchParams.placedDate))
                                            ? Time(new Date(searchParams.placedDate)).getLocaleDateString()
                                            : ''}
                                        readOnly={status === Status.PENDING}
                                        borderless={status === Status.PENDING}
                                        onChange={(ev, newVal) => this.setState({
                                            searchParams: { ...searchParams, placedDate: this.isValidDate(new Date(newVal)) ? new Date(newVal) : '' },
                                        })}
                                        type="date"
                                    />
                                </Columns.Column>
                            )}
                        <Columns.Column size="one-quarter">
                            <Label className="is-hidden-mobile">&nbsp;</Label>
                            <DefaultButton
                                text="Rechercher"
                                primary
                                split
                                disabled={status === Status.PENDING}
                                onClick={() => this.submitInput.current.click()}
                                iconProps={{ iconName: 'Search' }}
                                menuProps={{
                                    items: [
                                        {
                                            key: 'Search',
                                            text: 'Rechercher',
                                            iconProps: { iconName: 'Search' },
                                            onClick: () => this.submitInput.current.click(),
                                        },
                                        {
                                            key: 'Reset',
                                            text: 'Réinitialiser',
                                            iconProps: { iconName: 'Filter' },
                                            onClick: () => this.setState({
                                                /** @type {SearchParamsType} */
                                                searchParams: {
                                                    type: ESearchMapType.ALL,
                                                    placedDate: null,
                                                    divisionId: null,
                                                    subDivisionIds: [],
                                                    metierIds: [],
                                                    departmentIds: [],
                                                    minLatitude: null,
                                                    maxLatitude: null,
                                                    minLongitude: null,
                                                    maxLongitude: null,
                                                },
                                                items: [],
                                            }),
                                        },
                                    ],
                                }}
                            />
                        </Columns.Column>
                    </Columns>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
                <Columns>
                    <Columns.Column
                        size="one-quarter"
                    >
                        <Card
                            title="Les missions"
                            iconName="FavoriteStarFill"
                            style={{
                                minHeight: 'calc(100% - 34px)', height: '66vh', overflow: 'auto', paddingBottom: '0',
                            }}
                        >
                            {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                                && <Text styles={{ root: { fontStyle: 'italic' } }}>Aucun résultat trouvé</Text>}
                            {!items?.length && [Status.IDLE].includes(status)
                                && <Text styles={{ root: { fontStyle: 'italic' } }}>Veuillez lancer une recherche</Text>}
                            {!items?.length && [Status.PENDING].includes(status)
                                && <Loader />}
                            {!!items?.length && [Status.RESOLVED].includes(status)
                                && (
                                    <List
                                        items={items}
                                        renderedWindowsAhead={5}
                                        renderedWindowsBehind={5}
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        onRenderCell={(item, index) => (
                                            <MissionCard
                                                key={`${item.missionId}_${index}`}
                                                item={item}
                                                onOpen={() => this.missionMapRef.current?.open(index)}
                                            />
                                        )}
                                    />
                                )}
                        </Card>
                    </Columns.Column>
                    <Columns.Column size="three-quarters">
                        <Card>
                            <MissionMap
                                items={items}
                                setBounds={() => null}
                                // Not used anymore, but maybe later again
                                // setBounds={bounds => this.setState(truePrevState => ({
                                //     searchParams: {
                                //         ...truePrevState.searchParams,
                                //         ...bounds,
                                //     },
                                // }))}
                                mapContainerStyle={{
                                    minHeight: '450px',
                                    height: '66vh',
                                }}
                                ref={this.missionMapRef}
                            />
                        </Card>
                        <MissionMapLegend
                            items={[
                                { icon: EDot.BLUE, name: 'Ma position' },
                                { icon: EDot.RED, name: 'Mission' },
                            ]}
                        />
                    </Columns.Column>
                </Columns>
            </main>
        )
    }
}

IndexMapMissions.prototype.getUpdatedList = getUpdatedList
IndexMapMissions.prototype.isValidDate = isValidDate
