/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * JobBoard Object
 */
export default class JobBoard extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.jobBoardId jobBoardId
     * @param {string=} data.name name
     */
    constructor({
        jobBoardId,
        name,
        ...data
    } = {}) {
        super(data)
        this.jobBoardId = jobBoardId
        this.name = name
    }
}

/**
 * jobBoard Object used to bind error message
 */
export class ErrorjobBoard extends ErrorBase {
    constructor() {
        super()
        this.jobBoardId = ''
        this.name = ''
    }
}
