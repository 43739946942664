import CommercialInformation, { ErrorCommercialInformation } from 'requests/objects/commercialInformation'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import store from 'redux/store'
import { MessageBarType } from '@fluentui/react'
import { setMessageBar } from 'redux/slices/common'

/**
 * CommercialInformationsHandler
 * @augments {ApiHandler<CommercialInformation, ErrorCommercialInformation>}
 */
export default class CommercialInformationsHandler extends ApiHandler {
    constructor() {
        super({ type: CommercialInformation, errorType: ErrorCommercialInformation, key: 'commercial-informations' })
    }

    /**
     * Assign
     * @param {number} commercialInformationId commercialInformationId
     * @param {number} consultantId consultantId
     * @returns {RequestApi<CommercialInformation>} Request
     */
    assign(commercialInformationId, consultantId) {
        const request = this.initFetchRequest({
            url: [commercialInformationId, 'assign'],
            method: 'GET',
            params: { consultantId },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Process commercialInformation
     * @param {number} commercialInformationId commercialInformationId
     * @param {number} clientId clientId
     * @param {number} commercialInformationProcessOptionId commercialInformationProcessOptionId
     * @returns {RequestApi<CommercialInformation>} Request
     */
    process(commercialInformationId, clientId, commercialInformationProcessOptionId) {
        const request = this.initFetchRequest({
            url: [commercialInformationId, 'process'],
            method: 'GET',
            params: { clientId, commercialInformationProcessOptionId },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    // eslint-disable-next-line new-cap
                    const commercialInformation = new (this.type)(res.data[this.objectName])
                    // const message = commercialInformation.consultantId ? "S'est correctement affecté" : "S'est correctement désaffecté"
                    store.dispatch(setMessageBar({
                        isDisplayed: true,
                        type: MessageBarType.success,
                        message: "L'information commerciale a été correctement traité",
                    }))
                    return commercialInformation
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
