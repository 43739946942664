import React, { useState } from 'react'
import Card from 'components/containers/card'
import {
    ConstrainMode, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList, Text,
} from '@fluentui/react'
import Mission from 'requests/objects/mission'
import Time from 'helpers/methods/time'

const BASE_COLUMNS = /** @type {import('@fluentui/react').IColumn[]} */([
    {
        key: 'startDate',
        name: 'Début',
        fieldName: 'startDate',
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onRender: colItem => (colItem.startDate ? Time(colItem.startDate).getCleanDate({
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }) : ''),
    },
    {
        key: 'endDate',
        name: 'Fin',
        fieldName: 'endDate',
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        onRender: colItem => (colItem.endDate ? Time(colItem.endDate).getCleanDate({
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }) : ''),
    },
])

/**
 * Pivot historic
 * @param {object} props Props
 * @param {Mission} props.item item
 * @returns {JSX.Element} Returns
 */
export default function PivotHistoric({
    item,
}) {
    const [columnsMissionUserCollaboratorHistorics] = useState([
        {
            key: 'name',
            name: 'Nom',
            fieldName: 'lastname',
            minWidth: 250,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            onRender: colItem => [colItem?.userCollaborator?.lastname, colItem?.userCollaborator?.firstname].filter(x => x).join(' '),
        },
        ...BASE_COLUMNS,
    ])
    const [columnsMissionUserOperationsManagerHistorics] = useState([
        {
            key: 'name',
            name: 'Nom',
            fieldName: 'lastname',
            minWidth: 250,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            onRender: colItem => [colItem?.userOperationsManager?.lastname, colItem?.userOperationsManager?.firstname].filter(x => x).join(' '),
        },
        ...BASE_COLUMNS,
    ])

    return (
        <>
            <Card
                title="Consultants"
                iconName="ContactList"
            >
                <ShimmeredDetailsList
                    items={item.missionUserCollaboratorHistorics}
                    columns={columnsMissionUserCollaboratorHistorics}
                    selectionMode={SelectionMode.none}
                    onShouldVirtualize={() => true}
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                />
                {!item.missionUserCollaboratorHistorics?.length
                    && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>}
            </Card>
            <br />
            <Card
                title="Chargés de recrutement"
                iconName="ContactList"
            >
                <ShimmeredDetailsList
                    items={item.missionUserOperationsManagerHistorics}
                    columns={columnsMissionUserOperationsManagerHistorics}
                    selectionMode={SelectionMode.none}
                    onShouldVirtualize={() => true}
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                />
                {!item.missionUserOperationsManagerHistorics?.length
                    && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>}
            </Card>
        </>
    )
}
