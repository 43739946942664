import { Text } from '@fluentui/react'
import getPrice from 'helpers/methods/getPrice'
import React, { useMemo } from 'react'
import User from 'requests/objects/user'
import styles from 'styles/components/pages/index/homeTop.module.scss'

/**
 * @param {object} props Props
 * @param {User} props.me me
 * @param {number=} props.currentWallet currentWallet
 * @param {number=} props.forecast forecast
 * @param {number=} props.monthlyTurnover monthlyTurnover
 * @param {number=} props.annualTurnover annualTurnover
 * @param {number=} props.totalTurnover totalTurnover
 * @param {number=} props.clientVisitsTotal clientVisitsTotal
 * @returns {JSX.Element} Returns
 */
export default function HomeTop({
    me,
    currentWallet,
    forecast,
    monthlyTurnover,
    annualTurnover,
    totalTurnover,
    clientVisitsTotal,
}) {
    const currentWalletStr = useMemo(() => getPrice(currentWallet), [currentWallet])
    const forecastStr = useMemo(() => getPrice(forecast), [forecast])
    const monthlyTurnoverStr = useMemo(() => getPrice(monthlyTurnover), [monthlyTurnover])
    const annualTurnoverStr = useMemo(() => getPrice(annualTurnover), [annualTurnover])
    const totalTurnoverStr = useMemo(() => getPrice(totalTurnover), [totalTurnover])

    return (
        <div
            className={styles['home-top']}
        >
            <div
                className={styles['home-top-left']}
            />
            <div
                className={styles['home-top-center']}
            >
                <Text
                    className={styles['home-top-center-text']}
                    as="p"
                    variant="mediumPlus"
                >
                    <b>
                        <span>👋</span>
                        {' '}
                        Bonjour
                        {' '}
                        <span className="is-capitalized">{me.firstname}</span>
                    </b>
                    , comment ça va aujourd'hui ?
                </Text>
            </div>
            <div
                className={styles['home-top-right']}
            >
                {(
                    (currentWallet !== undefined && currentWallet !== null)
                    || (forecast !== undefined && forecast !== null)
                    || (clientVisitsTotal !== undefined && clientVisitsTotal !== null)
                )
                    && (
                        <div className={styles['home-top-right-container']}>
                            {currentWallet !== undefined && currentWallet !== null && (
                                <Text
                                    as="p"
                                    variant="mediumPlus"
                                >
                                    <span>
                                        Portefeuille actuel
                                    </span>
                                    <span>
                                        {currentWalletStr}
                                    </span>
                                </Text>
                            )}
                            {forecast !== undefined && forecast !== null && (
                                <Text
                                    as="p"
                                    variant="mediumPlus"
                                >
                                    <span>
                                        Prévision
                                    </span>
                                    <span>
                                        {forecastStr}
                                    </span>
                                </Text>
                            )}
                            {clientVisitsTotal !== undefined && clientVisitsTotal !== null && (
                                <Text
                                    as="p"
                                    variant="mediumPlus"
                                >
                                    <span>
                                        RDV Consultant
                                    </span>
                                    <span>
                                        {clientVisitsTotal}
                                        {' '}
                                        {clientVisitsTotal > 8 ? '😀' : '🙁'}
                                    </span>
                                </Text>
                            )}
                        </div>
                    )}
                {(
                    (monthlyTurnover !== undefined && monthlyTurnover !== null)
                    || (annualTurnover !== undefined && annualTurnover !== null)
                    || (totalTurnover !== undefined && totalTurnover !== null)
                )
                    && (
                        <div className={styles['home-top-right-container']}>
                            {monthlyTurnover !== undefined && monthlyTurnover !== null && (
                                <Text
                                    as="p"
                                    variant="mediumPlus"
                                >
                                    <span>
                                        CA mensuel
                                    </span>
                                    <span>
                                        {monthlyTurnoverStr}
                                    </span>
                                </Text>
                            )}
                            {annualTurnover !== undefined && annualTurnover !== null && (
                                <Text
                                    as="p"
                                    variant="mediumPlus"
                                >
                                    <span>
                                        CA annuel
                                    </span>
                                    <span>
                                        {annualTurnoverStr}
                                    </span>
                                </Text>
                            )}
                            {totalTurnover !== undefined && totalTurnover !== null && (
                                <Text
                                    as="p"
                                    variant="mediumPlus"
                                >
                                    <span>
                                        CA généré
                                    </span>
                                    <span>
                                        {totalTurnoverStr}
                                    </span>
                                </Text>
                            )}
                        </div>
                    )}
            </div>
        </div>
    )
}
