/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Client from 'requests/objects//client'

/**
 * ParentCompany Object
 */
export default class ParentCompany extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.parentCompanyId parentCompanyId of the ParentCompany
     * @param {string=} data.name name
     * @param {object[]=} data.clients clients
     */
    constructor({
        parentCompanyId = 0,
        name = '',
        clients,
        ...data
    } = {}) {
        super(data)
        this.parentCompanyId = parentCompanyId
        this.name = name
        this.clients = (clients ?? []).map(x => new Client(x))
    }
}

/**
 * ParentCompany Object used to bind error message
 */
export class ErrorParentCompany extends ErrorBase {
    constructor() {
        super()
        this.parentCompanyId = ''
        this.name = ''
        this.clients = ''
    }
}
