/* eslint-disable max-classes-per-file */

/**
 * One Param Element
 */
export class ParamElement {
    /**
     * @param {object} data data
     * @param {(string | number)=} data.key key
     * @param {string=} data.text text
     * @param {number=} data.divisionId [subDivisions] divisionId
     * @param {number=} data.subDivisionId [metiers] subDivisionId
     * @param {string=} data.content [emailTemplates] content
     * @param {string=} data.subject [emailTemplates] subject
     * @param {boolean=} data.hasLeft [consultants, administrators, operationsManagers] hasLeft
     */
    constructor({
        key = 0, text = '', divisionId, subDivisionId, content, subject, hasLeft = false,
    } = {}) {
        this.key = key
        this.text = text
        this.divisionId = divisionId
        this.subDivisionId = subDivisionId
        this.content = content
        this.subject = subject
        this.hasLeft = hasLeft
    }
}

/**
 * All param
 */
export default class Param {
    /**
     * @param {object} data data
     */
    constructor(data = []) {
        /** @type {ParamElement[]} profiles */
        this.profiles = []

        /** @type {ParamElement[]} commercialInformationProcessOptions */
        this.commercialInformationProcessOptions = []

        /** @type {ParamElement[]} commercialInformationNatures */
        this.commercialInformationNatures = []

        /** @type {ParamElement[]} subDivisions */
        this.subDivisions = []

        /** @type {ParamElement[]} departments */
        this.departments = []

        /** @type {ParamElement[]} divisions */
        this.divisions = []

        /** @type {ParamElement[]} candidateEventTypes */
        this.candidateEventTypes = []

        /** @type {ParamElement[]} clientEventTypes */
        this.clientEventTypes = []

        /** @type {ParamElement[]} missionFamilies */
        this.missionFamilies = []

        /** @type {ParamElement[]} missionStatus */
        this.missionStatus = []

        /** @type {ParamElement[]} natures */
        this.natures = []

        /** @type {ParamElement[]} metiers */
        this.metiers = []

        /** @type {ParamElement[]} eventCategories */
        this.eventCategories = []

        /** @type {ParamElement[]} administrators */
        this.administrators = []

        /** @type {ParamElement[]} consultants */
        this.consultants = []

        /** @type {ParamElement[]} consultants */
        this.operationsManagers = []

        /** @type {ParamElement[]} civilities */
        this.civilities = []

        /** @type {ParamElement[]} cities */
        this.cities = []

        /** @type {ParamElement[]} candidates */
        this.candidates = []

        /** @type {ParamElement[]} consultants */
        this.consultants = []

        /** @type {ParamElement[]} clients */
        this.clients = []

        /** @type {ParamElement[]} parentCompanies */
        this.parentCompanies = []

        /** @type {ParamElement[]} sectors */
        this.sectors = []

        /** @type {ParamElement[]} candidacyProvenances */
        this.candidacyProvenances = []

        /** @type {ParamElement[]} emailTemplate */
        this.emailTemplates = []

        /** @type {ParamElement[]} turnover */
        this.turnovers = []

        /** @type {ParamElement[]} worforce */
        this.workforces = []

        /** @type {ParamElement[]} huntProvenance */
        this.huntProvenances = []

        // eslint-disable-next-line
        for (const param in data)
            this[param] = data[param].map(x => new ParamElement(x))
    }
}
