// // If using .NET 🌐
// /**
//  * Invalid Entity Error, thrown when fields are missing or wrong
//  * @template E
//  */
// export default class InvalidEntityError extends Error {
//     /**
//      *
//      * @param {object} data data
//      * @param {object} data.errorType Class to use for error
//      * @param {object} data.content Content of the errors
//      */
//     constructor({ content, errorType }) {
//         super(content.description)
//         this.name = this.constructor.name

//         /**
//          * Object containing all the errors
//          * @type {E}
//          */
//         // eslint-disable-next-line new-cap
//         this.errorField = errorType ? new (errorType)() : {}

//         // Init errors from API into the object
//         // eslint-disable-next-line no-restricted-syntax
//         for (const validationResult of content.validationResults)
//             // eslint-disable-next-line no-restricted-syntax
//             for (const memberName of validationResult.memberNames)
//                 if (this.errorField[memberName])
//                     this.errorField[memberName] += `, ${validationResult.errorMessage}`
//                 else
//                     this.errorField[memberName] = validationResult.errorMessage
//     }
// }

// If using NestJs 😸
/**
 * @typedef {object} InvalidEntityContent
 * @property {string} property property
 * @property {string} constraints constraints
 * @property {InvalidEntityContent[]} children children
 */

/**
 * Invalid Entity Error, thrown when fields are missing or wrong
 * @template E
 */
export default class InvalidEntityError extends Error {
    /**
     *
     * @param {object} data data
     * @param {object} data.errorType Class to use for error
     * @param {InvalidEntityContent[]} data.content Content of the errors
     */
    constructor({ content, errorType }) {
        super('Certains champs ne sont pas valides')
        this.name = this.constructor.name

        /**
         * Object containing all the errors
         * @type {E}
         */
        // eslint-disable-next-line new-cap
        this.errorField = errorType ? new (errorType)() : {}

        this.setErrorFields(content)
    }

    /**
     * Init errors from API into the object
     * @private
     * @param {InvalidEntityContent[]} validationResults validationResults
     * @param {string=} key key
     */
    setErrorFields(validationResults, key) {
        // eslint-disable-next-line no-restricted-syntax
        for (const validationResult of validationResults) {
            const newKey = [key, validationResult.property].filter(x => !!x).join('.')
            this.errorField[newKey] = validationResult.constraints
            if (validationResult.children)
                this.setErrorFields(validationResult.children, newKey)
        }
    }
}
