/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * File Object
 */
export default class File extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.fileId fileId of the File
     * @param {string=} data.name name
     * @param {string=} data.nameOnDisk nameOnDisk
     * @param {number=} data.createdByUserId createdByUserId
     * @param {object=} data.createdByUser createdByUser
     */
    constructor({
        fileId = 0,
        name = '',
        nameOnDisk = '',
        createdByUserId,
        createdByUser,
        ...data
    } = {}) {
        super(data)
        this.fileId = fileId
        this.name = name
        this.nameOnDisk = nameOnDisk
        this.createdByUserId = createdByUserId
        this.createdByUser = createdByUser
    }
}

/**
 * File Object used to bind error message
 */
export class ErrorFile extends ErrorBase {
    constructor() {
        super()
        this.fileId = ''
        this.name = ''
        this.nameOnDisk = ''
        this.createdByUserId = ''
        this.createdByUser = ''
    }
}
