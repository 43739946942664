import { Icon, Text } from '@fluentui/react'
import React, { ReactNode, ReactComponentElement } from 'react'
import Divider from 'components/visuals/divider'
import styles from 'styles/components/containers/card.module.scss'
import classNames from 'classnames'

/**
 * Card item
 * @param {object} props props
 * @param {(string | ReactComponentElement)=} props.title Title
 * @param {string=} props.iconName Icon Name
 * @param {string=} props.className Classes name
 * @param {React.CSSProperties=} props.style style
 * @param {ReactNode=} props.children Children
 * @returns {JSX.Element} Element
 */
function Card({
    title = '', iconName = '', className = '', children = null, style = {},
}) {
    return (
        <div
            className={classNames(styles.card, className)}
            style={style}
        >
            {title && (
                <>
                    <Text
                        variant="large"
                        block
                    >
                        <Icon iconName={iconName} />
                        {' '}
                        {title}
                    </Text>
                    <Divider />
                </>
            )}
            {children}
        </div>
    )
}

export default Card
