import { Text } from '@fluentui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from 'styles/components/pages/index/homeNote.module.scss'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history'

/**
 * @param {object} props Props
 * @param {Partial<Location> | string} props.to to
 * @param {React.ReactNode} props.children children
 * @returns {JSX.Element} Returns
 */
export default function HomeNote({
    to, children,
}) {
    if (to)
        return (
            <Link to={to}>
                <div className={styles['home-note']}>
                    <Text>
                        {children}
                    </Text>
                </div>
            </Link>
        )

    return (
        <div className={styles['home-note']}>
            <Text>
                {children}
            </Text>
        </div>
    )
}
