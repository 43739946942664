import Types from 'types/types'
import { Column } from 'types/column' // eslint-disable-line

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const PhoneTemplatesColumns = [
    {
        fieldName: 'type',
        name: 'Type',
        type: Types.STRING,
    },
    {
        fieldName: 'name',
        name: 'Nom',
        type: Types.STRING,
    },
    {
        fieldName: 'phone',
        name: 'Téléphone',
        type: Types.STRING,
    },
]

export default PhoneTemplatesColumns
