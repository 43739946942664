import React, { PureComponent } from 'react'
import {
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    buildColumns,
    Text,
    IColumn,
    Label,
    DefaultButton,
    TextField,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn, onColumnClick, getUpdatedList } from 'helpers/methods/common'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Client from 'requests/objects/client'
import { Link } from 'react-router-dom'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/clients/index.module.scss'
import { NEW_PATH } from 'types/others'
import clientColumns from 'types/clients/columns'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import SaveSearchModal from 'components/pages/clients/index/saveSearchModal'
import SearchPanel from 'components/pages/clients/index/searchPanel'

const CLIENT_TYPES = [
    { key: 1, text: 'Cient' },
    { key: 2, text: 'Prospect' },
]

/** @debug {AppProps} */

/**
 * @typedef {object} SearchParamsType
 * @property {string} name name
 * @property {string} contactName contactName
 * @property {number} divisionId divisionId
 * @property {number[]} subDivisionIds subDivisionIds
 * @property {number[]} sectorIds sectorIds
 * @property {number} turnoverId turnoverId
 * @property {number} clientTypeId clientTypeId
 * @property {number} departmentId departmentId
 * @property {string} codeCollaborator codeCollaborator
 */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexClients extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {IColumn[]} Columns displayed */
            columns: [],
            /** @type {Client[]} Items found in API */
            items: [],
            /** @type {Client[]} Initial items found in API */
            iniItems: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                name: null,
                contactName: null,
                divisionId: null,
                subDivisionIds: [],
                sectorIds: [],
                turnoverId: null,
                clientTypeId: null,
                departmentId: null,
                codeCollaborator: null,
            },
            /** @type {boolean} Is save search modal visible? */
            isSaveSearchModalVisible: false,
            /** @type {boolean} Is my search visible? */
            isSearchPanelVisible: false,
        }

        this.submitInput = React.createRef()

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        //    this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }
        // this.init()

        // this.setupBreadcrumb()

        // this.setupCommandBar()

        // this.setState({
        //     columns: this.buildColumns(),
        // })

        if (prevState.searchParams?.divisionId !== searchParams?.divisionId)
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(truePrevState => ({
                searchParams: {
                    ...truePrevState.searchParams,
                    subDivisionIds: [],
                },
            }))
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.clientsHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, setTitle } = this.props

        setBreadcrumb([
            { text: 'Saisie client', key: 'client' },
            { text: 'Tous les clients', key: 'all-client', isCurrentItem: true },
        ])
        setTitle('Clients')
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props

        setCommand(
            [
                {
                    key: 'new',
                    text: 'Nouveau',
                    iconProps: { iconName: 'Add' },
                    onClick: () => history.push(`/clients/${NEW_PATH}`),
                    disabled: false,
                },
                {
                    key: 'search',
                    text: 'Recherches',
                    iconProps: { iconName: 'Search' },
                    subMenuProps: {
                        items: [
                            {
                                key: 'save',
                                text: 'Sauvegarder',
                                iconProps: { iconName: 'SaveTemplate' },
                                onClick: () => this.setState({ isSaveSearchModalVisible: true }),
                                disabled: false,
                            },
                            {
                                key: 'search',
                                text: 'Mes recherches',
                                iconProps: { iconName: 'SearchBookmark' },
                                onClick: () => this.setState({ isSearchPanelVisible: true }),
                                disabled: false,
                            },
                        ],
                    },
                },
            ],
        )
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    name: queryParams.get('name') || null,
                    contactName: queryParams.get('contactName') || null,
                    divisionId: queryParams.get('divisionId') ? +queryParams.get('divisionId') : null,
                    subDivisionIds: queryParams.getAll('subDivisionIds') ? queryParams.getAll('subDivisionIds').map(x => +x) : [],
                    sectorIds: queryParams.getAll('sectorIds') ? queryParams.getAll('sectorIds').map(x => +x) : [],
                    turnoverId: queryParams.get('turnoverId') ? +queryParams.get('turnoverId') : null,
                    clientTypeId: queryParams.get('clientTypeId') ? +queryParams.get('clientTypeId') : null,
                    departmentId: queryParams.get('departmentId') ? +queryParams.get('departmentId') : null,
                    codeCollaborator: queryParams.get('codeCollaborator') || null,
                },
            }, () => this.syncSearchParamsInHistory())
        }

        this.setState({ columns: this.buildColumns() })
    }

    /**
     * Init <DetailList>
     * @returns {IColumn[]} Columns
     */
    buildColumns() {
        const cols = clientColumns?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            this.onColumnClick.bind(this, {
                colName: 'columns', dataName: ['items'], source: 'state', isFlatten: true,
            }),
        )

        // eslint-disable-next-line no-restricted-syntax
        for (const column of columns) {
            column.name = cols[column.name]
            column.maxWidth = (() => {
                switch (column.name) {
                    case 'Nom':
                    case 'Contacts':
                    case 'Secteurs':
                    case 'Département':
                    case 'Taille':
                    case 'Nature':
                    case 'Métiers':
                    case 'CA':
                        return 150
                    default:
                        return (column.name?.length ?? 1) * 12
                }
            })()
            column.minWidth = (column.name?.length ?? 1) * 5
            column.onRender = clientColumns.find(x => x.name === column.name)?.onRender ?? this.handleRenderColumn(clientColumns, column.name, column)
        }

        return columns
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { clientsHandler, setBreadcrumb } = this.props
            const { searchParams } = this.state
            setBreadcrumb([
                { text: 'Saisie client', key: 'client' },
                { text: 'Tous les clients', key: 'all-client', isCurrentItem: true },
            ])
            try {
                this.clientsHandlerGetAll = clientsHandler.getAll(searchParams)
                const clients = await this.clientsHandlerGetAll.fetch()
                this.setState({
                    items: clients.map(x => this.flattenObj(x)),
                    iniItems: clients,
                    status: Status.RESOLVED,
                })
                setBreadcrumb([
                    { text: 'Saisie client', key: 'client' },
                    { text: `Tous les clients (${clients.length || 0})`, key: 'all-client', isCurrentItem: true },
                ])
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            iniItems: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            columns, status, items, searchParams, isSaveSearchModalVisible, isSearchPanelVisible,
            iniItems, // eslint-disable-line no-unused-vars
        } = this.state
        const { param, researchClientsHandler } = this.props
        const hasResearchClientId = !!(new URLSearchParams(window.location.search)).get('researchClientId')

        return (
            <>
                <main className={styles.index}>
                    <form
                        className={styles['index-search-filters']}
                        onSubmit={ev => {
                            ev.preventDefault()
                            this.search()
                        }}
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label="Nom de la société"
                                    disabled={status === Status.PENDING || hasResearchClientId}
                                    value={searchParams.name || ''}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, name: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label="Nom/Prénom du contact"
                                    disabled={status === Status.PENDING || hasResearchClientId}
                                    value={searchParams.contactName || ''}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, contactName: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Division du contact"
                                    options={param.divisions}
                                    selectedKey={searchParams.divisionId}
                                    disabled={status === Status.PENDING || hasResearchClientId}
                                    onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, divisionId: option.key } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Sous-famille(s) du contact"
                                    options={param.subDivisions.filter(x => !searchParams.divisionId || x.divisionId === searchParams.divisionId)}
                                    selectedKey={searchParams.subDivisionIds}
                                    disabled={status === Status.PENDING || hasResearchClientId}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: { ...searchParams, subDivisionIds: this.getUpdatedList(searchParams.subDivisionIds, option) },
                                    })}
                                    multiSelect
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Secteur(s)"
                                    options={param.sectors}
                                    selectedKey={searchParams.sectorIds}
                                    disabled={status === Status.PENDING || hasResearchClientId}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: { ...searchParams, sectorIds: this.getUpdatedList(searchParams.sectorIds, option) },
                                    })}
                                    multiSelect
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Chiffre d'affaires"
                                    options={param.turnovers}
                                    selectedKey={searchParams.turnoverId}
                                    disabled={status === Status.PENDING || hasResearchClientId}
                                    onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, turnoverId: option.key } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Nature"
                                    options={CLIENT_TYPES}
                                    selectedKey={searchParams.clientTypeId}
                                    disabled={status === Status.PENDING || hasResearchClientId}
                                    onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, clientTypeId: option.key } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label="Département"
                                    options={param.departments}
                                    selectedKey={searchParams.departmentId}
                                    disabled={status === Status.PENDING || hasResearchClientId}
                                    onChange={(_ev, option) => this.setState({ searchParams: { ...searchParams, departmentId: option.key } })}
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label="Code collaborateur"
                                    disabled={status === Status.PENDING || hasResearchClientId}
                                    value={searchParams.codeCollaborator || ''}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, codeCollaborator: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <Label className="is-hidden-mobile">&nbsp;</Label>
                                <DefaultButton
                                    text="Rechercher"
                                    primary
                                    split
                                    disabled={status === Status.PENDING}
                                    onClick={() => this.submitInput.current.click()}
                                    iconProps={{ iconName: 'Search' }}
                                    menuProps={{
                                        items: [
                                            {
                                                key: 'Search',
                                                text: 'Rechercher',
                                                iconProps: { iconName: 'Search' },
                                                onClick: () => this.submitInput.current.click(),
                                            },
                                            {
                                                key: 'Reset',
                                                text: 'Réinitialiser',
                                                iconProps: { iconName: 'Filter' },
                                                onClick: () => this.setState({
                                                    /** @type {SearchParamsType} */
                                                    searchParams: {
                                                        name: null,
                                                        contactName: null,
                                                        divisionId: null,
                                                        subDivisionIds: [],
                                                        sectorIds: [],
                                                        turnoverId: null,
                                                        clientTypeId: null,
                                                        departmentId: null,
                                                        codeCollaborator: null,
                                                    },
                                                    items: [],
                                                    iniItems: [],
                                                }, () => this.setState({ columns: this.buildColumns() })),
                                            },
                                        ],
                                    }}
                                />
                            </Columns.Column>
                        </Columns>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                            tabIndex={-1}
                        />
                    </form>
                    <Card>
                        <ShimmeredDetailsList
                            items={items}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            onShouldVirtualize={() => true}
                            enableShimmer={status === Status.PENDING}
                            layoutMode={DetailsListLayoutMode.justified}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderRow={(props, defaultRender) => {
                                let rootStyle = {}
                                if (props.item.natureId === 1)
                                    rootStyle = {
                                        background: '#dff6dd',
                                        '&:hover': {
                                            backgroundColor: '#ccf1c9',
                                            color: 'rgb(50, 49, 48)',
                                        },
                                    }
                                return (
                                    <Link
                                        to={{
                                            pathname: `/clients/${props.item?.clientId}`,
                                            // state: {
                                            //     client: iniItems.find(x => x.clientId === props.item?.clientId),
                                            // },
                                        }}
                                    >
                                        {defaultRender({ ...props, styles: { root: rootStyle } })}
                                    </Link>
                                )
                            }}
                        />
                        {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                            && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>}
                        {!items?.length && [Status.IDLE].includes(status)
                            && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Veuillez lancer une recherche</Text>}
                    </Card>
                </main>
                <SaveSearchModal
                    isVisible={isSaveSearchModalVisible}
                    setIsVisible={newVal => this.setState({ isSaveSearchModalVisible: newVal })}
                    handler={researchClientsHandler}
                    data={searchParams}
                />
                <SearchPanel
                    isVisible={isSearchPanelVisible}
                    setIsVisible={newVal => this.setState({ isSearchPanelVisible: newVal })}
                    handler={researchClientsHandler}
                    applyFilters={newVal => this.setState({ searchParams: newVal }, () => {
                        this.submitInput.current.click()
                    })}
                />
            </>
        )
    }
}

IndexClients.prototype.onColumnClick = onColumnClick
IndexClients.prototype.flattenObj = flattenObj
IndexClients.prototype.handleRenderColumn = handleRenderColumn
IndexClients.prototype.getUpdatedList = getUpdatedList
