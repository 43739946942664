import EmailTemplate, { ErrorEmailTemplate } from 'requests/objects/emailTemplate'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'
import { MessageBarType } from '@fluentui/react'

/**
 * EmailTemplatesHandler
 * @augments {ApiHandler<EmailTemplate, ErrorEmailTemplate>}
 */
export default class EmailTemplatesHandler extends ApiHandler {
    constructor() {
        super({ type: EmailTemplate, errorType: ErrorEmailTemplate, key: 'email-templates' })
    }

    /**
     * Send an email
     * @param {{subject: string; content: string; to: string[]}} data data
     * @returns {RequestApi<null>} Request
     */
    sendMail(data) {
        const request = this.initFetchRequest({ url: ['send'], method: 'POST', data })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => store.dispatch(setMessageBar({
                    isDisplayed: true,
                    type: MessageBarType.success,
                    message: 'Les emails ont bien été envoyés.',
                })))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
