import { IconButton } from '@fluentui/react'
import React, { useCallback, useRef, useState } from 'react'
import ResearchCandidatesHandler from 'requests/handlers/researchCandidatesHandler'
import ResearchCandidate from 'requests/objects/researchCandidate'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import Status from 'types/status'

/**
 * @param {object} props Props
 * @param {ResearchCandidatesHandler} props.handler handler
 * @param {number} props.candidateId candidateId
 * @param {() => void} props.onClick onClick
 * @returns {JSX.Element} Element
 */
export default function ExcludeCandidatButton({ handler, candidateId, onClick }) {
    const [status, setStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<RequestApi<ResearchCandidate>>} */
    const handlerExcludeById = useRef()

    const exclude = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            const queryParams = new URLSearchParams(window.location.search)
            handlerExcludeById.current = handler.exclude(+queryParams.get('researchCandidateId'), candidateId)
            await handlerExcludeById.current.fetch()
            setStatus(Status.RESOLVED)
            onClick()
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                    break
                case InvalidEntityError:
                case NotImplementedError:
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [handler, candidateId, onClick])

    return (
        <IconButton
            iconProps={{ iconName: 'UserRemove' }}
            onClick={ev => {
                ev.preventDefault()
                exclude()
            }}
            style={{
                position: 'absolute',
                top: '6px',
            }}
            title="Exclure le candidat de la recherche"
            disabled={status === Status.PENDING}
        />
    )
}
