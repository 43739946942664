import {
    ConstrainMode,
    DetailsListLayoutMode, IconButton, Panel, SelectionMode, ShimmeredDetailsList, Text,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import ResearchCandidatesHandler from 'requests/handlers/researchCandidatesHandler'
import ResearchCandidate from 'requests/objects/researchCandidate'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'

/**
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {ResearchCandidatesHandler} props.handler handler
 * @param {(data: object) => void} props.applyFilters applyFilters
 * @returns {JSX.Element} Element
 */
export default function SearchPanel({
    isVisible, setIsVisible, handler, applyFilters,
}) {
    const [researchCandidates, setResearchCandidate] = useState([])
    const [status, setStatus] = useState(Status.IDLE)

    /** @type {React.MutableRefObject<RequestApi<ResearchCandidate[]>>} */
    const handlerGetAll = useRef()
    /** @type {React.MutableRefObject<RequestApi<ResearchCandidate>>} */
    const handlerRemoveById = useRef()

    const remove = useCallback(
        /**
         * @param {number} id id
         */
        async id => {
            try {
                handlerRemoveById.current = handler.removeById(id)
                await handlerRemoveById.current.fetch()
                setIsVisible(false)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                        break
                    case InvalidEntityError:
                    case NotImplementedError:
                    default:
                        setStatus(Status.REJECTED)
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [setIsVisible, handler],
    )

    const [columns] = useState(/** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'name',
            name: 'Nom',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            // isPadded: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: item => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <span
                    style={{
                        cursor: 'pointer', margin: '-11px', padding: '12px', display: 'block',
                    }}
                    onClick={() => {
                        applyFilters({
                            ...JSON.parse(item.request),
                            researchCandidateId: item.researchCandidateId,
                            excludedCandidateIds: item.excludedCandidates.map(x => x.candidateId),
                        })
                        setIsVisible(false)
                    }}
                >
                    {item.name}
                </span>
            ),
        },
        {
            key: 'action',
            name: '',
            fieldName: 'action',
            minWidth: 25,
            maxWidth: 25,
            isRowHeader: true,
            isResizable: true,
            // isPadded: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: item => (
                <div style={{ paddingTop: '6px', margin: '-11px 0' }}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        onClick={() => remove(item.researchCandidateId)}
                    />
                </div>
            ),
        },
    ]))

    useEffect(() => {
        if (isVisible) {
            (async () => {
                try {
                    handlerGetAll.current = handler.getAll()
                    const data = await handlerGetAll.current.fetch()
                    setResearchCandidate(data)
                    setStatus(Status.RESOLVED)
                } catch (error) {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError:
                        case InvalidEntityError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        default:
                            setResearchCandidate([])
                            setStatus(Status.REJECTED)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                }
            })()
        } else {
            handlerGetAll.current?.cancel()
            setResearchCandidate([])
            setStatus(Status.IDLE)
        }
    }, [handler, isVisible])

    // On component did unmount
    useEffect(() => () => {
        handlerGetAll.current?.cancel()
        handlerRemoveById.current?.cancel()
    }, [])

    return (
        <Panel
            isLightDismiss
            isOpen={isVisible}
            onDismiss={() => {
                setIsVisible(false)
            }}
            closeButtonAriaLabel="Fermer"
            headerText="Mes recherches"
        >
            <br />
            <ShimmeredDetailsList
                items={researchCandidates}
                columns={columns}
                enableShimmer={status === Status.PENDING}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
            />
            {!researchCandidates?.length && status !== Status.IDLE
                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucune recherche n'a été trouvée</Text>}
        </Panel>
    )
}
