import React from 'react'
import Card from 'components/containers/card'
import {
    ConstrainMode, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList, Text,
} from '@fluentui/react'
import Mission from 'requests/objects/mission'
import { Link } from 'react-router-dom'

const contactsColumns = /** @type {import('@fluentui/react').IColumn[]} */([
    {
        key: 'lastname',
        name: 'Nom',
        fieldName: 'lastname',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => {
            const content = `${item.lastname} ${item.firstname}`
            return item.hasLeft ? <del>{content}</del> : content
        },
    },
    {
        key: 'subDivision.name',
        name: 'Sous-famille',
        fieldName: 'subDivision.name',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (item.hasLeft ? <del>{item.subDivision?.name}</del> : item.subDivision?.name),
    },
    {
        key: 'metier.name',
        name: 'Metier',
        fieldName: 'metier.name',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => (item.hasLeft ? <del>{item.metier?.name}</del> : item.metier?.name),
    },
    {
        key: 'landlinePhone',
        name: 'Téléphone',
        fieldName: 'landlinePhone',
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: item => {
            const content = [item.landlinePhone, item.mobilePhone].filter(x => x).join(', ')
            return item.hasLeft ? <del>{content}</del> : content
        },
    },
])

/**
 * Pivot contact
 * @param {object} props Props
 * @param {Mission} props.item item
 * @returns {JSX.Element} Returns
 */
export default function PivotContact({
    item,
}) {
    return (
        <Card
            title="Contacts du client"
            iconName="ContactList"
        >
            <ShimmeredDetailsList
                items={item.client?.contacts ?? []}
                columns={contactsColumns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                // eslint-disable-next-line react/no-unstable-nested-components
                onRenderRow={(props, defaultRender) => (
                    <Link
                        to={{
                            pathname: `/clients/${props.item?.clientId}/contacts/${props.item?.contactId}`,
                        }}
                    >
                        {defaultRender(props)}
                    </Link>
                )}
            />
            {!item.missionUserCollaboratorHistorics?.length
                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>}
        </Card>
    )
}
