import React from 'react'
import { Text } from '@fluentui/react'
import styles from 'styles/components/pages/missions/pivotCandidacy/boardLegend.module.scss'
import classNames from 'classnames'

/**
 * @param {object} props Props
 * @param {{name: string, color: 'grey-blue' | 'purple' | 'orange' | 'yellow' | 'grey' | 'green'}[]} props.items Items
 * @returns {JSX.Element} Element
 */
export default function BoardLegend({ items = [] }) {
    return (
        <ul className={styles['board-legend']}>
            {items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                    <Text>
                        <span className={classNames(styles['board-legend-icon'], styles[`is-${item.color}`])} />
                        {' '}
                        {item.name}
                    </Text>
                </li>
            ))}
        </ul>
    )
}
