import React, { PureComponent } from 'react'
import {
    ShimmeredDetailsList, SelectionMode, DefaultButton, TextField, DetailsListLayoutMode, ConstrainMode, buildColumns, Text, IColumn, Label,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn, onColumnClick, getUpdatedList } from 'helpers/methods/common'
import Status from 'types/status'
import { Link } from 'react-router-dom'
import { Columns } from 'react-bulma-components'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/phone/index.module.scss'
import phoneTemplateColumns from 'types/phone/columns'
import Phone from 'requests/objects/phone'

/**
 * @typedef {object} SearchParamsType
 * @property {string} phone phone
 */

/**
 * @augments {PureComponent<import('app').AppProps>} extends
 */
export default class IndexPhoneTemplates extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {IColumn[]} Columns displayed */
            columns: [],
            /** @type {Phone[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                phone: '',
            },
        }

        this.submitInput = React.createRef()

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()
        this.setState({
            columns: this.buildColumns(),
        })
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.phonesHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, setTitle } = this.props

        setBreadcrumb([
            { text: 'Recherche téléphone', key: ' modele-phone' },
            { text: 'Tous les téléphones', key: 'all-modele-phone', isCurrentItem: true },
        ])
        setTitle('Téléphone')
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props

        setCommand([])
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    phone: queryParams.get('phone') || null,
                },
            }, () => this.syncSearchParamsInHistory())
        }
        this.setState({ columns: this.buildColumns() })
    }

    /**
     * Init <DetailList>
     * @returns {IColumn[]} Columns
     */
    buildColumns() {
        const cols = phoneTemplateColumns?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            this.onColumnClick.bind(this, {
                colName: 'columns', dataName: ['items'], source: 'state', isFlatten: true,
            }),
        )

        // eslint-disable-next-line no-restricted-syntax
        for (const column of columns) {
            column.name = cols[column.name]
            column.maxWidth = (() => {
                switch (column.name) {
                    case 'Type':
                        return 100
                    case 'Nom':
                        return 250
                    case 'Téléphone':
                        return 150
                    default:
                        return (column.name?.length ?? 1) * 12
                }
            })()
            column.minWidth = (column.name?.length ?? 1) * 5
            // eslint-disable-next-line max-len
            column.onRender = phoneTemplateColumns.find(x => x.name === column.name)?.onRender ?? this.handleRenderColumn(phoneTemplateColumns, column.name, column)
        }

        return columns
    }

    /**
     * Search elements test
     */
    search() {
        this.setState({
            status: Status.PENDING,
            columns: this.buildColumns(),
        }, async () => {
            const { phonesHandler, setBreadcrumb } = this.props
            const { searchParams } = this.state

            setBreadcrumb([
                { text: 'Recherche téléphone', key: ' modele-phone' },
                { text: 'Tous les téléphones', key: 'all-modele-phone', isCurrentItem: true },
            ])

            try {
                this.phonesHandlerGetAll = phonesHandler.getAll(searchParams)
                const phones = await this.phonesHandlerGetAll.fetch()
                this.setState({
                    items: phones.map(x => this.flattenObj(x)),
                    status: Status.RESOLVED,
                })
                setBreadcrumb([
                    { text: 'Recherche téléphone', key: ' modele-phone' },
                    { text: `Tous les téléphones (${phones.length || 0})`, key: 'all-modele-phone', isCurrentItem: true },
                ])
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            columns, status, items, searchParams,
        } = this.state
        const hasResearchPhoneId = !!(new URLSearchParams(window.location.search)).get('researchPhoneId')

        return (
            <main className={styles.index}>
                <form
                    className={styles['index-search-filters']}
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.search()
                    }}
                >
                    <Columns>
                        <Columns.Column size="one-quarter">
                            <TextField
                                label="Téléphone"
                                disabled={status === Status.PENDING || hasResearchPhoneId}
                                value={searchParams.phone || ''}
                                onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, phone: newVal } })}
                                required
                                minLength={2}
                                type="phone"
                            />
                        </Columns.Column>
                        <Columns.Column size="one-quarter">
                            <Label className="is-hidden-mobile">&nbsp;</Label>
                            <DefaultButton
                                text="Rechercher"
                                primary
                                split
                                disabled={status === Status.PENDING}
                                onClick={() => this.submitInput.current.click()}
                                iconProps={{ iconName: 'Search' }}
                                menuProps={{
                                    items: [
                                        {
                                            key: 'Search',
                                            text: 'Rechercher',
                                            iconProps: { iconName: 'Search' },
                                            onClick: () => this.submitInput.current.click(),
                                        },
                                        {
                                            key: 'Reset',
                                            text: 'Réinitialiser',
                                            iconProps: { iconName: 'Filter' },
                                            onClick: () => this.setState({
                                                /** @type {SearchParamsType} */
                                                searchParams: {
                                                    phone: null,
                                                },
                                                items: [],
                                            }, () => this.setState({ columns: this.buildColumns() })),
                                        },
                                    ],
                                }}
                            />
                        </Columns.Column>
                    </Columns>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
                <Card>
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => {
                            const rootStyle = {}
                            const path = {
                                Candidat: `/candidats/${props.item['ids.0']}`,
                                Client: `/clients/${props.item['ids.0']}`,
                                IC: `/informations-commerciales/${props.item['ids.0']}`,
                                Contact: `/clients/${props.item['ids.0']}/contacts/${props.item['ids.1']}`,
                                Chasse: `/missions/${props.item['ids.0']}`,
                            }[props.item?.type] ?? ''
                            if (path)
                                return (
                                    <Link
                                        to={{
                                            pathname: path,
                                        }}
                                    >
                                        {defaultRender({ ...props, styles: { root: rootStyle } })}
                                    </Link>
                                )
                            return defaultRender({ ...props, styles: { root: rootStyle } })
                        }}
                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>}
                    {!items?.length && [Status.IDLE].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Veuillez lancer une recherche</Text>}
                </Card>
            </main>
        )
    }
}

IndexPhoneTemplates.prototype.onColumnClick = onColumnClick
IndexPhoneTemplates.prototype.flattenObj = flattenObj
IndexPhoneTemplates.prototype.handleRenderColumn = handleRenderColumn
IndexPhoneTemplates.prototype.getUpdatedList = getUpdatedList
