import React from 'react'
import EDot from 'types/missions/enums/dot'
import { Text } from '@fluentui/react'
import styles from 'styles/components/pages/carte-missions/missionsMapLegend.module.scss'

/**
 * @param {object} props Props
 * @param {{name: string, icon: EDot}[]} props.items Items
 * @returns {JSX.Element} Element
 */
export default function MissionMapLegend({ items = [] }) {
    return (
        <ul className={styles['missions-map-legend']}>
            {items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                    <Text>
                        <img
                            src={item.icon}
                            alt="dot"
                        />
                        {item.name}
                    </Text>
                </li>
            ))}
        </ul>
    )
}
