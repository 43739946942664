// eslint-disable-next-line import/named
import ApiHandler from 'requests/apiHandler'
import Phone, { ErrorPhone } from 'requests/objects/phone'

/**
 * PhonesHandler
 * @augments {ApiHandler<Phone, ErrorPhone>}
 */
export default class PhonesHandler extends ApiHandler {
    constructor() {
        super({ type: Phone, errorType: ErrorPhone, key: 'phones' })
    }
}
