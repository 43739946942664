import React from 'react'
import UsersHandler from 'requests/handlers/usersHandler'
import User from 'requests/objects/user'
import ApiHandler from 'requests/apiHandler'
import ParamsHandler from 'requests/handlers/paramsHandler'
import Param from 'requests/objects/param'
import CandidatesHandler from 'requests/handlers/candidatesHandler'
import Candidate from 'requests/objects/candidate'
import ResearchCandidatesHandler from 'requests/handlers/researchCandidatesHandler'
import ResearchCandidate from 'requests/objects/researchCandidate'
import CommercialInformationsHandler from 'requests/handlers/commercialInformationsHandler'
import CommercialInformation from 'requests/objects/commercialInformation'
import SearchFiltersHandler from 'requests/handlers/searchFiltersHandler'
import EmailTemplatesHandler from 'requests/handlers/emailTemplatesHandler'
import EmailTemplate from 'requests/objects/emailTemplate'
import ClientsHandler from 'requests/handlers/clientsHandler'
import Client from 'requests/objects/client'
import ResearchClientsHandler from 'requests/handlers/researchClientsHandler'
import ResearchClient from 'requests/objects/researchClient'
import ParentCompaniesHandler from 'requests/handlers/parentCompaniesHandler'
import ParentCompany from 'requests/objects/parentCompany'
import ContactsHandler from 'requests/handlers/contactsHanlder'
import Contact from 'requests/objects/contact'
import Candidacy from 'requests/objects/candidacy'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import MissionsHandler from 'requests/handlers/missionsHandler'
import Mission from 'requests/objects/mission'
import HuntsHandler from 'requests/handlers/huntsHandler'
import Hunt from 'requests/objects/hunt'
import HomesHandler from 'requests/handlers/homesHandler'
import Home from 'requests/objects/home'
import PhonesHandler from 'requests/handlers/phonesHandler'
import Phone from 'requests/objects/phone'

/**
 * @typedef {object} HandlersProps
 * @property {function(object):ApiHandler<any>} handler Function to get proper handler for a desired object
 * @property {UsersHandler} usersHandler User Handler
 * @property {EmailTemplatesHandler} emailTemplatesHandler EmailTemplate Handler
 * @property {ParamsHandler} paramsHandler Param Handler
 * @property {CandidatesHandler} candidatesHandler Candidates Handler
 * @property {ResearchCandidatesHandler} researchCandidatesHandler ResearchCandidates Handler
 * @property {CommercialInformationsHandler} commercialInformationsHandler Commercial Information Handler
 * @property {SearchFiltersHandler} searchFiltersHandler Search filters Handler
 * @property {ClientsHandler} clientsHandler Clients Handler
 * @property {ResearchClientsHandler} researchClientsHandler ResearchClients Handler
 * @property {ParentCompaniesHandler} parentCompaniesHandler ParentCompanies Handler
 * @property {ContactsHandler} contactsHandler Contacts Handler
 * @property {CandidaciesHandler} candidaciesHandler Candidacies Handler
 * @property {MissionsHandler} missionsHandler Missions Handler
 * @property {HuntsHandler} huntsHandler Hunt Handler
 * @property {HomesHandler} homesHandler Homes Handler
 * @property {PhonesHandler} phonesHandler PhonesHandler
 */

// eslint-disable-next-line jsdoc/require-returns
/**
 * With handlers hoc
 * @param {Object} WrappedComponent Component to wrapp
 */
export default function withHandlers(WrappedComponent) {
    // eslint-disable-next-line react/display-name
    return class extends React.Component {
        constructor(props) {
            super(props)

            // Declare all handlers
            const usersHandler = new UsersHandler()
            const paramsHandler = new ParamsHandler()
            const candidatesHandler = new CandidatesHandler()
            const researchCandidatesHandler = new ResearchCandidatesHandler()
            const commercialInformationsHandler = new CommercialInformationsHandler()
            const searchFiltersHandler = new SearchFiltersHandler()
            const emailTemplatesHandler = new EmailTemplatesHandler()
            const clientsHandler = new ClientsHandler()
            const researchClientsHandler = new ResearchClientsHandler()
            const parentCompaniesHandler = new ParentCompaniesHandler()
            const contactsHandler = new ContactsHandler()
            const candidaciesHandler = new CandidaciesHandler()
            const missionsHandler = new MissionsHandler()
            const huntsHandler = new HuntsHandler()
            const homesHandler = new HomesHandler()
            const phonesHandler = new PhonesHandler()

            /** @type {object} Store handlers in an object */
            this.handlers = {
                usersHandler,
                paramsHandler,
                candidatesHandler,
                researchCandidatesHandler,
                commercialInformationsHandler,
                searchFiltersHandler,
                emailTemplatesHandler,
                clientsHandler,
                researchClientsHandler,
                parentCompaniesHandler,
                contactsHandler,
                candidaciesHandler,
                missionsHandler,
                huntsHandler,
                homesHandler,
                phonesHandler,
            }

            /** @type {function(object):ApiHandler<any>} Function to get proper handler for a desired object */
            this.handler = obj => {
                switch (obj) {
                    case User:
                        return usersHandler
                    case Param:
                        return paramsHandler
                    case Candidate:
                        return candidatesHandler
                    case ResearchCandidate:
                        return researchCandidatesHandler
                    case CommercialInformation:
                        return commercialInformationsHandler
                    case EmailTemplate:
                        return emailTemplatesHandler
                    case Client:
                        return clientsHandler
                    case ResearchClient:
                        return researchClientsHandler
                    case ParentCompany:
                        return parentCompaniesHandler
                    case Contact:
                        return contactsHandler
                    case Candidacy:
                        return candidaciesHandler
                    case Mission:
                        return missionsHandler
                    case Hunt:
                        return huntsHandler
                    case Home:
                        return homesHandler
                    case Phone:
                        return phonesHandler
                    default:
                        return null
                }
            }
        }

        render() {
            return (
                <WrappedComponent
                    handler={this.handler}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.handlers}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.props}
                />
            )
        }
    }
}
