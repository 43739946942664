import React from 'react'
import Card from 'components/containers/card'
import { Columns } from 'react-bulma-components'
import Mission from 'requests/objects/mission'
import EventClient from 'requests/objects/eventClient'
import EventFileInput from 'components/pages/clients/[id]/index/contacts/[id]/evenements/eventFileInput'
import ContactsHandler from 'requests/handlers/contactsHanlder'

/**
 * Pivot docs
 * @param {object} props Props
 * @param {Mission} props.item item
 * @param {ContactsHandler} props.contactsHandler ContactsHandler
 * @returns {JSX.Element} Returns
 */
export default function PivotDocuments({
    item, contactsHandler,
}) {
    /**
     * ENVOI CONTRAT COLLABORATION
     * @type {EventClient | null}
     */
    const event35 = (item.eventClients).find(x => x.eventTypeId === 35 && x.file)
    /**
     * FICHE DE RECHERCHE
     * @type {EventClient | null}
     */
    const event43 = (item.eventClients).find(x => x.eventTypeId === 43 && x.file)
    /**
     * DIFFUSION ANNONCE
     * @type {EventClient | null}
     */
    const event40 = (item.eventClients).find(x => x.eventTypeId === 40 && x.file)

    return (
        <Card
            title="Documents relatifs à la mission"
            iconName="DocumentSet"
        >
            <Columns>
                <Columns.Column size="one-quarter">
                    <EventFileInput
                        label="Fichier"
                        handler={contactsHandler}
                        contactId={event35?.contactId}
                        eventClientId={event35?.eventClientId}
                        item={event35}
                        isReadOnly
                    />
                </Columns.Column>
                <Columns.Column size="one-quarter">
                    <EventFileInput
                        label="Fiche de recherche"
                        handler={contactsHandler}
                        contactId={event43?.contactId}
                        eventClientId={event43?.eventClientId}
                        item={event43}
                        isReadOnly
                    />
                </Columns.Column>
                <Columns.Column size="one-quarter">
                    <EventFileInput
                        label="Annonce"
                        handler={contactsHandler}
                        contactId={event40?.contactId}
                        eventClientId={event40?.eventClientId}
                        item={event40}
                        isReadOnly
                    />
                </Columns.Column>
            </Columns>
        </Card>
    )
}
