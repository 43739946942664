/**
 * @enum {string}
 */
const ESearchMapType = {
    ALL: 'all',
    OPEN: 'open',
    PLACED: 'placed',
}

export default ESearchMapType
