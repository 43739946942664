import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import Param from 'requests/objects/param'
import Mission from 'requests/objects/mission'
import User from 'requests/objects/user'
import styles from 'styles/components/pages/missions/pivotCandidacy.module.scss'
import BoardColumns, { BoardColumn } from 'components/pages/missions/[id]/pivotCandidacy/boardColumns'
import Candidacy from 'requests/objects/candidacy'
import Hunt from 'requests/objects/hunt'
import BoardCard from 'components/pages/missions/[id]/pivotCandidacy/boardCard'
import BoardLegend from 'components/pages/missions/[id]/pivotCandidacy/boardLegend'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import EProfile from 'types/profile'
import EBoardColumn from 'types/missions/enums/boardColumn'
import EBoardCardType from 'types/missions/enums/boardCardType'
import HuntsHandler from 'requests/handlers/huntsHandler'
import BoardToCallCards from 'components/pages/missions/[id]/pivotCandidacy/boardToCallCards'
import BoardSetInterviewInfoModal from 'components/pages/missions/[id]/pivotCandidacy/boardSetInterviewInfoModal'
import BoardMissionCodingModal from 'components/pages/missions/[id]/pivotCandidacy/boardMissionCodingModal'
import BoardSendMailModal from 'components/pages/missions/[id]/pivotCandidacy/boardSendMailModal'
import EmailTemplatesHandler from 'requests/handlers/emailTemplatesHandler'
import BoardSetInterviewClientInfoModal from 'components/pages/missions/[id]/pivotCandidacy/boardSetInterviewClientInfoModal'
import BoardCancelInterviewModal from 'components/pages/missions/[id]/pivotCandidacy/boardCancelInterviewModal'
import BoardCancelInterviewClientModal from 'components/pages/missions/[id]/pivotCandidacy/boardCancelInterviewClientModal'
import BoardReCodingModal from 'components/pages/missions/[id]/pivotCandidacy/boardReCodingModal'
import { List, TextField } from '@fluentui/react'
import MissionsHandler from 'requests/handlers/missionsHandler'

/**
 * Sort card
 * @param {Candidacy | Hunt} a Candidacy or Hunt
 * @param {Candidacy | Hunt} b Candidacy or Hunt
 * @returns {number} Sort
 */
const sortCard = (a, b) => {
    if (a.isUnderProposal || b.isUnderProposal) {
        if (a.isUnderProposal && b.isUnderProposal)
            return b.updatedAt.getTime() - a.updatedAt.getTime()
        if (a.isUnderProposal && !b.isUnderProposal)
            return -1
        if (!a.isUnderProposal && b.isUnderProposal)
            return 1
    }

    if (a.isRejected || b.isRejected) {
        if (a.isRejected && b.isRejected)
            return b.updatedAt.getTime() - a.updatedAt.getTime()
        if (!a.isRejected && b.isRejected)
            return -1
        if (a.isRejected && !b.isRejected)
            return 1
    }

    return b.updatedAt.getTime() - a.updatedAt.getTime()
}

/**
 * Pivot Candidacy
 * @param {object} props Props
 * @param {Param} props.param param
 * @param {Mission} props.item item
 * @param {CandidaciesHandler} props.candidaciesHandler candidaciesHandler
 * @param {HuntsHandler} props.huntsHandler huntHandler
 * @param {User} props.me me
 * @param {(item: Partial<Mission>, isIniItemToo?: boolean) => void} props.onChange onChange
 * @param {boolean} props.isSendMailEnable isSendMailEnable
 * @param {(isSendMailEnable: boolean) => void} props.setIsSendMailEnable setIsSendMailEnable
 * @param {boolean} props.isSendMailModalVisible isSendMailModalVisible
 * @param {(isSendMailEnable: boolean) => void} props.setIsSendMailModalVisible setIsSendMailModalVisible
 * @param {EmailTemplatesHandler} props.emailTemplatesHandler emailTemplatesHandler
 * @param {Candidacy | Hunt} props.preSelectedBoardItem preSelectedBoardItem
 * @param {boolean} props.isHiddingReject isHiddingReject
 * @param {MissionsHandler} props.missionsHandler missionsHandler
 * @returns {JSX.Element} Returns
 */
export default function PivotCandidacy({
    param,
    item,
    me,
    onChange,
    candidaciesHandler,
    huntsHandler,
    isSendMailEnable,
    setIsSendMailEnable,
    isSendMailModalVisible,
    setIsSendMailModalVisible,
    emailTemplatesHandler,
    preSelectedBoardItem,
    isHiddingReject,
    missionsHandler,
}) {
    const [isInterviewModalVisible, setIsInterviewModalVisible] = useState(false)
    const [isInterviewClientModalVisible, setIsInterviewClientModalVisible] = useState(false)
    const [isCodingModalVisible, setIsCodingModalVisible] = useState(false)
    const [isInterviewCancelModalVisible, setIsInterviewCancelModalVisible] = useState(false)
    const [isInterviewClientCancelModalVisible, setIsInterviewClientCancelModalVisible] = useState(false)
    const [isRecodingModalVisible, setIsRecodingModalVisible] = useState(false)

    /** @type {[Candidacy | Hunt, React.Dispatch<React.SetStateAction<Candidacy | Hunt>>]} Board item focused for modal and so */
    const [focusedBoardItem, setFocusedBoardItem] = useState(null)
    /** @type {[CandidaciesHandler | HuntsHandler, React.Dispatch<React.SetStateAction<CandidaciesHandler | HuntsHandler>>]} Handler focused for modal and so */
    const [focusedHandler, setFocusedHandler] = useState(null)

    /** @type {[string[], React.Dispatch<React.SetStateAction<string[]>>]} Mail to */
    const [selectedEmails, setSelectedEmail] = useState([])
    /** @type {[EBoardColumn, React.Dispatch<React.SetStateAction<EBoardColumn>>]} Current dragging board column */
    const [dragingColumnId, setDragingColumnId] = useState(null)

    const [filter, setFilter] = useState('')

    const [
        boardItems1,
        boardItems2,
        boardItems3,
        boardItems4,
        boardItems5,
        boardItems6,
        boardItems7,
        boardItems8,
    ] = useMemo(() => {
        const boardItems = item.boardItems.filter(x => [
            x.candidate.lastname,
            x.candidate.firstname,
        ].filter(z => z).join(' ').toLowerCase().includes(filter.toLowerCase().trim()))

        return [
            boardItems
                .filter(boardItem => boardItem.boardColumnId === EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT)
                .filter(x => (isHiddingReject ? !x.isRejected : true))
                .sort(sortCard),
            boardItems
                .filter(boardItem => boardItem.boardColumnId === EBoardColumn.RETURN_ANNOUCEMENT)
                .filter(x => (isHiddingReject ? !x.isRejected : true))
                .sort(sortCard),
            boardItems.filter(boardItem => boardItem.boardColumnId === EBoardColumn.TO_CALL)
                .filter(x => (isHiddingReject ? !x.isRejected : true))
                .sort(sortCard),
            boardItems.filter(boardItem => boardItem.boardColumnId === EBoardColumn.CALLED)
                .filter(x => (isHiddingReject ? !x.isRejected : true))
                .sort(sortCard),
            boardItems.filter(boardItem => boardItem.boardColumnId === EBoardColumn.CANDIDIDATE_INTERVIEW)
                .filter(x => (isHiddingReject ? !x.isRejected : true))
                .sort(sortCard),
            boardItems.filter(boardItem => boardItem.boardColumnId === EBoardColumn.SENDING_CV)
                .filter(x => (isHiddingReject ? !x.isRejected : true))
                .sort(sortCard),
            boardItems.filter(boardItem => boardItem.boardColumnId === EBoardColumn.CLIENT_CANDIDATE_INTERVIEW)
                .filter(x => (isHiddingReject ? !x.isRejected : true))
                .sort(sortCard),
            boardItems.filter(boardItem => boardItem.boardColumnId === EBoardColumn.PLACED)
                .filter(x => (isHiddingReject ? !x.isRejected : true))
                .sort(sortCard),
        ]
    }, [filter, isHiddingReject, item.boardItems])

    /** @type {React.MutableRefObject<RequestApi<Candidacy | Hunt>>} */
    const handlerPatchStatus = useRef(null)

    const getHandler = useCallback(
        /**
         * @param {Candidacy | Hunt} boardItem boardItem
         * @returns {CandidaciesHandler | HuntsHandler} handler
         */
        boardItem => {
            switch (boardItem.type) {
                case EBoardCardType.CANDIDACY:
                    return candidaciesHandler
                case EBoardCardType.HUNT:
                    return huntsHandler
                default:
                    return null
            }
        }, [candidaciesHandler, huntsHandler],
    )

    // Switch column
    const onBoardItemDrop = useCallback(
        /**
         * @param {number} id Id
         * @param {EBoardColumn} newColumnId newColumnId
         * @param {EBoardCardType} type type
         */
        async (id, newColumnId, type) => {
            try {
                const index = item.boardItems.findIndex(boardItem => {
                    switch (type) {
                        case EBoardCardType.CANDIDACY:
                            return id === /** @type {Candidacy} */(boardItem).candidacyId
                        case EBoardCardType.HUNT:
                            return id === /** @type {Hunt} */(boardItem).huntId
                        default:
                            return false
                    }
                })
                if (index > -1 && newColumnId !== item.boardItems[index]?.boardColumnId) {
                    const boardItems = [...item.boardItems]
                    const boardItem = boardItems[index]
                    switch (type) {
                        case EBoardCardType.CANDIDACY:
                            handlerPatchStatus.current = candidaciesHandler.patchStatus(
                                /** @type {Candidacy} */(boardItem).candidacyId,
                                newColumnId,
                            )
                            break
                        case EBoardCardType.HUNT:
                            handlerPatchStatus.current = huntsHandler.patchStatus(
                                /** @type {Hunt} */(boardItem).huntId,
                                newColumnId,
                            )
                            break
                        default:
                            break
                    }
                    const newboardItem = await handlerPatchStatus.current.fetch()
                    boardItems[index] = newboardItem
                    // Stuff to execute actions on placed in new column
                    switch (newboardItem.boardColumnId) {
                        case EBoardColumn.RETURN_ANNOUCEMENT:
                            // If previous was RECEPTION_RETURN_ANNOUCEMENT
                            if (boardItem.boardColumnId === EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT) {
                                setFocusedBoardItem(newboardItem)
                                setIsRecodingModalVisible(true)
                                setFocusedHandler(getHandler(newboardItem))
                            }
                            onChange({ boardItems })
                            break
                        case EBoardColumn.CALLED:
                            // If previous was candidate interview
                            if (boardItem.boardColumnId === EBoardColumn.CANDIDIDATE_INTERVIEW) {
                                setFocusedBoardItem(newboardItem)
                                setIsInterviewCancelModalVisible(true)
                                setFocusedHandler(getHandler(newboardItem))
                            }
                            onChange({ boardItems })
                            break
                        case EBoardColumn.SENDING_CV:
                            // If previous was candidate client interview
                            if (boardItem.boardColumnId === EBoardColumn.CLIENT_CANDIDATE_INTERVIEW) {
                                setFocusedBoardItem(newboardItem)
                                setIsInterviewClientCancelModalVisible(true)
                                setFocusedHandler(getHandler(newboardItem))
                            }
                            onChange({ boardItems })
                            break
                        case EBoardColumn.CANDIDIDATE_INTERVIEW:
                            setFocusedBoardItem(newboardItem)
                            setIsInterviewModalVisible(true)
                            setFocusedHandler(getHandler(newboardItem))
                            onChange({ boardItems })
                            break
                        case EBoardColumn.CLIENT_CANDIDATE_INTERVIEW:
                            setFocusedBoardItem(newboardItem)
                            setIsInterviewClientModalVisible(true)
                            setFocusedHandler(getHandler(newboardItem))
                            onChange({ boardItems })
                            break
                        case EBoardColumn.PLACED:
                            setFocusedBoardItem(newboardItem)
                            setIsCodingModalVisible(true)
                            setFocusedHandler(getHandler(newboardItem))
                            onChange({
                                boardItems: boardItems.map((x, i) => {
                                    if (i === index)
                                        return { ...x, isUnderProposal: false }
                                    if (x.boardColumnId === EBoardColumn.PLACED)
                                        return x
                                    return { ...x, isRejected: true }
                                }),
                                missionStatusId: 2,
                                investmentDate: new Date(),
                            })
                            break
                        default:
                            onChange({ boardItems })
                            break
                    }
                }
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [candidaciesHandler, onChange, huntsHandler, item.boardItems, getHandler],
    )

    /**
     * On delete candidacy
     */
    const onDelete = useCallback(
        /**
         * @param {number} id id
         * @param {EBoardCardType} type type
         */
        (id, type) => {
            onChange({
                boardItems: item.boardItems.filter(boardItem => {
                    switch (type) {
                        case EBoardCardType.CANDIDACY:
                            return id !== /** @type {Candidacy} */(boardItem).candidacyId
                        case EBoardCardType.HUNT:
                            return id !== /** @type {Hunt} */(boardItem).huntId
                        default:
                            return true
                    }
                }),
            })
        }, [item.boardItems, onChange],
    )

    /**
     * On update candidacy
     */
    const onUpdate = useCallback(
        /**
         * @param {Candidacy | Hunt} boardItemUpdated boardItemUpdated
         */
        async boardItemUpdated => {
            const index = item.boardItems.findIndex(boardItem => {
                switch (boardItemUpdated.type) {
                    case EBoardCardType.CANDIDACY:
                        return /** @type {Candidacy} */(boardItemUpdated).candidacyId === /** @type {Candidacy} */(boardItem).candidacyId
                    case EBoardCardType.HUNT:
                        return /** @type {Hunt} */(boardItemUpdated).huntId === /** @type {Hunt} */(boardItem).huntId
                    default:
                        return false
                }
            })
            if (index > -1) {
                const boardItems = [...item.boardItems]
                boardItems[index] = boardItemUpdated
                onChange({ boardItems })
            }
        }, [item.boardItems, onChange],
    )

    /**
     * On create candidacy
     */
    const onCreate = useCallback(
        /**
         * @param {Candidacy | Hunt} boardItemCreated boardItemCreated
         */
        async boardItemCreated => {
            onChange({ boardItems: [...item.boardItems, boardItemCreated] })
        }, [item.boardItems, onChange],
    )

    /**
     * On create many candidacy
     */
    const onCreateMany = useCallback(
        /**
         * @param {(Candidacy | Hunt)[]} boardItemsCreated boardItemsCreated
         */
        async boardItemsCreated => {
            onChange({ boardItems: [...item.boardItems, ...boardItemsCreated] })
        }, [item.boardItems, onChange],
    )

    /**
     * Toggle an email into list to send
     */
    const toggleEmail = useCallback(
        /**
         * @param {string} email email
         */
        email => {
            if (email)
                setSelectedEmail(prev => {
                    if (prev.includes(email))
                        return prev.filter(x => x !== email)
                    return [...prev, email]
                })
        }, [],
    )

    // Clear selected email on isSendMailEnable is false
    useEffect(() => {
        if (!isSendMailEnable)
            setSelectedEmail([])
    }, [isSendMailEnable])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const version = useMemo(() => new Date().getTime(), [isSendMailEnable, selectedEmails])
    return (
        <>
            <div className={styles['pivot-candidacy']}>
                <BoardColumns>
                    {([EProfile.Administrator, EProfile.Consultant].includes(me.profileId)
                        || (me.profileId === EProfile.OperationsManager && me.hasOpManAccessBoardReception))
                        && (
                            <BoardColumn
                                iconName="ReturnToSession"
                                columnId={EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT}
                                title="Réception retour annonces"
                                total={boardItems1.length}
                                onBoardItemDrop={onBoardItemDrop}
                                dragingColumnId={dragingColumnId}
                                missionsHandler={missionsHandler}
                                missionId={item.missionId}
                            >
                                <List
                                    items={boardItems1}
                                    // renderedWindowsAhead={5}
                                    // renderedWindowsBehind={5}
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    onRenderCell={boardItem => (
                                        <BoardCard
                                            // eslint-disable-next-line max-len
                                            key={`${/** @type {Candidacy} */(boardItem).candidacyId || /** @type {Hunt} */(boardItem).huntId}_${boardItem.type}`}
                                            item={boardItem}
                                            columnId={EBoardColumn.RECEPTION_RETURN_ANNOUCEMENT}
                                            handler={getHandler(boardItem)}
                                            onDelete={onDelete}
                                            onUpdate={onUpdate}
                                            onCreate={onCreate}
                                            param={param}
                                            me={me}
                                            isSendMailEnable={isSendMailEnable}
                                            toggleEmail={toggleEmail}
                                            selectedEmails={selectedEmails}
                                            setDragingColumnId={setDragingColumnId}
                                            preSelectedBoardItem={preSelectedBoardItem}
                                        />
                                    )}
                                    version={version}
                                />
                            </BoardColumn>
                        )}
                    <BoardColumn
                        iconName="Refresh"
                        columnId={EBoardColumn.RETURN_ANNOUCEMENT}
                        title="Retour annonce"
                        total={boardItems2.length}
                        onBoardItemDrop={onBoardItemDrop}
                        dragingColumnId={dragingColumnId}
                        missionsHandler={missionsHandler}
                        missionId={item.missionId}
                    >
                        <List
                            items={boardItems2}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderCell={boardItem => (
                                <BoardCard
                                    key={`${/** @type {Candidacy} */(boardItem).candidacyId || /** @type {Hunt} */(boardItem).huntId}_${boardItem.type}`}
                                    item={boardItem}
                                    columnId={EBoardColumn.RETURN_ANNOUCEMENT}
                                    handler={getHandler(boardItem)}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    onCreate={onCreate}
                                    param={param}
                                    me={me}
                                    isSendMailEnable={isSendMailEnable}
                                    toggleEmail={toggleEmail}
                                    selectedEmails={selectedEmails}
                                    setDragingColumnId={setDragingColumnId}
                                    preSelectedBoardItem={preSelectedBoardItem}
                                />
                            )}
                            version={version}
                        />
                    </BoardColumn>
                    <BoardColumn
                        iconName="TransferCall"
                        columnId={EBoardColumn.TO_CALL}
                        title="A appeler"
                        total={boardItems3.length}
                        onBoardItemDrop={onBoardItemDrop}
                        dragingColumnId={dragingColumnId}
                        missionsHandler={missionsHandler}
                        missionId={item.missionId}
                    >
                        {!isSendMailEnable && (
                            <BoardToCallCards
                                param={param}
                                onCreate={onCreate}
                                onCreateMany={onCreateMany}
                                huntsHandler={huntsHandler}
                                candidaciesHandler={candidaciesHandler}
                                missionId={item.missionId}
                            />
                        )}
                        <List
                            items={boardItems3}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderCell={boardItem => (
                                <BoardCard
                                    key={`${/** @type {Candidacy} */(boardItem).candidacyId || /** @type {Hunt} */(boardItem).huntId}_${boardItem.type}`}
                                    item={boardItem}
                                    columnId={EBoardColumn.TO_CALL}
                                    handler={getHandler(boardItem)}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    onCreate={onCreate}
                                    param={param}
                                    me={me}
                                    isSendMailEnable={isSendMailEnable}
                                    toggleEmail={toggleEmail}
                                    selectedEmails={selectedEmails}
                                    setDragingColumnId={setDragingColumnId}
                                    preSelectedBoardItem={preSelectedBoardItem}
                                />
                            )}
                            version={version}
                        />
                    </BoardColumn>
                    <BoardColumn
                        iconName="DeclineCall"
                        columnId={EBoardColumn.CALLED}
                        title="Appelé"
                        total={boardItems4.length}
                        onBoardItemDrop={onBoardItemDrop}
                        dragingColumnId={dragingColumnId}
                        missionsHandler={missionsHandler}
                        missionId={item.missionId}
                    >
                        <List
                            items={boardItems4}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderCell={boardItem => (
                                <BoardCard
                                    key={`${/** @type {Candidacy} */(boardItem).candidacyId || /** @type {Hunt} */(boardItem).huntId}_${boardItem.type}`}
                                    item={boardItem}
                                    columnId={EBoardColumn.CALLED}
                                    handler={getHandler(boardItem)}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    onCreate={onCreate}
                                    param={param}
                                    me={me}
                                    isSendMailEnable={isSendMailEnable}
                                    toggleEmail={toggleEmail}
                                    selectedEmails={selectedEmails}
                                    setDragingColumnId={setDragingColumnId}
                                    preSelectedBoardItem={preSelectedBoardItem}
                                />
                            )}
                            version={version}
                        />
                    </BoardColumn>
                    <BoardColumn
                        iconName="ChatInviteFriend"
                        columnId={EBoardColumn.CANDIDIDATE_INTERVIEW}
                        title="Entretien candidat"
                        total={boardItems5.length}
                        onBoardItemDrop={onBoardItemDrop}
                        dragingColumnId={dragingColumnId}
                        missionsHandler={missionsHandler}
                        missionId={item.missionId}
                    >
                        <List
                            items={boardItems5}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderCell={boardItem => (
                                <BoardCard
                                    key={`${/** @type {Candidacy} */(boardItem).candidacyId || /** @type {Hunt} */(boardItem).huntId}_${boardItem.type}`}
                                    item={boardItem}
                                    columnId={EBoardColumn.CANDIDIDATE_INTERVIEW}
                                    handler={getHandler(boardItem)}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    onCreate={onCreate}
                                    param={param}
                                    me={me}
                                    isSendMailEnable={isSendMailEnable}
                                    toggleEmail={toggleEmail}
                                    selectedEmails={selectedEmails}
                                    setDragingColumnId={setDragingColumnId}
                                    preSelectedBoardItem={preSelectedBoardItem}
                                />
                            )}
                            version={version}
                        />
                    </BoardColumn>
                    <BoardColumn
                        iconName="MailAttached"
                        columnId={EBoardColumn.SENDING_CV}
                        title="Envoi CV"
                        total={boardItems6.length}
                        onBoardItemDrop={onBoardItemDrop}
                        dragingColumnId={dragingColumnId}
                        missionsHandler={missionsHandler}
                        missionId={item.missionId}
                    >
                        <List
                            items={boardItems6}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderCell={boardItem => (
                                <BoardCard
                                    key={`${/** @type {Candidacy} */(boardItem).candidacyId || /** @type {Hunt} */(boardItem).huntId}_${boardItem.type}`}
                                    item={boardItem}
                                    columnId={EBoardColumn.SENDING_CV}
                                    handler={getHandler(boardItem)}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    onCreate={onCreate}
                                    param={param}
                                    me={me}
                                    isSendMailEnable={isSendMailEnable}
                                    toggleEmail={toggleEmail}
                                    selectedEmails={selectedEmails}
                                    setDragingColumnId={setDragingColumnId}
                                    preSelectedBoardItem={preSelectedBoardItem}
                                />
                            )}
                            version={version}
                        />
                    </BoardColumn>
                    <BoardColumn
                        iconName="OfficeChat"
                        columnId={EBoardColumn.CLIENT_CANDIDATE_INTERVIEW}
                        title="Entretien client/candidat"
                        total={boardItems7.length}
                        onBoardItemDrop={onBoardItemDrop}
                        dragingColumnId={dragingColumnId}
                        missionsHandler={missionsHandler}
                        missionId={item.missionId}
                    >
                        <List
                            items={boardItems7}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderCell={boardItem => (
                                <BoardCard
                                    key={`${/** @type {Candidacy} */(boardItem).candidacyId || /** @type {Hunt} */(boardItem).huntId}_${boardItem.type}`}
                                    item={boardItem}
                                    columnId={EBoardColumn.CLIENT_CANDIDATE_INTERVIEW}
                                    handler={getHandler(boardItem)}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    onCreate={onCreate}
                                    param={param}
                                    me={me}
                                    isSendMailEnable={isSendMailEnable}
                                    toggleEmail={toggleEmail}
                                    selectedEmails={selectedEmails}
                                    setDragingColumnId={setDragingColumnId}
                                    preSelectedBoardItem={preSelectedBoardItem}
                                />
                            )}
                            version={version}
                        />
                    </BoardColumn>
                    <BoardColumn
                        iconName="SingleBookmark"
                        columnId={EBoardColumn.PLACED}
                        title="Placé"
                        total={boardItems8.length}
                        onBoardItemDrop={onBoardItemDrop}
                        dragingColumnId={dragingColumnId}
                        missionsHandler={missionsHandler}
                        missionId={item.missionId}
                    >
                        <List
                            items={boardItems8}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderCell={boardItem => (
                                <BoardCard
                                    key={`${/** @type {Candidacy} */(boardItem).candidacyId || /** @type {Hunt} */(boardItem).huntId}_${boardItem.type}`}
                                    item={boardItem}
                                    columnId={EBoardColumn.PLACED}
                                    handler={getHandler(boardItem)}
                                    onDelete={onDelete}
                                    onUpdate={onUpdate}
                                    onCreate={onCreate}
                                    param={param}
                                    me={me}
                                    isSendMailEnable={isSendMailEnable}
                                    toggleEmail={toggleEmail}
                                    selectedEmails={selectedEmails}
                                    setDragingColumnId={setDragingColumnId}
                                    preSelectedBoardItem={preSelectedBoardItem}
                                />
                            )}
                            version={version}
                        />
                    </BoardColumn>
                </BoardColumns>
                <TextField
                    placeholder="Filtrer sur le nom/prénom du candidat"
                    value={filter}
                    onChange={(ev, newVal) => {
                        setFilter(newVal)
                    }}
                    type="search"
                    styles={{
                        root: {
                            margin: '0 10px 0',
                        },
                    }}
                />
                <BoardLegend
                    items={[
                        { name: 'Classique', color: 'grey-blue' },
                        { name: 'AD', color: 'purple' },
                        { name: 'Rejetée', color: 'orange' },
                        { name: 'Sous propale', color: 'yellow' },
                        { name: 'Entretien annulé', color: 'grey' },
                        { name: 'A déjà été appelé', color: 'green' },
                    ]}
                />
            </div>
            <BoardSetInterviewInfoModal
                handler={focusedHandler}
                isVisible={isInterviewModalVisible}
                setIsVisible={newVal => setIsInterviewModalVisible(newVal)}
                item={focusedBoardItem}
                onUpdate={onUpdate}
                param={param}
            />
            <BoardSetInterviewClientInfoModal
                handler={focusedHandler}
                isVisible={isInterviewClientModalVisible}
                setIsVisible={newVal => setIsInterviewClientModalVisible(newVal)}
                item={focusedBoardItem}
                onUpdate={onUpdate}
            />
            <BoardMissionCodingModal
                handler={focusedHandler}
                isVisible={isCodingModalVisible}
                setIsVisible={newVal => setIsCodingModalVisible(newVal)}
                item={focusedBoardItem}
                isForce
                onUpdate={onUpdate}
            />
            <BoardSendMailModal
                handler={emailTemplatesHandler}
                isVisible={isSendMailModalVisible}
                setIsVisible={newVal => setIsSendMailModalVisible(newVal)}
                param={param}
                selectedEmails={selectedEmails}
                setIsSendMailEnable={setIsSendMailEnable}
            />

            <BoardCancelInterviewModal
                handler={focusedHandler}
                isVisible={isInterviewCancelModalVisible}
                setIsVisible={newVal => setIsInterviewCancelModalVisible(newVal)}
                item={focusedBoardItem}
                onUpdate={onUpdate}
            />
            <BoardCancelInterviewClientModal
                handler={focusedHandler}
                isVisible={isInterviewClientCancelModalVisible}
                setIsVisible={newVal => setIsInterviewClientCancelModalVisible(newVal)}
                item={focusedBoardItem}
                onUpdate={onUpdate}
            />
            <BoardReCodingModal
                handler={focusedHandler}
                isVisible={isRecodingModalVisible}
                setIsVisible={setIsRecodingModalVisible}
                item={focusedBoardItem}
                param={param}
                onUpdate={onUpdate}
                onBoardItemDrop={onBoardItemDrop}
            />
        </>
    )
}
