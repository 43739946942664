import {
    DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, ChoiceGroup,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import CommercialInformationsHandler from 'requests/handlers/commercialInformationsHandler'
import CommercialInformation, { ErrorCommercialInformation } from 'requests/objects/commercialInformation'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { ParamElement } from 'requests/objects/param'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import CommercialInformationProcessOption from 'requests/objects/commercialInformationProcessOption'
import ECommercialInformationProcessOption from 'types/informationsCommerciales/commercialInformationProcessOption'

/**
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {(data: {commercialInformation?: CommercialInformation, commercialInformationProcessOptionId?: number}) => void} props.completeProcess completeProcess
 * @param {CommercialInformationsHandler} props.commercialInformationsHandler commercialInformationsHandler
 * @param {CommercialInformation} props.commercialInformation commercialInformation
 * @param {ParamElement[]} props.commercialInformationProcessOptionParam commercialInformationProcessOptionParam
 * @param {CommercialInformationProcessOption} props.commercialInformationProcessOption commercialInformationProcessOption
 * @returns {JSX.Element} Element
 */
export default function ProcessModal({
    isVisible,
    completeProcess,
    commercialInformationsHandler,
    commercialInformation,
    commercialInformationProcessOptionParam,
    commercialInformationProcessOption,
}) {
    const [commercialInformationProcessOptionId, setProcessOptionId] = useState(commercialInformationProcessOption
        ? commercialInformationProcessOption.commercialInformationProcessOptionId
        : ECommercialInformationProcessOption.ClientRdvtDone)
    const [clientId] = useState(commercialInformation.clientId)
    const [status, setStatus] = useState(Status.IDLE)
    const [, setErrorField] = useState(new ErrorCommercialInformation())

    /** @type {React.MutableRefObject<RequestApi<CommercialInformation>>} */
    const handlerProcess = useRef()

    const save = useCallback(async () => {
        const { commercialInformationId } = commercialInformation
        try {
            setStatus(Status.PENDING)
            if (!commercialInformation.isProcessed && commercialInformation.isProspect) {
                completeProcess({ commercialInformation, commercialInformationProcessOptionId })
                return
            }
            handlerProcess.current = commercialInformationsHandler.process(commercialInformationId, clientId, commercialInformationProcessOptionId)
            const commercialInformationProcessed = await handlerProcess.current.fetch()
            completeProcess({ commercialInformation: commercialInformationProcessed })
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorCommercialInformation>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [completeProcess, commercialInformationsHandler, commercialInformationProcessOptionId, clientId, commercialInformation])

    // On component did unmount
    useEffect(() => () => {
        handlerProcess.current?.cancel()
    }, [])

    useEffect(() => {
        if (!isVisible) {
            setProcessOptionId(commercialInformationProcessOption
                ? commercialInformationProcessOption.commercialInformationProcessOptionId
                : ECommercialInformationProcessOption.ClientRdvtDone)
            setStatus(Status.IDLE)
        }
    }, [isVisible, commercialInformationProcessOption])

    const processOptions = commercialInformationProcessOptionParam.map(({ key, text }) => ({ key: `${key}`, text }))

    return (
        <div>
            <Dialog
                hidden={!isVisible}
                onDismiss={() => completeProcess({})}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: `Traitement ${commercialInformation.isProspect ? `prospect - création du client ${commercialInformation.prospectName}` : 'client'}`,
                    subText: "Veuillez séléctionner le résultat de l'IC",
                }}
                modalProps={{
                    isBlocking: false,
                    layerProps: {
                        onLayerDidMount: () => /** @type {HTMLElement} */(document.activeElement).blur(),
                    },
                }}
                maxWidth="555px"
                minWidth="33%"
            >
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        save()
                    }}
                >
                    <ChoiceGroup
                        selectedKey={`${commercialInformationProcessOptionId}`}
                        options={processOptions}
                        onChange={(ev, option) => {
                            ev.preventDefault()
                            setProcessOptionId(+option.key)
                        }}
                    />
                    <br />
                    <DialogFooter>
                        <DefaultButton
                            onClick={() => completeProcess({})}
                            text="Annuler"
                            disabled={status === Status.PENDING}
                        />
                        <PrimaryButton
                            text="Traiter"
                            type="submit"
                            disabled={status === Status.PENDING}
                        />
                    </DialogFooter>
                </form>
            </Dialog>
        </div>
    )
}
