import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import {
    DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField,
} from '@fluentui/react'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import Status from 'types/status'
import Param from 'requests/objects/param'
import EmailTemplatesHandler from 'requests/handlers/emailTemplatesHandler'
import { ErrorEmailTemplate } from 'requests/objects/emailTemplate'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'

/**
 * Candidacy card
 * @param {object} props Props
 * @param {EmailTemplatesHandler} props.handler handler
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {Param} props.param param
 * @param {string[]} props.selectedEmails selectedEmails
 * @param {(isSendMailEnable: boolean) => void} props.setIsSendMailEnable setIsSendMailEnable
 * @returns {JSX.Element} Returns
 */
export default function BoardSendMailModal({
    handler, isVisible, setIsVisible, param, selectedEmails, setIsSendMailEnable,
}) {
    const [item, setItem] = useState(/** @type {{subject: string; content: string; emailTemplateId: number}} */({ subject: '', content: '' }))
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorEmailTemplate())

    /** @type {React.MutableRefObject<RequestApi<null>>} */
    const handlerSendMail = useRef(null)

    const sendMail = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerSendMail.current = handler.sendMail({ ...item, to: selectedEmails })
            await handlerSendMail.current.fetch()
            setStatus(Status.RESOLVED)
            setIsVisible(false)
            setIsSendMailEnable(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorEmailTemplate>} */(error).errorField)
                    setStatus(Status.REJECTED)
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [handler, item, selectedEmails, setIsSendMailEnable, setIsVisible])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerSendMail?.current?.cancel()
    }, [])

    useEffect(() => {
        if (!isVisible)
            setItem(/** @type {{subject: string; content: string; emailTemplateId: number}} */({ subject: '', content: '' }))
    }, [isVisible])

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => setIsVisible(false)}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Envoyer un email',
                subText: 'Après avoir sélectionné les candidatures, choisissez un template et préparer le contenu pour envoyer un email.',
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="750px"
            minWidth="750px"
        >
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    sendMail()
                }}
            >
                <TextField
                    label="À"
                    value={selectedEmails?.length > 1
                        ? selectedEmails?.slice(0, -1)?.join(', ')?.concat(' et ', selectedEmails?.slice(-1)[0])
                        : selectedEmails?.[0] ?? ''}
                    readOnly
                    borderless
                    required
                    multiline={selectedEmails.join(', ')?.length > 50}
                    autoAdjustHeight
                    onChange={() => null}
                />
                <FilteredVirtualCombobox
                    label="Modèle"
                    readOnly={status === Status.PENDING}
                    options={param.emailTemplates}
                    selectedKey={item.emailTemplateId}
                    onChange={(_ev, option) => setItem(prevValue => ({
                        ...prevValue,
                        emailTemplateId: option.key,
                        subject: option?.subject,
                        content: option?.content,
                    }))}
                    required
                />
                <TextField
                    label="Nom"
                    value={item.subject}
                    readOnly={status === Status.PENDING}
                    borderless={status === Status.PENDING}
                    onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, subject: newVal }))}
                    errorMessage={errorField.subject}
                    maxLength={255}
                    required
                />
                <TextField
                    label="Contenu"
                    value={item.content}
                    readOnly={status === Status.PENDING}
                    borderless={status === Status.PENDING}
                    onChange={(_ev, newVal) => setItem(prevValue => ({ ...prevValue, content: newVal }))}
                    errorMessage={errorField.content}
                    required
                    multiline
                    autoAdjustHeight
                />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text="Annuler"
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        type="submit"
                        text="Envoyer"
                        disabled={status === Status.PENDING || !item?.content || !item?.subject || !selectedEmails?.length}
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
