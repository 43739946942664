import React, { useRef, useEffect } from 'react'
import Card from 'components/containers/card'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { Columns } from 'react-bulma-components'
import FilteredVirtualComboboxAjax from 'components/inputs/filteredVirtualComboboxAjax'
import { Text, TextField } from '@fluentui/react'
import Param, { ParamElement } from 'requests/objects/param'
import Mission, { ErrorMission } from 'requests/objects/mission'
import Status from 'types/status'
import SearchFiltersHandler from 'requests/handlers/searchFiltersHandler'
import Time from 'helpers/methods/time'
import EProfile from 'types/profile'
import User from 'requests/objects/user'
import { isValidDate } from 'helpers/methods/common'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import Contact from 'requests/objects/contact'
import { Link } from 'react-router-dom'
import resetCarret from 'helpers/methods/resetCarret'

/**
 * Is field disabled?
 * @param {keyof Mission} field field
 * @param {User} me me
 * @param {any} match match
 * @returns {boolean} Is disabled
 */
export const isDisabled = (field, me, match) => {
    switch (me.profileId) {
        case EProfile.OperationsManager:
            if (match.params?.missionId)
                if (['missionStatusId', 'briefDate', 'userOperationsManagerId'].includes(field))
                    return false
                else
                    return true
            break
        default:
            return false
    }

    return false
}

/**
 * Pivot detail
 * @param {object} props Props
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {Param} props.param param
 * @param {Mission} props.item item
 * @param {ErrorMission} props.errorField errorField
 * @param {Status} props.status status
 * @param {SearchFiltersHandler} props.searchFiltersHandler searchFiltersHandler
 * @param {any} props.match match
 * @param {User} props.me me
 * @param {(item: Partial<Mission>, isIniItemToo?: boolean) => void} props.onChange onChange
 * @param {() => void} props.clear clear
 * @returns {JSX.Element} Returns
 */
export default function PivotDetail({
    isReadOnly, param, item, errorField, status, searchFiltersHandler, match, me, onChange, clear,
}) {
    /** @type {React.MutableRefObject<RequestApi<ParamElement[]>>} */
    const handlerGetContact = useRef(null)

    useEffect(() => {
        handlerGetContact.current?.cancel?.()
    }, [])

    return (
        <>
            <Card
                title="Champs"
                iconName="PageData"
            >
                <Columns>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Collaborateur"
                            readOnly={isReadOnly || isDisabled('userCollaboratorId', me, match)}
                            options={param.consultants}
                            selectedKey={item.userCollaboratorId}
                            onChange={(_ev, option) => onChange({ userCollaboratorId: option.key })}
                            errorMessage={errorField.userCollaboratorId}
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Chargé de recherche"
                            readOnly={isReadOnly || isDisabled('userOperationsManagerId', me, match)}
                            options={param.operationsManagers}
                            selectedKey={item.userOperationsManagerId}
                            onChange={(_ev, option) => onChange({ userOperationsManagerId: option.key })}
                            errorMessage={errorField.userOperationsManagerId}
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualComboboxAjax
                            label="Client"
                            minChar={1}
                            defaultValue={item.client?.name}
                            readOnly={isReadOnly || isDisabled('clientId', me, match)}
                            disabled={status === Status.PENDING}
                            selectedKey={item.clientId}
                            onChange={(_ev, option) => {
                                onChange({ clientId: option.key })
                                // Check if only one contact, to auto set it
                                if (option.key)
                                    (async () => {
                                        handlerGetContact.current = searchFiltersHandler.searchContacts('', { clientId: option.key })
                                        const contacts = await handlerGetContact.current.fetch()
                                        if (contacts.length === 1) {
                                            onChange({
                                                contactId: /** @type {number} */(contacts[0].key),
                                                contact: new Contact({ lastname: contacts[0].text }),
                                            })
                                            clear()
                                        }
                                    })()
                            }}
                            functionName="searchClients"
                            handler={searchFiltersHandler}
                            errorMessage={errorField.clientId}
                            required
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderInput={(props, defaultRender) => (item.missionId
                                ? (
                                    <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                        <Link to={`/clients/${item?.clientId}`}>
                                            {item.client?.name}
                                        </Link>
                                    </Text>
                                )
                                : defaultRender(props))}
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualComboboxAjax
                            label="Contact"
                            minChar={0}
                            defaultValue={item.contact ? `${item.contact?.lastname} ${item.contact?.firstname}`.trim() : ''}
                            readOnly={isReadOnly || isDisabled('contactId', me, match)}
                            disabled={status === Status.PENDING}
                            selectedKey={item.contactId}
                            onChange={(_ev, option) => onChange({ contactId: option.key })}
                            functionName="searchContacts"
                            handler={searchFiltersHandler}
                            errorMessage={errorField.contactId}
                            extraFilters={{ clientId: item.clientId }}
                            required
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Libellé"
                            value={item.name}
                            readOnly={isReadOnly || isDisabled('name', me, match)}
                            borderless={isReadOnly || isDisabled('name', me, match)}
                            onChange={(ev, newVal) => {
                                onChange({ name: newVal?.toUpperCase() })
                                resetCarret(ev)
                            }}
                            errorMessage={errorField.name}
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Rémunération"
                            value={item.remuneration || ''}
                            readOnly={isReadOnly || isDisabled('remuneration', me, match)}
                            borderless={isReadOnly || isDisabled('remuneration', me, match)}
                            onChange={(ev, newVal) => {
                                onChange({ remuneration: newVal?.toUpperCase() })
                                resetCarret(ev)
                            }}
                            errorMessage={errorField.remuneration}
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Référence"
                            value={item.ref}
                            readOnly
                            borderless
                            onChange={(ev, newVal) => {
                                onChange({ ref: newVal?.toUpperCase() })
                                resetCarret(ev)
                            }}
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Statut de la mission"
                            readOnly={isReadOnly || isDisabled('missionStatusId', me, match)}
                            options={match.params?.missionId
                                ? param.missionStatus
                                : param.missionStatus.filter(x => [1, 7].includes(/** @type {number} */(x.key)))}
                            selectedKey={item.missionStatusId}
                            onChange={(_ev, option) => onChange({ missionStatusId: option.key })}
                            errorMessage={errorField.missionStatusId}
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Date brief"
                            value={item.briefDate ? Time(item.briefDate).getLocaleDateString() : ''}
                            readOnly={isReadOnly || isDisabled('briefDate', me, match)}
                            borderless={isReadOnly || isDisabled('briefDate', me, match)}
                            onChange={(ev, newVal) => onChange({ briefDate: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '' })}
                            errorMessage={errorField.briefDate}
                            type="date"
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Famille de poste"
                            readOnly={isReadOnly || isDisabled('divisionId', me, match)}
                            options={param.divisions}
                            selectedKey={item.divisionId}
                            onChange={(_ev, option) => onChange({ divisionId: option.key })}
                            errorMessage={errorField.divisionId}
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Sous-famille"
                            readOnly={isReadOnly || isDisabled('subDivisionId', me, match)}
                            options={param.subDivisions.filter(x => !item.divisionId || x.divisionId === item.divisionId)}
                            selectedKey={item.subDivisionId}
                            onChange={(_ev, option) => onChange({ subDivisionId: option.key })}
                            errorMessage={errorField.subDivisionId}
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Metier"
                            readOnly={isReadOnly || isDisabled('metierId', me, match)}
                            options={param.metiers.filter(x => !item.subDivisionId || item.subDivisionId === x.subDivisionId)}
                            selectedKey={item.metierId}
                            onChange={(_ev, option) => onChange({ metierId: option.key })}
                            errorMessage={errorField.metierId}
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Ville"
                            readOnly={isReadOnly || isDisabled('cityId', me, match)}
                            options={param.cities}
                            selectedKey={item.cityId}
                            onChange={(_ev, option) => onChange({ cityId: option.key })}
                            errorMessage={errorField.cityId}
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Backout"
                            value={item.isBackout ? 'Oui' : 'Non'}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                </Columns>
            </Card>
            <br />
            <Card
                title="Départements"
                iconName="CityNext2"
            >
                <Columns>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Mobilité 1"
                            readOnly={isReadOnly || isDisabled('department1Id', me, match)}
                            options={param.departments}
                            selectedKey={item.department1Id}
                            onChange={(_ev, option) => onChange({ department1Id: option.key })}
                            errorMessage={errorField.department1Id}
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Mobilité 2"
                            readOnly={isReadOnly || isDisabled('department2Id', me, match)}
                            options={param.departments}
                            selectedKey={item.department2Id}
                            onChange={(_ev, option) => onChange({ department2Id: option.key })}
                            errorMessage={errorField.department2Id}
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Mobilité 3"
                            readOnly={isReadOnly || isDisabled('department3Id', me, match)}
                            options={param.departments}
                            selectedKey={item.department3Id}
                            onChange={(_ev, option) => onChange({ department3Id: option.key })}
                            errorMessage={errorField.department3Id}
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <FilteredVirtualCombobox
                            label="Mobilité 4"
                            readOnly={isReadOnly || isDisabled('department4Id', me, match)}
                            options={param.departments}
                            selectedKey={item.department4Id}
                            onChange={(_ev, option) => onChange({ department4Id: option.key })}
                            errorMessage={errorField.department4Id}
                        />
                    </Columns.Column>
                </Columns>
            </Card>
            <br />
            <Card
                title="Montants"
                iconName="NumberSymbol"
            >
                <Columns>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Honoraires totaux PC inclus"
                            value={item.honorary?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('honorary', me, match)}
                            borderless={isReadOnly || isDisabled('honorary', me, match)}
                            onChange={(ev, newVal) => onChange({ honorary: +newVal })}
                            errorMessage={errorField.honorary}
                            type="number"
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Plan de communication"
                            value={item.communicationPlan?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('communicationPlan', me, match)}
                            borderless={isReadOnly || isDisabled('communicationPlan', me, match)}
                            onChange={(ev, newVal) => onChange({ communicationPlan: +newVal })}
                            errorMessage={errorField.communicationPlan}
                            type="number"
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Montant acompte"
                            value={item.depositAmount?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('depositAmount', me, match)}
                            borderless={isReadOnly || isDisabled('depositAmount', me, match)}
                            onChange={(ev, newVal) => onChange({ depositAmount: +newVal })}
                            errorMessage={errorField.depositAmount}
                            type="number"
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Date acompte"
                            value={item.depositDate ? Time(item.depositDate).getLocaleDateString() : ''}
                            readOnly={isReadOnly || isDisabled('depositDate', me, match)}
                            borderless={isReadOnly || isDisabled('depositDate', me, match)}
                            onChange={(ev, newVal) => onChange({ depositDate: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '' })}
                            errorMessage={errorField.depositDate}
                            type="date"
                            required
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Montant possible"
                            value={item.possibleAmount?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('possibleAmount', me, match)}
                            borderless={isReadOnly || isDisabled('possibleAmount', me, match)}
                            onChange={(ev, newVal) => onChange({ possibleAmount: +newVal })}
                            errorMessage={errorField.possibleAmount}
                            type="number"
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Montant prévision"
                            value={item.forecastAmount?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('forecastAmount', me, match)}
                            borderless={isReadOnly || isDisabled('forecastAmount', me, match)}
                            onChange={(ev, newVal) => onChange({ forecastAmount: +newVal })}
                            errorMessage={errorField.forecastAmount}
                            type="number"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Montant placement"
                            value={item.investmentAmount?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('investmentAmount', me, match)}
                            borderless={isReadOnly || isDisabled('investmentAmount', me, match)}
                            onChange={(ev, newVal) => onChange({ investmentAmount: +newVal })}
                            errorMessage={errorField.investmentAmount}
                            type="number"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Date de fin"
                            value={item.investmentDate ? Time(item.investmentDate).getLocaleDateString() : ''}
                            readOnly={isReadOnly || isDisabled('investmentDate', me, match)}
                            borderless={isReadOnly || isDisabled('investmentDate', me, match)}
                            onChange={(ev, newVal) => onChange({ investmentDate: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '' })}
                            errorMessage={errorField.investmentDate}
                            type="date"
                            // PLACE - INTERRUPTION MISSION - MISSION IDENTIFICATION / CLOTUREE
                            required={[2, 3, 8].includes(item.missionStatusId)}
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Divers 1"
                            value={item.variousAmount1?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('variousAmount1', me, match)}
                            borderless={isReadOnly || isDisabled('variousAmount1', me, match)}
                            onChange={(ev, newVal) => onChange({ variousAmount1: +newVal })}
                            errorMessage={errorField.variousAmount1}
                            type="number"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Date 1"
                            value={item.variousDate1 ? Time(item.variousDate1).getLocaleDateString() : ''}
                            readOnly={isReadOnly || isDisabled('variousDate1', me, match)}
                            borderless={isReadOnly || isDisabled('variousDate1', me, match)}
                            onChange={(ev, newVal) => onChange({ variousDate1: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '' })}
                            errorMessage={errorField.variousDate1}
                            type="date"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Divers 2"
                            value={item.variousAmount2?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('variousAmount2', me, match)}
                            borderless={isReadOnly || isDisabled('variousAmount2', me, match)}
                            onChange={(ev, newVal) => onChange({ variousAmount2: +newVal })}
                            errorMessage={errorField.variousAmount2}
                            type="number"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Date 2"
                            value={item.variousDate2 ? Time(item.variousDate2).getLocaleDateString() : ''}
                            readOnly={isReadOnly || isDisabled('variousDate2', me, match)}
                            borderless={isReadOnly || isDisabled('variousDate2', me, match)}
                            onChange={(ev, newVal) => onChange({ variousDate2: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '' })}
                            errorMessage={errorField.variousDate2}
                            type="date"
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Divers 3"
                            value={item.variousAmount3?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('variousAmount3', me, match)}
                            borderless={isReadOnly || isDisabled('variousAmount3', me, match)}
                            onChange={(ev, newVal) => onChange({ variousAmount3: +newVal })}
                            errorMessage={errorField.variousAmount3}
                            type="number"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Date 3"
                            value={item.variousDate3 ? Time(item.variousDate3).getLocaleDateString() : ''}
                            readOnly={isReadOnly || isDisabled('variousDate3', me, match)}
                            borderless={isReadOnly || isDisabled('variousDate3', me, match)}
                            onChange={(ev, newVal) => onChange({ variousDate3: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '' })}
                            errorMessage={errorField.variousDate3}
                            type="date"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Divers 4"
                            value={item.variousAmount4?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('variousAmount4', me, match)}
                            borderless={isReadOnly || isDisabled('variousAmount4', me, match)}
                            onChange={(ev, newVal) => onChange({ variousAmount4: +newVal })}
                            errorMessage={errorField.variousAmount4}
                            type="number"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Date 4"
                            value={item.variousDate4 ? Time(item.variousDate4).getLocaleDateString() : ''}
                            readOnly={isReadOnly || isDisabled('variousDate4', me, match)}
                            borderless={isReadOnly || isDisabled('variousDate4', me, match)}
                            onChange={(ev, newVal) => onChange({ variousDate4: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '' })}
                            errorMessage={errorField.variousDate4}
                            type="date"
                        />
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Montant double placement"
                            value={item.dualPlacementAmont?.toString() || ''}
                            readOnly={isReadOnly || isDisabled('dualPlacementAmont', me, match)}
                            borderless={isReadOnly || isDisabled('dualPlacementAmont', me, match)}
                            onChange={(ev, newVal) => onChange({ dualPlacementAmont: +newVal })}
                            errorMessage={errorField.dualPlacementAmont}
                            type="number"
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label="Date double placement"
                            value={item.dualPlacementDate ? Time(item.dualPlacementDate).getLocaleDateString() : ''}
                            readOnly={isReadOnly || isDisabled('dualPlacementDate', me, match)}
                            borderless={isReadOnly || isDisabled('dualPlacementDate', me, match)}
                            onChange={(ev, newVal) => onChange({
                                dualPlacementDate: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '',
                            })}
                            errorMessage={errorField.dualPlacementDate}
                            type="date"
                        />
                    </Columns.Column>
                </Columns>
            </Card>
        </>
    )
}
