import Types from 'types/types'
import { Column } from 'types/column' // eslint-disable-line

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const emailTemplateColumns = [
    {
        fieldName: 'name',
        name: 'Nom',
        type: Types.STRING,
    },
    {
        fieldName: 'subject',
        name: 'Sujet',
        type: Types.STRING,
    },
    // {
    //     fieldName: 'content',
    //     name: 'Contenu',
    //     type: Types.STRING_LONG,
    // },
]

export default emailTemplateColumns
