// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import NotImplementedError from 'requests/errors/notImplementedError'
import { ParamElement } from 'requests/objects/param'

/**
 * SearchFiltersHandler
 * @augments {ApiHandler<ParamElement>}
 */
export default class SearchFiltersHandler extends ApiHandler {
    constructor() {
        super({ type: ParamElement, errorType: Object, key: 'search-filters' })
    }

    /**
     * Search filter clients
     * @param {string} text text used to filter clients
     * @returns {RequestApi<ParamElement[]>} Request
     */
    searchClients(text) {
        const request = this.initFetchRequest({ url: ['clients'], method: 'GET', params: { text } })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => /** @type {any} */(res.data[this.objectName])?.map(x => new (this.type)(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Search filter clients
     * @param {string} text text used to filter clients
     * @returns {RequestApi<ParamElement[]>} Request
     */
    searchCandidates(text) {
        const request = this.initFetchRequest({ url: ['candidates'], method: 'GET', params: { text } })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => /** @type {any} */(res.data[this.objectName])?.map(x => new (this.type)(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Search filter clients
     * @param {string} text text used to filter clients
     * @param {object} extraFilters extraFilters
     * @returns {RequestApi<ParamElement[]>} Request
     */
    searchContacts(text, extraFilters) {
        const request = this.initFetchRequest({ url: ['contacts'], method: 'GET', params: { text, ...extraFilters } })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => /** @type {any} */(res.data[this.objectName])?.map(x => new (this.type)(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Search filter missions
     * @param {string} text text used to filter missions
     * @returns {RequestApi<ParamElement[]>} Request
     */
    searchMissions(text) {
        const request = this.initFetchRequest({ url: ['missions'], method: 'GET', params: { text } })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => /** @type {any} */(res.data[this.objectName])?.map(x => new (this.type)(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    getAll() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    getById() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    create() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    updateById() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    removeById() {
        throw new NotImplementedError()
    }
}
