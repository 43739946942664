/**
 * @enum {string}
 */
const EDot = {
    BLUE: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
    RED: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
    GREEN: 'http://maps.google.com/mapfiles/ms/icons/grn-dot.png',
    PINK: 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png',
    PURPLE: 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
    WHITE: 'http://maps.google.com/mapfiles/ms/icons/wht-dot.png',
}

export default EDot
