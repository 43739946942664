/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * FileCv Object
 */
export default class FileCv extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.fileCvId fileCvId of the FileCv
     * @param {string=} data.name name
     * @param {string=} data.elasticId elasticId
     * @param {number=} data.candidateId candidateId
     * @param {number=} data.createdByUserId createdByUserId
     * @param {object=} data.createdByUser createdByUser
     */
    constructor({
        fileCvId = 0,
        name = '',
        elasticId,
        candidateId,
        createdByUserId,
        createdByUser,
        ...data
    } = {}) {
        super(data)
        this.fileCvId = fileCvId
        this.name = name
        this.elasticId = elasticId
        this.candidateId = candidateId
        this.createdByUserId = createdByUserId
        this.createdByUser = createdByUser
    }
}

/**
 * FileCv Object used to bind error message
 */
export class ErrorFileCv extends ErrorBase {
    constructor() {
        super()
        this.fileCvId = ''
        this.name = ''
        this.elasticId = ''
        this.candidateId = ''
        this.createdByUserId = ''
        this.createdByUser = ''
    }
}
