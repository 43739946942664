/**
 * @enum {number}
 */
const EProfile = {
    Administrator: 1,
    Consultant: 3,
    OperationsManager: 4,
}

export default EProfile
