import Client, { ErrorClient } from 'requests/objects/client'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import { setMessageBar } from 'redux/slices/common'
import { MessageBarType } from '@fluentui/react'
import store from 'redux/store'

/**
 * ClientsHandler
 * @augments {ApiHandler<Client, ErrorClient>}
 */
export default class ClientsHandler extends ApiHandler {
    constructor() {
        super({ type: Client, errorType: ErrorClient, key: 'clients' })
    }

    /**
     * Create
     * @param {Client} client client
     * @param {number} commercialInformationId commercialInformationId
     * @param {number} commercialInformationProcessOptionId commercialInformationProcessOptionId
     * @returns {RequestApi<Client>} Request
     */
    // eslint-disable-next-line new-cap
    createWithProcess(client, commercialInformationId, commercialInformationProcessOptionId) {
        const request = this.initFetchRequest({
            method: 'POST',
            url: ['process'],
            data: client,
            params: { commercialInformationId, commercialInformationProcessOptionId },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(() => {
                    store.dispatch(setMessageBar({
                        isDisplayed: true,
                        type: MessageBarType.success,
                        message: "Information commerciale traité avec création d'un nouveau client.",
                    }))
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
